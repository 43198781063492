import React, { Component, useEffect, useState } from 'react'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


function CustomLoader({ spinner_type }) {
    return (
        <>
            {/* <Loader type="Audio" color="#00BFFF" height={80} width={80} />
                            <Loader type="BallTriangle" color="#00BFFF" height={80} width={80} />
                            <Loader type="Bars" color="#00BFFF" height={80} width={80} />
                            <Loader type="Circles" color="#00BFFF" height={80} width={80} />
                            <Loader type="Grid" color="#00BFFF" height={80} width={80} />
                            <Loader type="Hearts" color="#00BFFF" height={80} width={80} />
                            <Loader type="Oval" color="#00BFFF" height={80} width={80} />
                            <Loader type="Puff" color="#00BFFF" height={80} width={80} />
                            <Loader type="Rings" color="#00BFFF" height={80} width={80} />
                            <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />
                            <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} /> */}
            <div className='text-center'>< Loader type="ThreeDots" color="#009efb" height={50} width={50} /></div>
        </>
    );
}
export default CustomLoader;