import React, { useState } from "react";
import decodeJwtToken from "../auth/decodeJwtToken";
import userImg from "../assets/img/user.jpg";
import { domain } from "../auth/domain";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import swal from "sweetalert";

const UserProfile = () => {
  const accessToken = localStorage.getItem("accessToken");
  const user = decodeJwtToken(accessToken);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    let formErrors = {};
    let hasError = false;

    if (!oldPassword) {
      formErrors.oldPassword = "Current password is required";
      hasError = true;
    }
    if (!newPassword) {
      formErrors.newPassword = "New password is required";
      hasError = true;
    }

    setErrors(formErrors);

    if (hasError) return;

    setShowSpinner(true);

    try {
      const response = await fetch(`${domain}auth/users/set_password/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${accessToken}`,
        },
        body: JSON.stringify({
          current_password: oldPassword,
          new_password: newPassword,
        }),
      });

      if (response.ok) {
        swal({
          title: "Password successfully updated",
          icon: "success",
        });
        setOldPassword("");
        setNewPassword("");
      } else {
        const result = await response.json();
        let errorTitle = "Error";
        let errorMsgs = ["An error occurred"];

        if (result.new_password) {
          errorTitle = "New Password Error";
          errorMsgs = result.new_password;
        } else if (result.current_password) {
          errorTitle = "Current Password Error";
          errorMsgs = result.current_password;
        }

        swal({
          icon: "error",
          title: errorTitle,
          text: errorMsgs.join("\n"), // Join all error messages with a newline
        });
      }
    } catch (error) {
      swal({
        icon: "error",
        title: "Error",
        text: "An error occurred",
        timer: 2000,
      });
    } finally {
      setShowSpinner(false);
    }
  };

  return (
    <div className="row">
      <h3>My Profile</h3>
      <div
        className="card "
        style={{
          boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)",
          borderRadius: "7px",
        }}
      >
        <div className="card-body">
          <div className="d-flex flex-column align-items-center text-center">
            <img
              src={userImg}
              alt="user"
              className="rounded-circle"
              width="150"
            />
            <div className="mt-3">
              <h4>Full Name: {user?.full_name}</h4>
              <p className="text-secondary mb-1">Email: {user?.email}</p>
              {/* <p className="text-secondary mb-1">Username: {user?.username}</p> */}
            </div>
            <div className="mt-3 p-3">
              <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ "& .MuiTextField-root": { m: 1, width: "100%" } }}
                noValidate
                autoComplete="off"
              >
                <div>
                  <TextField
                    id="outlined-old-password"
                    label="Current Password"
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    error={!!errors.oldPassword}
                    helperText={errors.oldPassword}
                    required
                    fullWidth
                  />
                  <TextField
                    id="outlined-new-password"
                    label="New Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    error={!!errors.newPassword}
                    helperText={errors.newPassword}
                    required
                    fullWidth
                  />
                </div>
                <div style={{ marginTop: "1rem", width: "100%" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={showSpinner}
                  >
                    {showSpinner ? (
                      <LinearProgress color="inherit" />
                    ) : (
                      "UPDATE"
                    )}
                  </Button>
                </div>
                {showSpinner && (
                  <div style={{ width: "100%", marginTop: "1rem" }}>
                    <LinearProgress />
                  </div>
                )}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
