import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./Popup.css";
import { domain } from "../auth/domain";
import swal from "sweetalert";
import confirmImage from "../assets/img/sent.png";
import { event, get } from "jquery";
import decodeJwtToken from "../auth/decodeJwtToken";
import { Tabs, Tab, Form } from "react-bootstrap";
import CustomLoader from "./CustomLoader";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import { TextField } from "@mui/material";
import AOWStepper from "./AOWStepper";
import formatCureny3F from "../shared/formatCurrency3F";
import removeCommas from "../shared/removeCommas";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExpensesDialog({
  btnName,
  btnClass,
  iconName,
  formTitle,
  userId,
  onToggleModal,
  onRefreshTable,
  disabled,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    onToggleModal && onToggleModal(true);
    handleEditMode();
  };

  const handleRefresh = (data) => {
    onRefreshTable(data);
  };

  const handleClose = () => {
    setOpen(false);
    onToggleModal(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);

  const [cities, setCities] = useState([]);
  const [error, setError] = useState({
    name: "",
    cost: "",
    rejection_reason: "",
  });
  const [products, setProducts] = useState([]);
  const [patientCityId, setSelectedPatientCity] = useState("");
  const [patientInsuranceId, setSelectedPatientInsurance] = useState(0);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [category, setCategory] = useState("");
  const [lowStockThreshold, setLowStockThreshold] = useState("");
  const [location, setLocation] = useState("");
  const [cardNumber, setCardNumber] = useState(0);
  const [memberShipNumber, setMemberShipNumber] = useState(0);
  // use this for update insurance data and not insurance provider data
  const [insuranceId, setInsuranceId] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [firstVisit, setFirstVisit] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [invoiceCategory, setInvoiceCategory] = useState("");
  const [showLabel, setShowLabel] = useState(false);

  const [nextOfKinFirstName, setNextOfKinFirstName] = useState("");
  const [nextOfKinLastName, setNextOfKinLastName] = useState("");
  const [nextOfKinMiddleName, setNextOfKinMiddleName] = useState("");

  const [nextOfKinGender, setNextOfKinGender] = useState("");
  const [nextOfKinPhone, setNextOfKinPhone] = useState("");
  const [doctors, setDoctors] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [quantity, setQuantity] = useState("");
  const [reason, setReason] = useState("");
  const [cost, setCost] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);

  const [invoiceItems, setInvoiceItems] = useState([
    {
      id: 1,
      name: "",
      category: "",
      low_stock_threshold: "",
      description: "",
      batch_number: "",
      expiry_date: "",
      location: 1,
    },
  ]);
  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const addInvoiceItem = () => {
    const highestId = Math.max(...invoiceItems.map((item) => item.id));
    setInvoiceItems([
      ...invoiceItems,
      {
        id: highestId + 1,
        name: "",
        category: "",
        low_stock_threshold: "",
        description: "",
        sell_price: "",
        bulk_quantity: "",
        batch_number: "",
        expiry_date: "",
        location: 1,
      },
    ]);
  };

  const correctDate = (trh) => {
    const utcDateTime = trh;

    // Create a Date object from the UTC string
    const date = new Date(utcDateTime);

    // Convert to local time (EAT)
    const localTimeOffset = 3 * 60; // EAT is UTC+3, so offset is +180 minutes
    const localDate = new Date(date.getTime() - localTimeOffset * 60 * 1000);

    // Format as a combined datetime string in the local timezone
    const localDateTimeString = localDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    return localDateTimeString;
  };
  const handleEditMode = () => {
    if (btnName === "EDIT") {
      fetch(domain + "api/running-expenses/" + userId + "/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setSelectedProduct(data?.product?.id);
          setName(data?.name);
          setAmount(data?.amount);
          setDate(data?.date);
        });
    }
  };

  const handleInputChange = (id, field, value) => {
    setInvoiceItems(
      invoiceItems.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    onToggleModal(!isOpen);
  };

  const handleNext = () => {
    if (activeTab === 0) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    } else if (activeTab === 1) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    }

    setActiveTab(activeTab + 1);
  };

  useEffect(() => {
    if (btnName == "Reject") {
      setMaxWidth("lg");
    }

    // fetch(domain + "api/profile/", {
    //   method: "GET",
    //   headers: {
    //     Authorization: "JWT " + accessToken,
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setLocation(data.filter((data) => data.user === me.user_id));
    //   });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (btnName === "ADD" || btnName === "Edit") {
      let hasError = false;

      if (!name) {
        setError((prevError) => ({
          ...prevError,
          name: "Description is required",
        }));
        hasError = true;
      }
      if (!amount) {
        setError((prevError) => ({
          ...prevError,
          cost: "Cost is required",
        }));
        hasError = true;
      }

      if (hasError) return; // Prevent submission if there are errors

      setError(""); // Clear errors
    }
    setError("");
    if (btnName === "ADD") {
      const response = await fetch(domain + "expenses/expenses/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + accessToken,
        },
        body: JSON.stringify({
          description: name,
          amount: removeCommas(amount),
          date,
          branch_id: me?.branch?.id,
        }),
      });

      if (response.ok) {
        swal({
          title: "",
          text: "Expense added",
          icon: "success",
          timer: 1500,
          buttons: false,
        });

        handleClose();
        handleRefresh(Math.random());
      } else {
        swal({
          title: "Error occured",
          icon: "error",
          timer: 1500,
          buttons: false,
        });
      }
    }

    if (btnName === "Reject") {
      let status = "";
      if (me?.group?.name === "Branch Manager") {
        status = "REJECTED_BY_BRANCH_MANAGER";
      } else if (me?.group?.name === "General Manager") {
        status = "REJECTED_BY_GENERAL_MANAGER";
      }
      if (!reason) {
        setError((prevError) => ({
          ...prevError,
          rejection_reason: "Rejection reason is required",
        }));
        return;
      }

      setError({
        name: "",
        cost: "",
        rejection_reason: "",
      });
      try {
        const response = await fetch(
          domain + "expenses/expenses/" + userId + "/approve/",
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: "JWT " + accessToken,
            },
            body: JSON.stringify({
              status,
              rejection_reason: reason,
            }),
          }
        );

        if (response.ok) {
          swal({
            title: "success",
            text: "Expense successful rejected",
            icon: "success",
            buttons: false,
            timer: 2000,
          });
          setReason("");
          handleClose();
          // onRefreshTable("update");
          handleRefresh(Math.random());
        } else {
          const body = await response.json();
          const details = body?.detail;
          swal({
            title: "Failed",
            text: details ? details : "Error occured",
            icon: "error",
            buttons: false,
            timer: 1300,
          });
          setReason("");
          handleClose();
        }
      } catch (error) {
        swal({
          title: "Error",
          text: "Error occured",
          icon: "error",
          buttons: false,
          timer: 1300,
        });
      }
    }

    if (btnName === "EDIT") {
      const response = await fetch(
        domain + "api/running-expenses/" + userId + "/",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            date,
            amount,
            name,
          }),
        }
      );

      if (response.ok) {
        swal({
          title: "Expense updated",
          icon: "success",
        });
        handleClose();
        // onRefreshTable("update");
        handleRefresh(Math.random());
      } else {
        swal({ title: "Error occured", icon: "error" });
      }
    }

    if (btnName === "DELETE") {
      togglePopup();

      const response = await fetch(
        domain + "api/running-expenses/" + userId + "/",
        {
          method: "DELETE",
          headers: {
            Authorization: "JWT " + accessToken,
          },
        }
      );

      if (response.ok) {
        swal({ title: " deleted", icon: "success" });
        handleRefresh(Math.random());
      } else {
        swal({ title: "Something went wrong", icon: "error" });
      }
    }

    if (btnName === "Dr.CONSULTATION") {
      // if (doctorId === "") {
      //   swal({ icon: "info", title: "Please select Dr" });
      //   return;
      // }

      if (paymentMethod === "cash") {
        // const response = await fetch(domain + "api/bills/", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: "JWT " + accessToken,
        //   },
        //   body: JSON.stringify({
        //     amount: doctorFee,
        //     patient: userId,
        //     category: 1,
        //   }),
        // });
        // if (response.ok) {
        //   swal({ title: "Patient successful sent to Dr", icon: "success" });
        // } else {
        //   swal({
        //     title: "Error occured",
        //     text: "Please try again or Consult System admin",
        //     icon: "error",
        //   });
        // }
      }

      if (paymentMethod === "insurance") {
        const response = await fetch(domain + "api/invoices/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            patient: userId,
            category: 1,
          }),
        });

        if (response.ok) {
          swal({ title: "Patient successful sent to Dr", icon: "success" });
        } else {
          swal({
            title: "Error occured",
            text: "Please try again or Consult System admin",
            icon: "error",
          });
        }
      }
    }
  };

  // Function to get city name based on city id
  const getLocation = (cityId) => {
    const city = cities.find((city) => city.id === cityId);
    return city ? city.name : "";
  };

  // const getFee = (doctorId) => {
  //   // Check if doctorFees is available and not empty
  //   if (doctorFees && doctorFees.length > 0) {
  //     const fee = doctorFees.find((fee) => fee.doctor == doctorId);
  //     return fee ? fee.consultation_fee : "";
  //   }
  //   return ""; // Return empty string if doctorFees is not yet available or empty
  // };

  const getInvoiceCategory = (categoryId) => {
    const category = invoiceCategory.find(
      (category) => category.id === categoryId
    );
    return category ? category.name : "";
  };

  // Handler for doctor selection
  // const handleDoctorChange = (event) => {
  //   const selectedDoctorId = event.target.value;

  //   setSelectedDoctor(selectedDoctorId);
  //   setDoctorFee(getFee(selectedDoctorId));
  // };

  return (
    <React.Fragment>
      <button
        title={btnName}
        className={btnClass}
        onClick={handleClickOpen}
        disabled={disabled}
      >
        <i className={"fa fa-" + iconName + " m-r-5"}></i>
        {btnName}
      </button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {btnName === "DELETE" || <DialogTitle>{formTitle}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            {btnName === "ADD" && (
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <TextField
                        type="text"
                        label="Description"
                        value={name}
                        error={!!error.name}
                        helperText={error.name}
                        onChange={(event) => {
                          setName(event.target.value);
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <TextField
                        type="text"
                        label="cost"
                        value={amount}
                        error={!!error.cost}
                        helperText={error.cost}
                        onChange={(event) => {
                          setAmount(formatCureny3F(event.target.value));
                        }}
                      />
                    </FormControl>
                    {/* <FormControl fullWidth sx={{ m: 1 }}>
                      <TextField
                        type="datetime-local"
                        value={date}
                        onChange={(event) => {
                          setDate(event.target.value);
                        }}
                      />
                    </FormControl> */}
                  </div>
                </div>
              </>
            )}
            {btnName == "Reject" && (
              <div className="col-lg-12">
                <FormControl fullWidth sx={{ width: "80ch" }}>
                  <TextField
                    label="Rejection reason"
                    multiline
                    required
                    rows={3}
                    type="text"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!error.rejection_reason}
                    helperText={error.rejection_reason}
                  />
                </FormControl>
              </div>
            )}
            {btnName === "DELETE" && (
              <div class="text-center">
                <img src={confirmImage} alt="" width="50" height="46" />
                <h3>Are you sure want to Delete this Wastage ?</h3>

                <div class="m-t-20"> </div>
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-light m-1 float-right"
            onClick={handleClose}
          >
            CLOSE
          </button>
          {btnName === "DELETE" ? (
            <button
              type="submit"
              className="btn m-1 float-right btn-danger"
              onClick={handleSubmit}
            >
              DELETE
            </button>
          ) : (
            <button
              type="submit"
              className="btn m-1 float-right btn-primary"
              onClick={handleSubmit}
            >
              SUBMIT
            </button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
