import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../shared/Form.css";
import { domain } from "../auth/domain";
import UserRolePopup from "../components/CustomerDialog";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import NoRecords from "../shared/NoRecords";
import { LinearProgress } from "@mui/material";
import formatRoleName from "../shared/formatRoleName";
import { useBranch } from "../contexts/BranchContext";
import decodeJwtToken from "../auth/decodeJwtToken";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import FilterIcon from "@mui/icons-material/FilterList";
import TuneIcon from "@mui/icons-material/Tune";
import RefreshIcon from "@mui/icons-material/Refresh";
import SyncIcon from "@mui/icons-material/Sync";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  MenuItem,
  Stack,
} from "@mui/material";

export default function Customers() {
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [filteredUserTypes, setFilteredUserTypes] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState("");
  const navigate = useNavigate();
  const { branch, setBranch } = useBranch();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [loanPortfolio, setLoanPortfolio] = useState("");

  const handleBackDrop = () => setShowBackDrop(!Backdrop);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    const query = event.target.value.toLowerCase();
    const filtered = userTypes.filter((userType) =>
      userType.full_name.toLowerCase().includes(query)
    );
    setFilteredUserTypes(filtered);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    // Reset your table data if needed
  };
  // handle react page visibility based on open/clode of popup
  const handleToggleModal = (modalState) => {
    setIsModalOpen(modalState);
  };

  const handleTableRefresh = (info) => {
    setRefreshTable(info);
  };

  // Implement a function to handle the search functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = userTypes.filter((userType) =>
      userType.full_name.toLowerCase().includes(query)
    );
    setFilteredUserTypes(filtered);
  };
  // Implement a function to handle the page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Use the currentPage and perPage state variables to calculate
  // the slice of applications to be displayed on the current page

  const offset = currentPage * perPage;
  const paginatedUserTypes = filteredUserTypes.slice(offset, offset + perPage);

  const handlePrintForm = async (customerId) => {
    try {
      const result = await swal({
        title: "Are you sure you want to Print form?",
        icon: "warning",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (result) {
        const response = await fetch(domain + "forms/form-items/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            customer_id: customerId,
            branch_id: branch?.id,
          }),
        });

        if (response.ok) {
          swal({
            title: "success",
            text: "Form successful printed",
            icon: "success",
            buttons: false,
            timer: 2000,
          });
          // handleClose();
          // onRefreshTable("update");
          const body = await response.json();
          // navigate(body?.form_file_url)
          // window.location.href = body?.form_file_url;
          window.open(body?.form_file_url, "_blank");

          handleTableRefresh(Math.random());
        } else {
          const body = await response.json();
          const details = body?.detail;
          swal({
            title: "Failed",
            text: details ? details : "Error occured",
            icon: "error",
            buttons: false,
          });
        }
      }
    } catch (error) {}
  };

  const handleDeleteCustomer = async (customerId) => {
    try {
      const result = await swal({
        title: "Are you sure you want to delete this customer ?",
        icon: "warning",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (result) {
        const response = await fetch(
          domain + "customers/customers/" + customerId,
          {
            method: "DELETE",
            headers: {
              Authorization: "JWT " + accessToken,
            },
          }
        );

        if (response.ok) {
          swal({
            title: "success",
            text: "Customer successful deleted",
            icon: "success",
            buttons: false,
            timer: 2000,
          });

          handleTableRefresh(Math.random());
          navigate("/customers");
        } else {
          const body = await response.json();
          const details = body?.detail;
          swal({
            title: "Failed",
            text: details ? details : "Error occured",
            icon: "error",
            buttons: false,
            timer: 1500,
          });
        }
      }
    } catch (error) {}
  };

  const handleUpdateNMB = async (customerId) => {
    try {
      const result = await swal({
        title: "Are you sure you want to update NMB ?",
        icon: "warning",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (result) {
        setShowBackDrop(true);
        const response = await fetch(
          domain + "nmb/callback/update-invoice/" + customerId,
          {
            method: "GET",
            headers: {
              Authorization: "JWT " + accessToken,
            },
          }
        );

        if (response.ok) {
          setShowBackDrop(false);
          const body = await response.json();
          const status = body?.status;
          status == 1
            ? swal({
                title: "success",
                text: "successful updated",
                icon: "success",
                buttons: false,
                timer: 2000,
              })
            : swal({
                title: "error",
                text: body?.description,
                icon: "error",
                buttons: false,
                timer: 2000,
              });
        } else {
          setShowBackDrop(false);
          const body = await response.json();
          const details = body?.detail;
          swal({
            title: "Failed",
            text: details ? details : "Error occured",
            icon: "error",
            buttons: false,
            timer: 1500,
          });
        }
      }
    } catch (error) {}
  };

  // fetch Current users
  useEffect(() => {
    setBranch(branch);
    setShowSpinner(true);
    // Fetch user types
    fetch(
      domain +
        `customers/customers/?branch_id=${branch?.id}&loan_portfolio=${loanPortfolio}
        `,
      {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["detail"]) {
          swal({ icon: "info", title: data["detail"] });
          navigate("/dashboard");
        }
        setUserTypes(data);
        setFilteredUserTypes(data);
        setShowSpinner(false);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setShowSpinner(false);
      });
  }, [refreshTable, branch, loanPortfolio]);

  return (
    <div className="row">
      <Backdrop
        sx={(theme) => ({ color: "#800b33", zIndex: theme.zIndex.drawer + 1 })}
        open={showBackDrop}
        onClick={handleBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Search & Filter
          <IconButton
            onClick={() => setOpen(false)}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {/* {searchTerm && (
                    <IconButton onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  )} */}
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            style={{ marginBottom: 20 }}
          />
          <TextField
            label="Loan portfolio"
            select
            name="loanPortfolio"
            value={loanPortfolio}
            onChange={(e) => setLoanPortfolio(e.target.value)}
            fullWidth
            margin="normal"
          >
            <MenuItem value="">All</MenuItem>
            {[
              { value: "with_loans", label: "With loans" },
              { value: "without_loans", label: "Without loans" },
              { value: "multiple_loans", label: "Multiple loans" },
            ].map((status) => (
              <MenuItem key={status.value} value={status.value}>
                {status.label}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
      </Dialog>
      {showSpinner && <LinearProgress />}
      <h3>Customers</h3>
      <div class="text-right mb-1">
        {["Cashier", "Loan Officer"].includes(me?.group?.name) && (
          <UserRolePopup
            onToggleModal={handleToggleModal}
            onRefreshTable={handleTableRefresh}
            btnName="ADD"
            iconName={"plus"}
            btnClass={"btn btn-outline-primary"}
            formTitle={"Add Customer"}
          />
        )}

        <IconButton
          onClick={() => setOpen(true)}
          style={{
            // backgroundColor: "#800b33",
            // color: "#fff",
            marginLeft: "5px",
          }}
        >
          <TuneIcon />
        </IconButton>
        {/* <IconButton
          onClick={() => setOpen(true)}
          style={{
            // backgroundColor: "#800b33",
            // color: "#fff",
            marginLeft: "5px",
          }}
        >
          <SearchIcon />
        </IconButton> */}
      </div>
      <table
        id="app"
        className="datatable table table-striped"
        style={{
          boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)",
          borderRadius: "7px",
          whiteSpace: "nowrap",
        }}
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Mobile</th>
            <th>Reference&nbsp;Number</th>
            <th>Bank&nbsp;Account&nbsp;Number</th>
            <th>Email</th>
            <th>ID&nbsp;Type</th>
            <th>ID&nbsp;Number</th>
            <th>Guarantor</th>
            <th className="text-right">Action</th>
          </tr>
        </thead>
        <tbody>
          {(() => {
            try {
              return paginatedUserTypes.map((userType, index) => (
                <tr key={userType.id}>
                  <td>
                    {index + 1}
                    {userType?.guarantor?.full_name == "" && (
                      <Tooltip title="Missing guarantor Info">
                        <ErrorOutlineIcon color="error" sx={{ ml: 1 }} />
                      </Tooltip>
                    )}
                  </td>
                  <td>{userType.full_name}</td>

                  <td>{userType.phone_number}</td>
                  <td>{userType.bank_reference_number}</td>
                  <td>{userType.bank_account_number}</td>
                  <td>{userType?.email}</td>
                  <td>{formatRoleName(userType?.id_type)}</td>
                  <td>{userType?.id_number}</td>
                  <td>{userType?.guarantor?.full_name}</td>
                  <td class="text-right">
                    <div class="dropdown dropdown-action">
                      <span
                        href="#"
                        class="action-icon dropdown-toggle"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-ellipsis-v"></i>
                      </span>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        x-placement="bottom-end"
                        style={{
                          position: "absolute",
                          willChange: "transform",
                          top: "0px",
                          left: "0px",
                          transform: "translate3d(-102px, 27px, 0px)",
                        }}
                      >
                        {me?.group?.name === "Cashier" && (
                          <UserRolePopup
                            onToggleModal={handleToggleModal}
                            onRefreshTable={handleTableRefresh}
                            btnName="Edit"
                            iconName={"pencil"}
                            btnClass={"dropdown-item"}
                            formTitle={"Update customer"}
                            userId={userType.id}
                          />
                        )}
                        {me?.group?.name === "Loan Officer" && (
                          <button
                            class="dropdown-item"
                            onClick={() => handlePrintForm(userType?.id)}
                          >
                            <i class="fa fa-print m-r-5"></i>Print Form
                          </button>
                        )}
                        {me?.group?.name === "Cashier" &&
                          !userType?.has_active_loan &&
                          userType?.guarantor?.full_name !== "" && (
                            <UserRolePopup
                              onToggleModal={handleToggleModal}
                              onRefreshTable={handleTableRefresh}
                              btnName="Apply Loan"
                              iconName={"money"}
                              btnClass={"dropdown-item"}
                              formTitle={"Apply Loan"}
                              userId={userType.id}
                              disabled={userType?.has_active_loan}
                            />
                          )}
                        <button
                          class="dropdown-item"
                          onClick={() => navigate(`/loans/${userType.id}`)}
                        >
                          <i class="fa fa-eye m-r-5"></i> View Loans
                        </button>
                        <button
                          class="dropdown-item"
                          onClick={() =>
                            navigate(`/customer-statements/${userType.id}`)
                          }
                        >
                          <i class="fa fa-eye m-r-5"></i> View Statements
                        </button>
                        <UserRolePopup
                          onToggleModal={handleToggleModal}
                          onRefreshTable={handleTableRefresh}
                          btnName="Invoice"
                          iconName={"file"}
                          btnClass={"dropdown-item"}
                          formTitle={"NMB Invoice"}
                          userId={userType.id}
                        />
                        <button
                          class="dropdown-item"
                          onClick={() => handleUpdateNMB(userType?.id)}
                        >
                          <i class="m-r-1">
                            <RefreshIcon style={{ fontSize: "18px" }} />
                          </i>
                          Update NMB
                        </button>
                        <button
                          class="dropdown-item"
                          onClick={() => handleDeleteCustomer(userType?.id)}
                        >
                          <i class="fa fa-trash-o m-r-5"></i>Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ));
            } catch (error) {
              console.log(error);
              return (
                <tr>
                  <td>Network Problem.Please check your Internet</td>
                </tr>
              );
            }
          })()}
        </tbody>
      </table>
      <NoRecords data={filteredUserTypes} />

      {!isModalOpen && (
        <ReactPaginate
          style={{ color: "#800b33" }}
          previousLabel="Previous"
          nextLabel="Next"
          pageCount={Math.ceil(filteredUserTypes.length / perPage)}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          nextClassName="page-item"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
        />
      )}

      {/* {showSpinner && <CustomLoader />} */}
    </div>
  );
}
