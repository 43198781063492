import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./Popup.css";
import { domain } from "../auth/domain";
import swal from "sweetalert";
import confirmImage from "../assets/img/sent.png";
import { event, get } from "jquery";
import decodeJwtToken from "../auth/decodeJwtToken";
import { Tabs, Tab, Form } from "react-bootstrap";
import CustomLoader from "./CustomLoader";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Upload from "@mui/icons-material/Upload";
import { useNavigate } from "react-router-dom";
import { useBranch } from "../contexts/BranchContext";
import formatCureny3F from "../shared/formatCurrency3F";
import { Tooltip } from "@mui/material";
import removeCommas from "../shared/removeCommas";
import { Paper, Grid } from "@mui/material";
import { styled } from "@mui/system";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  formatPhoneNumber,
  formatNidaId,
  formatVoterId,
} from "../shared/formatter";
import formatCurrency from "../shared/formatCurrency";
import LinearProgress from "@mui/material/LinearProgress";
import nmb from "../assets/img/nmb-logo.webp";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: 10,
  background: "#f9f9f9",
  border: "1px solid #e0e0e0",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
}));

const Title = styled(Typography)({
  fontWeight: "bold",
  color: "#800b33",
});

const AmountContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginTop: 16,
});

export default function CustomerDialog({
  btnName,
  btnClass,
  iconName,
  formTitle,
  userId,
  onToggleModal,
  onRefreshTable,
  disabled,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    onToggleModal && onToggleModal(true);
    handleEditMode();
  };

  const handleRefresh = (data) => {
    onRefreshTable(data);
  };

  const handleClose = () => {
    setOpen(false);
    onToggleModal(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);
  const navigate = useNavigate();
  const { branch, setBranch } = useBranch();
  const [products, setProducts] = useState([]);
  const [patientCityId, setSelectedPatientCity] = useState("");
  const [patientInsuranceId, setSelectedPatientInsurance] = useState(0);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [category, setCategory] = useState("");
  const [lowStockThreshold, setLowStockThreshold] = useState("");
  const [location, setLocation] = useState("");
  const [cardNumber, setCardNumber] = useState(0);
  const [memberShipNumber, setMemberShipNumber] = useState(0);
  // use this for update insurance data and not insurance provider data
  const [insuranceId, setInsuranceId] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [firstVisit, setFirstVisit] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [invoiceCategory, setInvoiceCategory] = useState("");
  const [showLabel, setShowLabel] = useState(false);

  const [nextOfKinFirstName, setNextOfKinFirstName] = useState("");
  const [nextOfKinLastName, setNextOfKinLastName] = useState("");
  const [nextOfKinMiddleName, setNextOfKinMiddleName] = useState("");

  const [invoice, setInvoice] = useState([]);
  const [nextOfKinPhone, setNextOfKinPhone] = useState("");
  const [doctors, setDoctors] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [quantity, setQuantity] = useState("");
  const [reason, setReason] = useState([]);
  const [cost, setCost] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const [error, setError] = useState({
    id_type: "",
    id_number: "",
    full_name: "",
    email: "",
    phone_number: "",
    address: "",
    photo: null,
    gender: "M",
    marital_status: "S",
    residence: "",
    loan_category: "",
    repayment_method: "",
    bank_reference_number: "",
    branch_id: me?.branch?.id,
  });
  const [customerData, setCustomerData] = useState({
    id_type: "",
    id_number: "",
    full_name: "",
    email: "",
    phone_number: "",
    address: "",
    photo: null,
    gender: "M",
    marital_status: "S",
    residence: "",
    loan_category: "",
    repayment_method: "",
    bank_reference_number: "",
    bank_account_number: "",
    branch_id: me?.branch?.id,
  });
  const [guarantorData, setGuarantorData] = useState({
    id_type: "",
    id_number: "",
    full_name: "",
    phone_number: "+255",
    address: "",
    photo: null,
    gender: "M",
  });

  const [loanData, setLoanData] = useState({
    amount: "",
    loan_category: "",
    repayment_method: "",
    customer_id: userId,
    loan_contract: null,
    branch_id: me?.branch?.id,
    status:
      me?.group?.name !== "Cashier"
        ? "APPROVED_BY_CASHIER_AND_WAIT_FOR_LOAN_OFFICER"
        : "APPROVED_BY_CASHIER_AND_WAIT_FOR_LOAN_OFFICER",
  });
  const handleInputChange = (e) => {
    const { name, value: rawValue } = e.target;

    let formattedValue = rawValue;

    // Handle phone number formatting separately
    if (name === "phone_number") {
      formattedValue = formatPhoneNumber(rawValue);
      setCustomerData({
        ...customerData,
        [name]: formattedValue,
      });
      return; // Exit the function early since phone_number change does not need further processing
    }

    // fullnames into uppercases changes
    if (name === "full_name") {
      setCustomerData({
        ...customerData,
        full_name: rawValue.toUpperCase(),
      });
      return; // Exit the function early since id_type change does not need further processing
    }

    // Clear id_number if id_type changes
    if (name === "id_type") {
      setCustomerData({
        ...customerData,
        id_type: rawValue,
        id_number: "", // Clear id_number field
      });
      return; // Exit the function early since id_type change does not need further processing
    }

    // Format id_number based on id_type
    if (name === "id_number") {
      formattedValue =
        customerData.id_type === "NIDA"
          ? formatNidaId(rawValue)
          : customerData.id_type === "VOTER_ID"
          ? formatVoterId(rawValue)
          : rawValue;
    }

    setCustomerData({
      ...customerData,
      [name]: formattedValue,
    });
  };
  const handleGuarantorInputChange = (e) => {
    const { name, value: rawValue } = e.target;
    let formattedValue = rawValue;
    // fullnames into uppercases changes
    if (name === "full_name") {
      setGuarantorData({
        ...guarantorData,
        full_name: rawValue.toUpperCase(),
      });
      return; // Exit the function early since id_type change does not need further processing
    }

    // Handle phone number formatting separately
    if (name === "phone_number") {
      formattedValue = formatPhoneNumber(rawValue);
      setGuarantorData({
        ...guarantorData,
        [name]: formattedValue,
      });
      return; // Exit the function early since phone_number change does not need further processing
    }

    // Clear id_number if id_type changes
    if (name === "id_type") {
      setGuarantorData({
        ...guarantorData,
        id_type: rawValue,
        id_number: "", // Clear id_number field
      });
      return; // Exit the function early since id_type change does not need further processing
    }

    // Format id_number based on id_type
    if (name == "id_number") {
      formattedValue =
        guarantorData.id_type === "NIDA"
          ? formatNidaId(rawValue)
          : guarantorData.id_type === "VOTER_ID"
          ? formatVoterId(rawValue)
          : rawValue;
    }
    setGuarantorData({
      ...guarantorData,
      [name]: formattedValue,
    });
  };

  const handleLoanInputChange = (e) => {
    const { name, value: rawValue } = e.target;

    let formattedValue = rawValue;
    // Handle amount formatting separately
    if (name === "amount") {
      formattedValue = formatCureny3F(rawValue);
      setLoanData({
        ...loanData,
        amount: formattedValue,
      });
      return; // Exit the function early since phone_number change does not need further processing
    }

    // Handle amount formatting separately
    if (name === "loan_category") {
      setLoanData({
        ...loanData,
        loan_category: formattedValue,
        repayment_method: formattedValue === "EMERGENCY" ? "MONTHLY" : "", // Reset repayment method for other categories
      });
      return; // Exit the function early since phone_number change does not need further processing
    }

    setLoanData({
      ...loanData,
      [name]: rawValue,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    const file = files[0];

    // Check if the selected file is an image
    if (file && file.type.startsWith("image/")) {
      setCustomerData({
        ...customerData,
        [name]: file,
      });
    } else {
      swal({
        title: "Error",
        text: "Please select valid image file.",
        timer: 1300,
        buttons: false,
        icon: "error",
      });
      setCustomerData({
        ...customerData,
        [name]: null,
      });
    }
  };

  const handleGuarantorFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    // Check if the selected file is an image
    if (file && file.type.startsWith("image/")) {
      setGuarantorData({
        ...guarantorData,
        [name]: file,
      });
    } else {
      swal({
        title: "Error",
        text: "Please select valid image file.",
        timer: 1300,
        buttons: false,
        icon: "error",
      });
      return;
    }
  };

  const handleLoanFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file && file.type === "application/pdf") {
      setLoanData({
        ...loanData,
        [name]: file,
      });
    } else {
      // Display an error message or handle the invalid file type case
      swal({
        title: "Invalid File",
        text: "Please upload a valid PDF document.",
        icon: "error",
        timer: 2000,
        buttons: false,
      });
    }
  };

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const handleEditMode = () => {
    if (btnName === "Edit") {
      fetch(domain + "customers/customers/" + userId + "/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const branchId = data.branch?.id;

          setCustomerData({
            ...data,
            branch_id: branchId,
          });
          setGuarantorData(data.guarantor);
        });
    }
    if (btnName === "Invoice") {
      setShowSpinner(true);
      fetch(domain + "nmb/callback/check-invoice/" + userId, {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json(); // Proceed only if the response is OK (status 200)
          } else {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }
        })
        .then((data) => {
          setInvoice(data.data);
        })
        .finally(() => setShowSpinner(false));
    }
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    onToggleModal(!isOpen);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (btnName === "ADD") {
      setShowSpinner(true);
      let hasError = false;

      if (!customerData?.id_number) {
        setError((prevError) => ({
          ...prevError,
          id_number: "ID number is required",
        }));
        hasError = true;
      }

      if (!customerData?.id_type) {
        setError((prevError) => ({
          ...prevError,
          id_type: "ID type is required",
        }));
        hasError = true;
      }
      if (!customerData?.full_name) {
        setError((prevError) => ({
          ...prevError,
          full_name: "Full name is required",
        }));
        hasError = true;
      }

      if (!customerData?.phone_number) {
        setError((prevError) => ({
          ...prevError,
          phone_number: "Phone number is required",
        }));
        hasError = true;
      }

      if (hasError) {
        setShowSpinner(false);
        return; // Prevent submission if there are errors
      }
      setError({
        name: "",
        cost: "",
      }); // Clear errors

      const formData = new FormData();
      for (const key in customerData) {
        if (key !== "photo") {
          formData.append(key, customerData[key]);
        }
      }

      formData.append("guarantor.full_name", guarantorData.full_name);
      formData.append("guarantor.phone_number", guarantorData.phone_number);
      formData.append("guarantor.address", guarantorData.address);
      // formData.append('guarantor.photo', guarantorData.photo);
      formData.append("guarantor.gender", guarantorData.gender);

      const response = await fetch(domain + "customers/customers/", {
        method: "POST",
        headers: {
          Authorization: "JWT " + accessToken,
        },
        body: formData,
      });
      if (response.ok) {
        setShowSpinner(false);
        swal({
          title: "succes",
          text: "Customer added",
          icon: "success",
          timer: 2000,
          buttons: false,
        });
        setActiveStep(0);
        handleClose();
        handleRefresh(Math.random());
      } else {
        setShowSpinner(false);
        const body = await response.json();

        if (body) {
          const errors = body;
          for (const [field, messages] of Object.entries(errors)) {
            messages.forEach((message) => {
              swal({
                title: "Error",
                text: `${message}`,
                icon: "error",
                timer: 3000,
                buttons: false,
              });
            });
          }
        } else {
          setShowSpinner(false);
          swal({
            title: "Error",
            text: "An unexpected error occurred",
            icon: "error",
            timer: 2000,
            buttons: false,
          });
        }
      }
    }

    if (btnName.includes("Apply Loan")) {
      setShowSpinner(true);
      const formData = new FormData();
      // Create a copy of loanData to modify
      let dataToSubmit = { ...loanData };
      // set default loan duration based on repayment_method
      if (dataToSubmit.repayment_method === "DAILY") {
        dataToSubmit = { ...dataToSubmit, duration: 39 };
      } else {
        dataToSubmit = { ...dataToSubmit, duration: 7 };
      }
      // Remove loan_contract if loan_category is EMERGENCY
      if (dataToSubmit.loan_category === "EMERGENCY") {
        delete dataToSubmit.loan_contract;
      }

      for (const key in dataToSubmit) {
        if (key === "amount") {
          formData.append(key, removeCommas(dataToSubmit[key]));
        } else {
          formData.append(key, dataToSubmit[key]);
        }
        if (
          key === "loan_contract" &&
          dataToSubmit.loan_contract !== null &&
          (dataToSubmit[key] instanceof File ||
            dataToSubmit[key] instanceof Blob) &&
          dataToSubmit.category !== "EMERGENCY" // Skip if the category is EMERGENCY
        ) {
          formData.append(key, dataToSubmit[key]);
        }
      }

      const response = await fetch(domain + "loans/loans/", {
        method: "POST",
        headers: {
          Authorization: "JWT " + accessToken,
        },
        body: formData,
      });
      if (response.ok) {
        setShowSpinner(false);
        swal({
          title: "Success",
          text: "Loan application submitted successfully",
          icon: "success",
          timer: 2000,
          buttons: false,
        });
        setActiveStep(0);
        handleClose();
        handleRefresh(Math.random());
        navigate(`/loans/${userId}`);
      } else {
        setShowSpinner(false);
        const body = await response.json();

        if (body) {
          const errors = body;
          for (const [field, messages] of Object.entries(errors)) {
            messages.forEach((message) => {
              swal({
                title: "Error",
                text: `${field}: ${message}`,
                icon: "error",
                timer: 3000,
                buttons: false,
              });
            });
          }
        } else {
          swal({
            title: "Error",
            text: "An unexpected error occurred",
            icon: "error",
            timer: 2000,
            buttons: false,
          });
        }
      }
    }

    if (btnName === "Edit") {
      const formData = new FormData();
      for (const key in customerData) {
        formData.append(key, customerData[key]);
      }

      formData.append("guarantor.full_name", guarantorData.full_name);
      formData.append("guarantor.phone_number", guarantorData.phone_number);
      formData.append("guarantor.address", guarantorData.address);
      formData.append("guarantor.photo", guarantorData.photo);
      formData.append("guarantor.gender", guarantorData.gender);

      const response = await fetch(
        domain + "customers/customers/" + userId + "/",
        {
          method: "PUT",
          headers: {
            Authorization: "JWT " + accessToken,
          },
          body: formData,
        }
      );

      if (response.ok) {
        swal({
          title: "Success",
          text: "Customer updated",
          icon: "success",
          timer: 2000,
          buttons: false,
        });
        handleClose();
        // onRefreshTable("update");
        handleRefresh(Math.random());
        setActiveStep(0);
      } else {
        swal({
          title: "Error",
          text: "Error occurred",
          icon: "error",
          timer: 2000,
          buttons: false,
        });
      }
    }

    if (btnName === "DELETE") {
      togglePopup();

      const response = await fetch(
        domain + "api/running-expenses/" + userId + "/",
        {
          method: "DELETE",
          headers: {
            Authorization: "JWT " + accessToken,
          },
        }
      );

      if (response.ok) {
        swal({ title: " deleted", icon: "success" });
        handleRefresh(Math.random());
      } else {
        swal({ title: "Something went wrong", icon: "error" });
      }
    }
  };

  // Stepper settings
  const steps =
    btnName === "ADD" ? ["Customer Info"] : ["Customer Info", "Guarantor Info"];

  return (
    <React.Fragment>
      <button
        title={btnName}
        className={btnClass}
        onClick={handleClickOpen}
        disabled={disabled}
      >
        <i className={"fa fa-" + iconName + " m-r-5"}></i>
        {btnName}
      </button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {showSpinner && <LinearProgress />}
        {btnName === "DELETE" || btnName == "Invoice" || (
          <DialogTitle>{formTitle}</DialogTitle>
        )}
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            {(btnName === "ADD" ||
              (btnName === "Edit" && formTitle.includes("customer"))) && (
              <>
                <div className="row">
                  <div className="col-lg-12">
                    {btnName === "Edit" && (
                      <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                          const stepProps = {};
                          const labelProps = {};
                          if (isStepOptional(index)) {
                            labelProps.optional = (
                              <Typography variant="caption">
                                Optional
                              </Typography>
                            );
                          }
                          if (isStepSkipped(index)) {
                            stepProps.completed = false;
                          }
                          return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                    )}

                    {activeStep === steps.length ? (
                      <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          All steps completed - you&apos;re finished
                        </Typography>
                        <Box
                          sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        >
                          <Box sx={{ flex: "1 1 auto" }} />
                          <Button onClick={handleReset}>Review</Button>
                        </Box>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {btnName === "Edit" && (
                          <Typography sx={{ mt: 2, mb: 1 }}>
                            Step {activeStep + 1}
                          </Typography>
                        )}
                        <Box
                          component="form"
                          sx={{ mt: 3 }}
                          onSubmit={handleSubmit}
                        >
                          {activeStep === 0 && (
                            <Box>
                              <div className="row">
                                <div className="col-lg-6">
                                  <TextField
                                    label="ID Type *"
                                    select
                                    name="id_type"
                                    value={customerData.id_type}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!error.id_type}
                                    helperText={error.id_type}
                                  >
                                    <MenuItem value="NIDA">NIDA</MenuItem>
                                    <MenuItem value="VOTER_ID">
                                      Voter ID
                                    </MenuItem>
                                  </TextField>
                                  <TextField
                                    label="Full Name *"
                                    name="full_name"
                                    value={customerData.full_name}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!error.full_name}
                                    helperText={error.full_name}
                                  />
                                  <TextField
                                    label="Phone Number *"
                                    name="phone_number"
                                    value={customerData.phone_number}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!error.phone_number}
                                    helperText={error.phone_number}
                                  />

                                  <TextField
                                    label="Marital Status"
                                    select
                                    name="marital_status"
                                    value={customerData.marital_status}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                  >
                                    <MenuItem value="S">Single</MenuItem>
                                    <MenuItem value="M">Married</MenuItem>
                                    <MenuItem value="D">Divorced</MenuItem>
                                    <MenuItem value="W">Widowed</MenuItem>
                                  </TextField>
                                </div>
                                <div className="col-lg-6">
                                  <TextField
                                    label="ID Number *"
                                    name="id_number"
                                    value={customerData.id_number}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!error.id_number}
                                    helperText={error.id_number}
                                  />
                                  {btnName !== "ADD" && (
                                    <TextField
                                      label="Email"
                                      name="email"
                                      type="email"
                                      value={customerData.email}
                                      onChange={handleInputChange}
                                      fullWidth
                                      margin="normal"
                                      error={!!error.email}
                                      helperText={error.email}
                                    />
                                  )}
                                  <TextField
                                    label="Bank Account Number"
                                    name="bank_account_number"
                                    value={customerData.bank_account_number}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    // error={!!error.phone_number}
                                    // helperText={error.phone_number}
                                  />
                                  <TextField
                                    label="Address"
                                    name="address"
                                    value={customerData.address}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    // error={!!error}
                                    // helperText={error.address}
                                  />
                                  <TextField
                                    label="Gender"
                                    select
                                    name="gender"
                                    value={customerData.gender}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                  >
                                    <MenuItem value="M">Male</MenuItem>
                                    <MenuItem value="F">Female</MenuItem>
                                  </TextField>
                                </div>
                                <div className="col-lg-12">
                                  <TextField
                                    label="Residence"
                                    name="residence"
                                    value={customerData.residence}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                  />
                                  {btnName !== "ADD" && (
                                    <Button
                                      variant="contained"
                                      component="label"
                                      startIcon={<Upload />}
                                    >
                                      Upload Photo
                                      <input
                                        type="file"
                                        name="photo"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                      />
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </Box>
                          )}
                          {btnName == "Edit" && (
                            <>
                              {activeStep === 1 && (
                                <Box>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <TextField
                                        label="ID Type *"
                                        select
                                        name="id_type"
                                        value={guarantorData.id_type}
                                        onChange={handleGuarantorInputChange}
                                        fullWidth
                                        margin="normal"
                                      >
                                        <MenuItem value="NIDA">NIDA</MenuItem>
                                        <MenuItem value="VOTER_ID">
                                          Voter ID
                                        </MenuItem>
                                      </TextField>
                                      <TextField
                                        label="Full Name"
                                        name="full_name"
                                        value={guarantorData.full_name}
                                        onChange={handleGuarantorInputChange}
                                        fullWidth
                                        margin="normal"
                                      />
                                      <TextField
                                        label="Gender"
                                        select
                                        name="gender"
                                        value={guarantorData.gender}
                                        onChange={handleGuarantorInputChange}
                                        fullWidth
                                        margin="normal"
                                      >
                                        <MenuItem value="M">Male</MenuItem>
                                        <MenuItem value="F">Female</MenuItem>
                                      </TextField>
                                    </div>
                                    <div className="col-lg-6">
                                      <TextField
                                        label="ID Number *"
                                        name="id_number"
                                        value={guarantorData.id_number}
                                        onChange={handleGuarantorInputChange}
                                        fullWidth
                                        margin="normal"
                                      />

                                      <TextField
                                        type="tel"
                                        label="Phone Number"
                                        name="phone_number"
                                        value={guarantorData.phone_number}
                                        onChange={handleGuarantorInputChange}
                                        fullWidth
                                        length={13}
                                        margin="normal"
                                      />
                                      <TextField
                                        label="Address"
                                        name="address"
                                        value={guarantorData.address}
                                        onChange={handleGuarantorInputChange}
                                        fullWidth
                                        margin="normal"
                                      />
                                    </div>
                                    {btnName !== "ADD" && (
                                      <div className="col-lg-12">
                                        <Button
                                          variant="contained"
                                          component="label"
                                          startIcon={<Upload />}
                                        >
                                          Upload Photo
                                          <input
                                            type="file"
                                            name="photo"
                                            accept="image/*"
                                            onChange={handleGuarantorFileChange}
                                          />
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                </Box>
                              )}
                            </>
                          )}
                          {btnName === "Edit" && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                pt: 2,
                              }}
                            >
                              <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                              >
                                Back
                              </Button>
                              <Box sx={{ flex: "1 1 auto" }} />
                              {isStepOptional(activeStep) && (
                                <Button
                                  color="inherit"
                                  onClick={handleNext}
                                  sx={{ mr: 1 }}
                                >
                                  Skip
                                </Button>
                              )}
                              <Button
                                type={
                                  activeStep === steps.length - 1
                                    ? "button"
                                    : "button"
                                }
                                onClick={
                                  activeStep === steps.length - 1
                                    ? handleNext
                                    : handleNext
                                }
                              >
                                {activeStep === steps.length - 1
                                  ? "Finish"
                                  : "Next"}
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </>
            )}
            {btnName.includes("Apply Loan") && (
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <FormControl fullWidth sx={{ width: "63ch" }}>
                      <TextField
                        type="text"
                        label="Amount"
                        name="amount"
                        value={loanData.amount}
                        onChange={handleLoanInputChange}
                        fullWidthy
                        margin="normal"
                      />
                    </FormControl>
                    <TextField
                      label="Loan Category"
                      select
                      name="loan_category"
                      value={loanData.loan_category}
                      onChange={handleLoanInputChange}
                      fullWidth
                      margin="normal"
                    >
                      <MenuItem value="ENTREPRENEUR">Entrepreneur</MenuItem>
                      <MenuItem value="BUSINESS">Business</MenuItem>
                      <MenuItem value="WORKERS">Workers</MenuItem>
                      <MenuItem value="EMERGENCY">Emergency</MenuItem>
                    </TextField>
                    <TextField
                      label="Repayment Method"
                      select
                      name="repayment_method"
                      value={loanData.repayment_method}
                      onChange={handleLoanInputChange}
                      fullWidth
                      margin="normal"
                      disabled={loanData.loan_category === "EMERGENCY"}
                    >
                      <MenuItem value="DAILY">Daily</MenuItem>
                      <MenuItem value="WEEKLY">Weekly</MenuItem>
                      <MenuItem value="MONTHLY">Monthly</MenuItem>
                    </TextField>
                    {loanData.loan_category === "EMERGENCY" && (
                      <Tooltip
                        title="Emergency loans must be repaid monthly"
                        arrow
                      >
                        <p style={{ color: "#999" }}>
                          Repayment method is set to Monthly for Emergency
                          loans.
                        </p>
                      </Tooltip>
                    )}
                    <Button
                      disabled={loanData.loan_category === "EMERGENCY"}
                      variant="contained"
                      component="label"
                      sx={{ width: "35rem" }}
                      startIcon={<Upload />}
                    >
                      Upload Contract
                      <input
                        type="file"
                        name="loan_contract"
                        accept="application/pdf"
                        onChange={handleLoanFileChange}
                      />
                    </Button>
                  </div>
                </div>
              </>
            )}
            {btnName == "Invoice" && (
              <StyledPaper elevation={3}>
                <Title variant="h5" align="center" gutterBottom>
                  <img
                    src={nmb}
                    style={{
                      width: "15%",
                      height: "10%",
                    }}
                    alt="nmb logo"
                  />
                </Title>
                <Title variant="h5" align="center" gutterBottom>
                  Invoice Details
                </Title>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Invoice Number:</Typography>
                    <Typography variant="body1" color="text.secondary">
                      {invoice.reference}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ whiteSpace: "nowrap" }}>
                    <Typography variant="h6">Customer Name:</Typography>
                    <Typography variant="body1" color="text.secondary">
                      {invoice.student_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Invoice Type:</Typography>
                    <Typography variant="body1" color="text.secondary">
                      {invoice.type}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AmountContainer>
                      {/* <AttachMoneyIcon color="primary" /> */}
                      <Typography variant="h6">Invoice Amount:</Typography>
                    </AmountContainer>
                    <Typography variant="body1" color="text.secondary">
                      {formatCurrency(invoice?.amount)}
                    </Typography>
                  </Grid>
                </Grid>
              </StyledPaper>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-light m-1 float-right"
            onClick={handleClose}
          >
            CLOSE
          </button>
          {btnName === "DELETE" ? (
            <button
              type="submit"
              className="btn m-1 float-right btn-danger"
              onClick={handleSubmit}
            >
              DELETE
            </button>
          ) : (
            <button
              hidden={
                (btnName === "Edit" && activeStep !== steps.length) ||
                btnName == "Invoice"
              }
              type="submit"
              className="btn m-1 float-right btn-primary"
              onClick={handleSubmit}
            >
              SUBMIT
            </button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
