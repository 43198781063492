import moment from "moment";

const formatDate = (date) => {
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");
  const billDate = moment(date);

  return billDate.format("MMM D, YYYY HH:mm:ss a");
};

export default formatDate;
