import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import OrciBanner from '../components/OrciBanner';
import './Login.css';
import swal from 'sweetalert';
import { domain } from '../auth/domain';

export function ForgotPassword() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {

        event.preventDefault();
        const response = await fetch(domain + 'users/account/reset-password/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email
            })
        });

        if (response.ok) {
            swal({
                title: "Visit your Email to continue with Password resetting!",
                icon: "success",
            }).then(() => {
                navigate('/');
            });
        } else {

            // display success messages
            swal({
                title: "Oops",
                text: "Something wrong.Try again",
                icon: "error"
                // dangerMode: true,
            }).catch((error) => swal({ title: error }))
        }
    };

    return (
        <>

            <div>
                <div className="d-flex align-items-center auth px-0  vh-100">
                    <div className="row w-100 mx-0">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left py-5 px-4 px-sm-5 card" style={{ boxShadow: '0 10px 15px 0 rgba(0, 0, 0, 0.2)',borderRadius: '7px' }}>
                                <OrciBanner />
                                <Form className="pt-3 form-custom" onSubmit={handleSubmit}>
                                    <Form.Group className="d-flex search-field form-custom">
                                        <Form.Control required type="email" placeholder="Enter your Email" size="lg" className="h-auto form-custom" value={email} onChange={(event) => setEmail(event.target.value)} />
                                    </Form.Group>

                                    <div className="mt-3">
                                        <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">RESET PASSWORD</button>
                                    </div>
                                    <div className="text-center mt-4 font-weight-light">
                                        <Link to="/" className="text-primary">Back to Login</Link>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ForgotPassword;
