import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { domain } from "../auth/domain";
import Popup from "../components/Popup";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../components/CustomLoader";
import LinearProgress from "@mui/material/LinearProgress";
import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import swal from "sweetalert";
import formatCurrency from "../shared/formatCurrency";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import HandshakeIcon from "@mui/icons-material/Handshake";
import CollectionsIcon from "@mui/icons-material/Collections";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import PaymentIcon from "@mui/icons-material/Payment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ShieldIcon from "@mui/icons-material/Shield";
import { useBranch } from "../contexts/BranchContext";
import decodeJwtToken from "../auth/decodeJwtToken";
import formatRoleName from "../shared/formatRoleName";
import Alert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AlertTitle from "@mui/material/AlertTitle";
import NoRecords from "../shared/NoRecords";
import userImage from "../assets/img/user.jpg";
import { Chip, Grid, Paper } from "@mui/material";
import { formatDate } from "@canvasjs/charts";
import Tooltip from "@mui/material/Tooltip";
import "./Dashboard.css";
// import { format } from "date-fns";

export const AnnouncementModal = ({ open, handleClose, announcement }) => {
  if (!announcement) return null;

  const { title, body, created_by, created_at, is_active_till, category } =
    announcement;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          padding: 4,
          backgroundColor: "background.paper",
          borderRadius: 3,
          width: "50rem",
          maxHeight: "80vh", // Set a maximum height to prevent overflow
          margin: "auto",
          mt: 10,
          boxShadow: 24,
          position: "relative",
          overflowY: "auto", // Enable vertical scrolling when content overflows
          animation: "fadeIn 0.3s ease-in-out",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage:
              "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
            zIndex: -1,
          },
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{ fontWeight: "bold", color: "primary.main" }}
          >
            {title}
          </Typography>
          <Chip
            label={category}
            color={category === "URGENT" ? "error" : "warning"}
            sx={{ fontWeight: "bold", textTransform: "uppercase" }}
          />
        </Grid>
        <Typography sx={{ mb: 2, color: "text.secondary" }}>{body}</Typography>
        <Typography
          sx={{ mb: 2, color: "text.secondary", fontSize: "0.875rem" }}
        >
          <strong>Created by:</strong> {created_by}
        </Typography>
        <Typography
          sx={{ mb: 2, color: "text.secondary", fontSize: "0.875rem" }}
        >
          <strong>Created on:</strong> {formatDate(created_at)}
        </Typography>
        <Typography
          sx={{ mb: 2, color: "text.secondary", fontSize: "0.875rem" }}
        >
          <strong>Active until:</strong> {formatDate(is_active_till)}
        </Typography>
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          sx={{
            width: "100%",
            borderRadius: 3,
            paddingY: 1.5,
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
        >
          ACKNOWLEDGE
        </Button>
      </Box>
    </Modal>
  );
};

const Dashboard = () => {
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);
  const [showSpinner, setShowSpinner] = useState(false);
  const [bestSelling, setBestSelling] = useState(0);
  const [slowMoving, setSlowMoving] = useState(0);
  const [forms, setForms] = useState(0);
  const [expired, setExpired] = useState(0);
  const [outOfStock, setOutOfStock] = useState(0);
  const [lowStock, setLowStock] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [todaySales, setTodaySales] = useState(0);
  const [loans, setLoans] = useState(0);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { branch } = useBranch();
  const [customerReport, setCustomerReport] = useState({});
  const [loanReport, setLoanReport] = useState({});
  const [insuaranceReport, setInsuaranceReport] = useState({});
  const [collectionReport, setCollectionReport] = useState({});
  const [incomeReport, setIncomeReport] = useState({});
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleLogout = () => {
    // Perform logout logic here
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    navigate("/"); // Navigate to the login page
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const navigate = useNavigate();

  // fetch orders

  useEffect(() => {
    setShowSpinner(true);

    fetch(`${domain}loans/loans/loan_stats/?branch_id=${branch?.id}`, {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLoanReport(data);
      })
      .catch((error) => console.error(error))
      .finally(() => setShowSpinner(false));

    fetch(`${domain}customers/customers/stats/?branch_id=${branch?.id}`, {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCustomerReport(data);
      })
      .catch((error) => console.error(error))
      .finally(() => setShowSpinner(false));

    fetch(`${domain}loans/loans/?branch_id=${branch?.id}`, {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Access only the first 10 items
        const firstTenLoans = data.slice(0, 10);
        setLoans(firstTenLoans);
      })
      .catch((error) => console.error(error))
      .finally(() => setShowSpinner(false));

    fetch(`${domain}forms/forms/?branch_id=${branch?.id}`, {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["detail"]) {
          swal({ icon: "info", title: data["detail"] });
          handleLogout();
        }
        const totalForms = data.reduce((acc, current) => {
          return acc + current.total_forms;
        }, 0);

        setForms(totalForms);
      })
      .catch((error) => console.error(error))
      .finally(() => setShowSpinner(false));

    fetch(`${domain}customers/customers/?branch_id=${branch?.id}`, {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["detail"]) {
          swal({ icon: "info", title: data["detail"] });
          handleLogout();
        }
        setCustomers(data);
      })
      .catch((error) => console.error(error))
      .finally(() => setShowSpinner(false));

    fetch(`${domain}reports/collections/?branch_id=${branch?.id}`, {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCollectionReport(data);
      });

    fetch(`${domain}reports/incomes/?branch_id=${branch?.id}`, {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIncomeReport(data);
      });
  }, [branch]);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AnnouncementModal
        open={open}
        handleClose={handleClose}
        announcement={{
          id: 11,
          title: "Notify about announcement",
          body: "Notify about announcement Notify about announcement Notify about announcement Notify about announcement Notify about announcement Notify about announcement Notify about announcement",
          created_by: "ZAINABU BAKARI : Cashier",
          created_at: "2024-08-27T16:54:10.063588+03:00",
          updated_at: "2024-08-27T16:54:10.063588+03:00",
          is_active_till: "2024-08-29",
          category: "GENERAL",
          branches: [],
        }}
      />
      {showSpinner ? (
        <LinearProgress />
      ) : (
        <>
          <div className="row">
            <h3>Dashboard</h3>

            <div
              className="card-animate col-md-4 col-sm-4 col-lg-4 col-xl-4"
              // onClick={() => navigate("/products/best-selling")}
            >
              <div className="dash-widget aow-card">
                <span className="dash-widget-bg7">
                  <i className="fa fa-file" aria-hidden="true"></i>
                </span>
                <div className="dash-widget-info text-right">
                  <h3>{forms}</h3>
                  <span className="widget-title7">
                    Total Forms
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>

            <div
              className="card-animate col-md-4 col-sm-4 col-lg-4 col-xl-4"
              // onClick={() => navigate("/products/best-selling")}
            >
              <div className="dash-widget aow-card">
                <span className="dash-widget-bg7">
                  <i className="fa fa-users" aria-hidden="true"></i>
                </span>
                <div className="dash-widget-info text-right">
                  <h3>{customers?.length}</h3>
                  <span className="widget-title7">
                    Customers
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="card-animate col-md-4 col-sm-4 col-lg-4 col-xl-4"
              // onClick={() => navigate("/products/slow-moving")}
            >
              <div className="dash-widget aow-card">
                <span className="dash-widget-bg1">
                  {/* <i className="fa fa-dollar"></i> */}
                  <PaymentIcon sx={{ fontSize: "50px" }} />
                </span>
                <div className="dash-widget-info text-right">
                  <h3>{formatCurrency(loanReport?.total)}</h3>
                  <span className="">
                    Loans
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="card-animate col-md-6 col-sm-6 col-lg-6 col-xl-4"
              // onClick={() => navigate("/products/out-of-stock")}
            >
              <div className="dash-widget aow-card">
                <span className="dash-widget-bg3">
                  <i class="fa fa-briefcase"></i>
                </span>
                <div className="dash-widget-info text-right">
                  <h3>{formatCurrency(collectionReport?.total)}</h3>
                  <span className="">
                    Collections
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>

            <div
              className="card-animate col-md-6 col-sm-6 col-lg-6 col-xl-4"
              // onClick={() => navigate("/products/low-stock")}
            >
              <div className="dash-widget aow-card">
                <span className="dash-widget-bg2">
                  <PriceCheckIcon sx={{ fontSize: "60px" }} />
                </span>
                <div className="dash-widget-info text-right">
                  <h3>{formatCurrency(incomeReport?.total)}</h3>
                  <span className="">
                    Income <i className="fa fa-check" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="card-animate col-md-6 col-sm-6 col-lg-6 col-xl-4"
              // onClick={() => navigate("/products/expired")}
            >
              <div className="dash-widget aow-card">
                <span className="dash-widget-bg5">
                  {/* <i className="fa fa-times-circle" aria-hidden="true"></i> */}
                  <ShieldIcon sx={{ fontSize: "45px" }} />
                </span>
                <div className="dash-widget-info text-right">
                  <h3>{formatCurrency(loanReport?.total * 0.1)}</h3>
                  <span className="">
                    Insurance<i className="fa fa-check" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card aow-card">
                <div className="card-header">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label="Customers" {...a11yProps(0)} />
                    <Tab label="Loans" {...a11yProps(1)} />
                    <Tab label="Collections" {...a11yProps(2)} />
                    <Tab label="Income" {...a11yProps(3)} />
                    <Tab label="Insurance" {...a11yProps(4)} />
                  </Tabs>
                </div>

                <div className="card-body p-0">
                  <div className="table-responsive">
                    <SwipeableViews
                      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                      index={value}
                      onChangeIndex={handleChangeIndex}
                    >
                      <TabPanel value={value} index={0} dir={theme.direction}>
                        <table
                          id="app"
                          className="datatable table table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Today</th>
                              <th>This Week</th>
                              <th>This Month</th>
                              <th>This Year</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{customerReport?.total_today}</td>
                              <td>{customerReport?.total_this_week}</td>
                              <td>{customerReport?.total_this_month}</td>
                              <td>{customerReport?.total_this_year}</td>
                            </tr>
                          </tbody>
                        </table>
                      </TabPanel>
                      <TabPanel value={value} index={1} dir={theme.direction}>
                        <table
                          id="app"
                          className="datatable table table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Today</th>
                              <th>This Week</th>
                              <th>This Month</th>
                              <th>This Year</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{formatCurrency(loanReport?.total_today)}</td>
                              <td>
                                {formatCurrency(loanReport?.total_this_week)}
                              </td>
                              <td>
                                {formatCurrency(loanReport?.total_this_month)}
                              </td>
                              <td>
                                {formatCurrency(loanReport?.total_this_year)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </TabPanel>
                      <TabPanel value={value} index={2} dir={theme.direction}>
                        <table
                          id="app"
                          className="datatable table table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Today</th>
                              <th>This Week</th>
                              <th>This Month</th>
                              <th>This Year</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {formatCurrency(collectionReport?.total_today)}
                              </td>
                              <td>
                                {formatCurrency(
                                  collectionReport?.total_this_week
                                )}
                              </td>
                              <td>
                                {formatCurrency(
                                  collectionReport?.total_this_month
                                )}
                              </td>
                              <td>
                                {formatCurrency(
                                  collectionReport?.total_this_year
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </TabPanel>
                      <TabPanel value={value} index={3} dir={theme.direction}>
                        <table
                          id="app"
                          className="datatable table table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Today</th>
                              <th>This Week</th>
                              <th>This Month</th>
                              <th>This Year</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {formatCurrency(incomeReport?.total_today)}
                              </td>
                              <td>
                                {formatCurrency(incomeReport?.total_this_week)}
                              </td>
                              <td>
                                {formatCurrency(incomeReport?.total_this_month)}
                              </td>
                              <td>
                                {formatCurrency(incomeReport?.total_this_year)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </TabPanel>
                      <TabPanel value={value} index={4} dir={theme.direction}>
                        <table
                          id="app"
                          className="datatable table table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Today</th>
                              <th>This Week</th>
                              <th>This Month</th>
                              <th>This Year</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {formatCurrency(loanReport?.total_today * 0.1)}
                              </td>
                              <td>
                                {formatCurrency(
                                  loanReport?.total_this_week * 0.1
                                )}
                              </td>
                              <td>
                                {formatCurrency(
                                  loanReport?.total_this_month * 0.1
                                )}
                              </td>
                              <td>
                                {formatCurrency(
                                  loanReport?.total_this_year * 0.1
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </TabPanel>
                    </SwipeableViews>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div
                style={{
                  boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)",
                  borderRadius: "7px",
                  whiteSpace: "nowrap",
                }}
              >
                <div className="card-header">
                  <h5 className="d-inline-block ml-2 mt-3">
                    10 Latest Loan Applications
                  </h5>

                  <button
                    className="btn btn-secondary float-right m-3"
                    onClick={() => navigate("/loans")}
                  >
                    View
                  </button>
                </div>
                <div className="card-block">
                  <table id="app" className="datatable table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Customer</th>
                        <th>Branch</th>
                        <th>Category</th>
                        <th>Amount</th>
                        <th>Repayment</th>
                        <th>StartDate</th>
                        <th>EndDate</th>
                        <th>Status</th>

                        {/* <th className="text-right">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {(() => {
                        try {
                          return loans.map((loan, index) => {
                            const groupName = me?.group?.name;
                            const loanStatus = loan?.status;

                            return (
                              <tr key={loan?.id}>
                                <td>{index + 1}</td>
                                <td>{loan?.customer?.full_name}</td>
                                <td>{loan?.branch?.name}</td>
                                <td>{loan?.loan_category}</td>
                                <td>{formatCurrency(loan?.amount)}</td>
                                <td>{loan?.repayment_method}</td>

                                <td>{loan?.start_date}</td>
                                <td>{loan?.end_date}</td>
                                <td>
                                  {loanStatus.includes("REJECTED") ? (
                                    <span className="custom-badge status-red">
                                      {loanStatus !== "REJECTED" ? (
                                        <>
                                          {formatRoleName(loanStatus)}
                                          <Tooltip
                                            title={loan?.rejection_reason}
                                            arrow
                                          >
                                            <i className="fa fa-info-circle ml-2"></i>
                                          </Tooltip>
                                        </>
                                      ) : (
                                        formatRoleName(loanStatus)
                                      )}
                                    </span>
                                  ) : (
                                    <>
                                      {/* Blue badge for statuses waiting for approval by the current user's group */}
                                      {formatRoleName(loanStatus).includes(
                                        `Wait For ${groupName}`
                                      ) ? (
                                        <span className="custom-badge status-blue">
                                          {formatRoleName(loanStatus)}
                                          {/* {"Waiting for you"} */}
                                        </span>
                                      ) : loanStatus ===
                                        "APPROVED_BY_GENERAL_MANAGER" ? (
                                        <span className="custom-badge status-green">
                                          {formatRoleName(loanStatus)}
                                        </span>
                                      ) : loanStatus.startsWith("APPROVED") ? (
                                        <span className="custom-badge status-orange">
                                          {formatRoleName(loanStatus)}
                                        </span>
                                      ) : (
                                        <span className="custom-badge status-green">
                                          {formatRoleName(loanStatus)}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          });
                        } catch (error) {
                          console.log(error);
                          return (
                            <tr>
                              <td colSpan={10}>
                                <Alert severity="error">
                                  Network Problem.Please check your Internet
                                </Alert>
                              </td>
                            </tr>
                          );
                        }
                      })()}
                    </tbody>
                  </table>
                  <NoRecords data={loans} />
                </div>
              </div>
            </div>

            {/* <div className="col-md-3">
              <div class="card member-panel">
                <div class="card-header bg-white">
                  <h4 class="card-title mb-0">Doctors</h4>
                </div>
                <div class="card-body">
                  <ul class="contact-list">
                    <li>
                      <div class="contact-cont">
                        <div class="float-left user-img m-r-10">
                          <a href="profile.html" title="Richard Miles">
                            <img
                              src={userImage}
                              alt=""
                              class="w-40 rounded-circle"
                            />
                            <span class="status away"></span>
                          </a>
                        </div>
                        <div class="contact-info">
                          <span class="contact-name text-ellipsis">
                            Richard Miles
                          </span>
                          <span class="contact-date">MBBS, MD</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="card-footer text-center bg-white">
                  <a href="doctors.html" class="text-muted">
                    View all Doctors
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
