import React, { Component } from "react";
import cheapchapLogo from "../assets/img/cheapchapLogo.png";
import "./OrciBanner.css";
import logo from "../assets/img/logo-Photoroom.png";
import { brandName } from "../shared/setting";

class OrciBanner extends Component {
  render() {
    return (
      <div className="account-logo">
        <img
          src={logo}
          alt={brandName + "logo"}
          className="image"
          width={300}
          height={150}
        />
        {/* <h4>{brandName}</h4> */}
      </div>
    );
  }
}

export default OrciBanner;
