import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import "../assets/css/bootstrap.min.css";
import "../assets/css/font-awesome.min.css";
// font - awesome.min.css;
import "../assets/css/style.css";

import userImg from "../assets/img/user.jpg";
import logo from "../assets/img/logo-Photoroom.png";
import { useNavigate } from "react-router-dom";
import decodeJwtToken from "../auth/decodeJwtToken";
import { domain } from "../auth/domain";
import getTimeAgo from "../shared/getTimeAgo";
import { brandName } from "../shared/setting";
import swal from "sweetalert";
import UndoIcon from "@mui/icons-material/Undo";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import FilterListIcon from "@mui/icons-material/FilterList";
import "../shared/card.css";
import { useBranch } from "../contexts/BranchContext";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { useLoanNotification } from "../contexts/LoanNotificationContext";
import { useExpensesNotification } from "../contexts/ExpensesNotificationContext";
import DescriptionIcon from "@mui/icons-material/Description";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import Alert from "@mui/material/Alert";
import { ErrorOutline, WarningAmber, CheckCircle } from "@mui/icons-material";
import { Typography, Box, Paper } from "@mui/material";
import { AnnouncementModal } from "../pages/Dashboard";

function MainLayout({ onLogout, activePage }) {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  const [user, setUser] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [branches, setBranches] = useState([]);
  const [subdrop, setSubDrop] = useState(false);
  const me = decodeJwtToken(accessToken);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  const navLink = {
    textDecoration: "none",
  };

  // state for modal announcement
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  // end
  const { branch, setBranch } = useBranch();
  const { loanNotification, setLoanNotification } = useLoanNotification();
  const { expensesNotification, setExpensesNotification } =
    useExpensesNotification();

  const handleAnnouncementModal = (notification) => {
    setSelectedAnnouncement(notification.announcement);
    setOpen(true);

    // mark announcement as read after open it
    fetch(
      domain +
        `announcements/announcements/${notification?.announcement?.id}/mark_as_read/`,
      {
        method: "POST",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      }
    )
      .then((response) => response.json())
      .then(() => {
        fetchAnnouncement();
      })
      .catch((error) => console.error(error));
  };
  const handleSidebarCollapsed = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };
  const expensesNotify = () => {
    // Fetch user types
    let status = "";
    if (me?.group?.name === "Cashier") {
      status = "";
    } else if (me?.group?.name === "Branch Manager") {
      status = "PENDING";
    } else if (me?.group?.name === "General Manager") {
      status = "APPROVED_BY_BRANCH_MANAGER";
    }
    fetch(
      domain + `expenses/expenses/?branch_id=${branch?.id}&status=${status}`,
      {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setExpensesNotification(data.length);
      })
      .catch((error) => console.error(error));
  };
  // Fetch notifications for loans
  const loansNotify = () => {
    let status = "";
    if (me?.group?.name === "Cashier") {
      status = "";
    } else if (me?.group?.name === "Loan Officer") {
      status = "APPROVED_BY_CASHIER_AND_WAIT_FOR_LOAN_OFFICER";
    } else if (me?.group?.name === "Branch Manager") {
      status = "APPROVED_BY_LOAN_OFFICER_AND_WAIT_FOR_BRANCH_MANAGER";
    } else if (me?.group?.name === "General Manager") {
      status = "APPROVED_BY_BRANCH_MANAGER_AND_WAIT_FOR_GENERAL_MANAGER";
    } else {
      if (me?.group?.name === "Admin") {
        status = "";
      }
    }

    let url = domain + `loans/loans/?branch_id=${branch?.id}&status=${status}`;

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLoanNotification(data.length);
      })
      .catch((error) => console.error(error));
  };
  const fetchAnnouncement = () => {
    fetch(domain + "announcements/announcement-read-status/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Filter valid notifications based on the is_active_till date
        const validAnnouncements = data.filter((notification) => {
          const announcementDate = new Date(
            notification.announcement.is_active_till
          );
          return announcementDate >= new Date(); // Compare announcement date with current date
        });

        // Set notifications with valid announcements
        setNotifications(validAnnouncements);

        // Calculate the number of unread notifications
        const count = validAnnouncements.filter(
          (notification) => !notification.is_read
        ).length;

        // Set the count of unread notifications
        setUnreadNotificationsCount(count);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    loansNotify();
    expensesNotify();

    fetch(domain + "branches/branches/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBranches(data);
      })
      .catch((error) => console.error(error));

    fetchAnnouncement();
  }, []);
  const active = location.pathname.split("/").pop();

  const handleLogout = () => {
    swal({
      title: "Are you sure you want to logout?",
      icon: "warning",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        // Perform logout logic here
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");

        onLogout(); // Call the onLogout callback to update the authentication state
        // reset user branch
        // setBranch({});
        navigate("/"); // Navigate to the login page
      }
    });
  };

  return (
    <>
      <body className={` ${isSidebarCollapsed ? "mini-sidebar" : ""}`}>
        <div
          className={`main-wrapper  ${isSidebarCollapsed ? "slide-nav" : ""}`}
          style={{
            width: "100%",
            maxHeight: "100vh",
            overflowX: "auto",
          }}
        >
          <div className="header">
            <div className="header-left">
              {/* branch list here  */}

              {/* end branch list here  */}
              <a className="logo">
                <img src={logo} alt="" width={80} height={45} />
                <span style={{ color: "gray" }}>{branch?.name}</span>
              </a>
            </div>
            {/* menu icon for large screen */}
            <a id="toggle_btn" href="javascript:void(0);">
              <i
                className="fa fa-bars"
                style={{ color: "#800b33", textDecoration: "none" }}
                onClick={handleSidebarCollapsed}
              />
            </a>
            <a id="mobile_btn" className="mobile_btn float-left">
              <i
                className="fa fa-bars"
                style={{ color: "#800b33", textDecoration: "none" }}
                onClick={handleSidebarCollapsed}
              />
            </a>
            <ul className="nav user-menu float-right">
              {["Admin", "General Manager"].includes(me?.group?.name) && (
                <>
                  <li className="nav-item dropdown d-none d-sm-block">
                    <a
                      className="dropdown-toggle nav-link"
                      data-toggle="dropdown"
                    >
                      {/* <i className="fa fa-bell-o" style={{color:'gray'}} /> */}
                      <FilterListIcon sx={{ color: "gray" }} />
                    </a>
                    <div className="dropdown-menu notifications">
                      <div className="topnav-dropdown-header">
                        <span>Branches</span>
                      </div>
                      <div className="drop-scroll">
                        <ul className="notification-list">
                          <li className="notification-message">
                            <a
                              onClick={() => setBranch({ id: "", name: "All" })}
                            >
                              <div className="media">
                                <span className="avatar"></span>
                                <div className="media-body">
                                  <p className="noti-details">
                                    <span className="noti-title">{"All"}</span>
                                  </p>
                                </div>
                              </div>
                            </a>
                          </li>
                          {branches.map((branch, index) => (
                            <li className="notification-message">
                              <a onClick={() => setBranch(branch)}>
                                <div className="media">
                                  <span className="avatar">{index + 1}</span>
                                  <div className="media-body">
                                    <p className="noti-details">
                                      <span className="noti-title">
                                        {branch.name}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="topnav-dropdown-footer"></div>
                    </div>
                  </li>
                </>
              )}
              <li className="nav-item dropdown d-none d-sm-block">
                <a
                  href="#"
                  className="dropdown-toggle nav-link"
                  data-toggle="dropdown"
                >
                  <i className="fa fa-bell-o" style={{ color: "gray" }} />{" "}
                  <span className="badge badge-pill bg-danger float-right">
                    {unreadNotificationsCount}
                  </span>
                </a>
                <div className="dropdown-menu notifications">
                  <div className="topnav-dropdown-header">
                    <span>Notifications</span>
                  </div>
                  <div className="drop-scroll">
                    <ul className="notification-list">
                      {notifications.map((notification, index) => (
                        <li className="notification-message">
                          <a
                            onClick={() =>
                              handleAnnouncementModal(notification)
                            }
                          >
                            <div className="media">
                              <span className="avatar">
                                {notification?.announcement?.category ===
                                "URGENT"
                                  ? "U"
                                  : notification?.announcement?.category ===
                                    "IMPORTANT"
                                  ? "I"
                                  : "G"}
                              </span>
                              <div className="media-body">
                                <p className="noti-details">
                                  <span className="noti-title">
                                    {notification?.announcement?.title}
                                  </span>
                                </p>
                                <p className="noti-time">
                                  <span className="notification-time">
                                    {getTimeAgo(
                                      notification?.announcement?.created_at
                                    )}
                                  </span>
                                  {notification?.is_read && (
                                    <span className="text-right">
                                      <CheckCircle
                                        sx={{
                                          fontSize: "15px",
                                          color: "#4CAF50",
                                        }}
                                      />
                                    </span>
                                  )}
                                </p>
                              </div>
                            </div>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </li>

              <li className="nav-item dropdown d-none d-sm-block">
                <a
                  href="javascript:void(0);"
                  id="open_msg_box"
                  className="hasnotifications nav-link"
                >
                  <i className="fa fa-comment-o" style={{ color: "gray" }} />{" "}
                  <span className="badge badge-pill bg-danger float-right">
                    0
                  </span>
                </a>
              </li>

              <Dropdown>
                <Dropdown.Toggle variant="link" id="dropdown-basic">
                  <span className="user-img">
                    <img
                      className="rounded-circle"
                      src={userImg}
                      alt="Admin"
                      width={40}
                    />
                    <span className="status online" />
                  </span>
                  {/* <h4 className="text-primary">{user.email}</h4> */}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link
                      className=""
                      style={{ color: "#800b33", textDecoration: "none" }}
                      to="/user-profile"
                    >
                      My Profile
                    </Link>
                  </Dropdown.Item>

                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
            <div className="dropdown mobile-user-menu float-right">
              <Dropdown>
                <Dropdown.Toggle variant="link" id="dropdown-basic">
                  <span className="user-img">
                    <img
                      className="rounded-circle"
                      src={user}
                      alt="Admin"
                      width={40}
                    />
                    <span className="status online" />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link
                      className=""
                      style={{ color: "#800b33", textDecoration: "none" }}
                      to="/user-profile"
                    >
                      My Profile
                    </Link>
                  </Dropdown.Item>

                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className={"sidebar"} id="sidebar">
            {/* isSidebarCollapsed ? "opened */}
            <div
              className="sidebar-inner slimscroll"
              style={{
                width: "100%",
                maxHeight: "100vh",
                overflowY: "auto",
              }}
            >
              <div id="sidebar-menu" className="sidebar-menu">
                <ul>
                  <li className="menu-title">{me?.group?.name}</li>
                  {/* All users */}
                  <li className={active === "dashboard" && "active"}>
                    <NavLink to="/dashboard" style={navLink}>
                      <i className="fa fa-dashboard" /> <span>Dashboard</span>
                    </NavLink>
                  </li>
                  <li className={active === "customers" && "active"}>
                    <NavLink to="/customers" style={navLink}>
                      <i className="fa fa-archive" /> <span>Customers</span>
                    </NavLink>
                  </li>
                  <li className={active === "loans" && "active"}>
                    <NavLink to="/loans" style={navLink}>
                      <i className="fa fa-archive" /> <span>Loans</span>
                      {["Admin", "Cashier"].includes(me?.group?.name) || (
                        <span class="badge badge-pill bg-danger float-right">
                          {loanNotification}
                        </span>
                      )}
                    </NavLink>
                  </li>
                  {/* Loan Officer */}
                  {["Loan Officer"].includes(me?.group?.name) || (
                    <li className={active === "expenses" && "active"}>
                      <NavLink to="/expenses" style={navLink}>
                        <i className="fa fa-money" /> <span>Expenses</span>
                        {["Cashier", "Admin"].includes(me?.group?.name) || (
                          <span class="badge badge-pill bg-danger float-right">
                            {expensesNotification}
                          </span>
                        )}
                      </NavLink>
                    </li>
                  )}

                  {/* GM, BM && Admin */}
                  {["General Manager", "Branch Manager", "Admin"].includes(
                    me?.group?.name
                  ) && (
                    <li className={active === "inventories" && "active"}>
                      <NavLink to="/inventories" style={navLink}>
                        <i className="fa fa-pie-chart" />{" "}
                        <span>Inventories</span>
                      </NavLink>
                    </li>
                  )}
                  <li
                    className={
                      "submenu" +
                      (active === "return-sales" ||
                        active === "return-purchases")
                    }
                  >
                    <NavLink
                      class={subdrop && "subdrop"}
                      onClick={() => setSubDrop(!subdrop)}
                      style={navLink}
                    >
                      <i className="fa fa-file"></i>
                      <span>Reports</span>
                      <span class="menu-arrow"></span>
                    </NavLink>
                    <ul style={{ display: subdrop ? "block" : "none" }}>
                      <>
                        <li>
                          <NavLink
                            to="/loan-report"
                            className={"text-decoration-none"}
                            style={navLink}
                          >
                            <span>Loans</span>
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            to="/repayments"
                            className={"text-decoration-none"}
                            style={navLink}
                          >
                            <span>Repayments</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/general-report"
                            className={"text-decoration-none"}
                            style={navLink}
                          >
                            <span>General</span>
                          </NavLink>
                        </li>
                      </>
                    </ul>
                  </li>
                  {/* Admin & GM */}
                  {["Admin", "General Manager"].includes(me?.group?.name) && (
                    <>
                      <li className={active === "sms" && "active"}>
                        <NavLink to="/sms" style={navLink}>
                          <i className="fa fa-envelope" /> <span>SMS</span>
                        </NavLink>
                      </li>

                      <li className={active === "announcement" && "active"}>
                        <NavLink to="/announcement" style={navLink}>
                          <FontAwesomeIcon icon={faBullhorn} />
                          <span>Announcement</span>
                        </NavLink>
                      </li>
                    </>
                  )}
                  {/* all user */}
                  <li className={active === "reconciliations" && "active"}>
                    <NavLink to="/reconciliations" style={navLink}>
                      <i className="fa fa-users" /> <span>Reconciliation</span>
                    </NavLink>
                  </li>
                  {/* admin only */}
                  {me?.group?.name == "Admin" && (
                    <>
                      <li className={active === "users" && "active"}>
                        <NavLink to="/users" style={navLink}>
                          <i className="fa fa-users" /> <span>Users</span>
                        </NavLink>
                      </li>

                      <li className={active === "branches" && "active"}>
                        <NavLink to="/branches" style={navLink}>
                          <GroupWorkIcon /> <span>Branches</span>
                        </NavLink>
                      </li>

                      <li className={active === "forms" && "active"}>
                        <NavLink to="/forms" style={navLink}>
                          <DescriptionIcon /> <span>Forms</span>
                        </NavLink>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="page-wrapper">
            <div className="content">
              <div className="row">
                {/* component here */}
                {activePage}
                {/* component end by aow */}
              </div>
            </div>
            <div className="notification-box">
              <div className="msg-sidebar notifications msg-noti">
                <div className="topnav-dropdown-header">
                  <span>Messages</span>
                </div>
                <div className="drop-scroll msg-list-scroll" id="msg_list">
                  {/* <ul className="list-box">
                    <li>
                      <a href="chat.html">
                        <div className="list-item new-message">
                          <div className="list-left">
                            <span className="avatar">J</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">John Doe</span>
                            <span className="message-time">1 Aug</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">T</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              {" "}
                              Tarah Shropshire{" "}
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">M</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">Mike Litorus</span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">C</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              {" "}
                              Catherine Manseau{" "}
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">D</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              {" "}
                              Domenic Houston{" "}
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">B</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              {" "}
                              Buster Wigton{" "}
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">R</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              {" "}
                              Rolland Webber{" "}
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">C</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              {" "}
                              Claire Mapes{" "}
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">M</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              Melita Faucher
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">J</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              Jeffery Lalor
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">L</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">Loren Gatlin</span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="chat.html">
                        <div className="list-item">
                          <div className="list-left">
                            <span className="avatar">T</span>
                          </div>
                          <div className="list-body">
                            <span className="message-author">
                              Tarah Shropshire
                            </span>
                            <span className="message-time">12:28 AM</span>
                            <div className="clearfix" />
                            <span className="message-content">
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                            </span>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul> */}
                </div>
                <div className="topnav-dropdown-footer">
                  <a href="chat.html">See all messages</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar-overlay" data-reff="" />
        {selectedAnnouncement && (
          <AnnouncementModal
            open={open}
            handleClose={handleClose}
            announcement={selectedAnnouncement}
          />
        )}
      </body>
      {/* blank-page24:04*/}
    </>
  );
}
export default MainLayout;
