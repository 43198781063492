import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../shared/Form.css";
import { domain } from "../auth/domain";
import UserRolePopup from "../components/CustomerDialog";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import NoRecords from "../shared/NoRecords";
import { LinearProgress } from "@mui/material";
import formatRoleName from "../shared/formatRoleName";
import { useBranch } from "../contexts/BranchContext";
import decodeJwtToken from "../auth/decodeJwtToken";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";
import FilterIcon from "@mui/icons-material/FilterList";
import TuneIcon from "@mui/icons-material/Tune";
import RefreshIcon from "@mui/icons-material/Refresh";
import SyncIcon from "@mui/icons-material/Sync";
import Backdrop from "@mui/material/Backdrop";
import { Box, FormControl, Alert, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import EventIcon from "@mui/icons-material/Event";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  MenuItem,
  Stack,
} from "@mui/material";
import formatCurrency from "../shared/formatCurrency";
import { formatDateN } from "../shared/setting";

export default function Reconciliations() {
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [filteredUserTypes, setFilteredUserTypes] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState("");
  const navigate = useNavigate();
  const { branch, setBranch } = useBranch();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [loanPortfolio, setLoanPortfolio] = useState("");
  const [receiptAvailables, setReceiptAvailables] = useState([]);
  const [reconcileDate, setReconcileDate] = useState(null);
  const handleBackDrop = () => setShowBackDrop(!Backdrop);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    const query = event.target.value.toLowerCase();
    const filtered = userTypes.filter((userType) =>
      userType.customer_name.toLowerCase().includes(query)
    );
    setFilteredUserTypes(filtered);
  };

  // handle react page visibility based on open/clode of popup
  const handleToggleModal = (modalState) => {
    setIsModalOpen(modalState);
  };

  const handleTableRefresh = (info) => {
    setRefreshTable(info);
  };

  // Implement a function to handle the search functionality
  // const handleSearch = (event) => {
  //   const query = event.target.value.toLowerCase();
  //   const filtered = userTypes.filter((userType) =>
  //     userType.full_name.toLowerCase().includes(query)
  //   );
  //   setFilteredUserTypes(filtered);
  // };
  // Implement a function to handle the page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Use the currentPage and perPage state variables to calculate
  // the slice of applications to be displayed on the current page

  const offset = currentPage * perPage;
  const paginatedUserTypes = filteredUserTypes.slice(offset, offset + perPage);

  const handlePrintForm = async (customerId) => {
    try {
      const result = await swal({
        title: "Are you sure you want to Print form?",
        icon: "warning",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (result) {
        const response = await fetch(domain + "forms/form-items/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            customer_id: customerId,
            branch_id: branch?.id,
          }),
        });

        if (response.ok) {
          swal({
            title: "success",
            text: "Form successful printed",
            icon: "success",
            buttons: false,
            timer: 2000,
          });
          // handleClose();
          // onRefreshTable("update");
          const body = await response.json();
          // navigate(body?.form_file_url)
          // window.location.href = body?.form_file_url;
          window.open(body?.form_file_url, "_blank");

          handleTableRefresh(Math.random());
        } else {
          const body = await response.json();
          const details = body?.detail;
          swal({
            title: "Failed",
            text: details ? details : "Error occured",
            icon: "error",
            buttons: false,
          });
        }
      }
    } catch (error) {}
  };

  const handleDeleteCustomer = async (customerId) => {
    try {
      const result = await swal({
        title: "Are you sure you want to delete this customer ?",
        icon: "warning",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (result) {
        const response = await fetch(
          domain + "customers/customers/" + customerId,
          {
            method: "DELETE",
            headers: {
              Authorization: "JWT " + accessToken,
            },
          }
        );

        if (response.ok) {
          swal({
            title: "success",
            text: "Customer successful deleted",
            icon: "success",
            buttons: false,
            timer: 2000,
          });

          handleTableRefresh(Math.random());
          navigate("/customers");
        } else {
          const body = await response.json();
          const details = body?.detail;
          swal({
            title: "Failed",
            text: details ? details : "Error occured",
            icon: "error",
            buttons: false,
            timer: 1500,
          });
        }
      }
    } catch (error) {}
  };

  const handleReconciliation = async (invoice) => {
    try {
      const result = await swal({
        title: "Are you sure you want to Reconcile?",
        icon: "warning",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (result) {
        setShowBackDrop(true);
        const response = await fetch(domain + "nmb/callback/", {
          method: "POST",
          headers: {
            Authorization: "JWT " + accessToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...invoice,
            api_key: process.env.REACT_APP_API_KEY,
            api_secret: process.env.REACT_APP_API_SECRET,
          }),
        });

        if (response.ok) {
          setShowBackDrop(false);
          const body = await response.json();
          const status = body?.status;
          status == 1
            ? swal({
                title: "success",
                text: "successful updated",
                icon: "success",
                buttons: false,
                timer: 2000,
              })
            : swal({
                title: "error",
                text: body?.description,
                icon: "error",
                buttons: false,
                timer: 2000,
              });
        } else {
          setShowBackDrop(false);
          const body = await response.json();
          const details = body?.detail;
          swal({
            title: "Failed",
            text: details ? details : "Error occured",
            icon: "error",
            buttons: false,
            timer: 1500,
          });
        }
      }
    } catch (error) {}
  };

  const handleReconcileDateChange = (e) => {
    const today = new Date(e.target.value);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
    const day = String(today.getDate()).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year}`;
    setReconcileDate(formattedDate);
  };

  // fetch Current users
  useEffect(() => {
    const url = reconcileDate
      ? `nmb/callback/reconciliation/${reconcileDate}/?branch_id=${branch?.id}`
      : `nmb/callback/reconciliation/?branch_id=${branch?.id}`;

    setBranch(branch);
    setShowSpinner(true);

    // Clear existing data when a new reconciliation date is set
    setFilteredUserTypes([]);
    setCurrentPage(0);
    const fetchReceipts = async () => {
      try {
        // Third fetch: repayments/penalties
        const penaltiesResponse = await fetch(domain + "reports/receipts/", {
          method: "GET",
          headers: {
            Authorization: "JWT " + accessToken,
          },
        });
        const penaltiesData = await penaltiesResponse.json();

        // Set all receipts together using a functional update
        setReceiptAvailables(penaltiesData.receipt_numbers);

        // Fourth fetch: nmb/callback/reconciliation
        const reconciliationResponse = await fetch(domain + url, {
          method: "GET",
          headers: {
            Authorization: "JWT " + accessToken,
          },
        });
        const reconciliationData = await reconciliationResponse.json();

        if (reconciliationData["error"]) {
          swal({ icon: "error", title: reconciliationData["error"] });
          // navigate("/dashboard");
          return;
        }
        if (reconciliationData["status"] === 0) {
          swal({ icon: "error", title: reconciliationData["description"] });
          // navigate("/dashboard");
          return;
        }
        setUserTypes(reconciliationData?.transactions);
        setFilteredUserTypes(reconciliationData?.transactions);
      } catch (error) {
        console.error(error);
      } finally {
        setShowSpinner(false);
      }
    };

    fetchReceipts();
  }, [reconcileDate]);

  return (
    <div className="row">
      <Backdrop
        sx={(theme) => ({ color: "#800b33", zIndex: theme.zIndex.drawer + 1 })}
        open={showBackDrop}
        onClick={handleBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Search & Filter
          <IconButton
            onClick={() => setOpen(false)}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {/* {searchTerm && (
                    <IconButton onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  )} */}
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            style={{ marginBottom: 20 }}
          />
          <TextField
            type="date"
            label="Date"
            name="loanPortfolio"
            value={reconcileDate}
            onChange={handleReconcileDateChange}
            fullWidth
            margin="normal"
            inputProps={{
              max: new Date().toISOString().split("T")[0], // Sets the max date to today
            }}
          ></TextField>
        </DialogContent>
      </Dialog>
      {showSpinner && <LinearProgress />}
      <h3>Reconciliations</h3>
      <div class="text-right mb-1">
        <IconButton
          onClick={() => setOpen(true)}
          style={{
            // backgroundColor: "#800b33",
            // color: "#fff",
            marginLeft: "5px",
          }}
        >
          <TuneIcon />
        </IconButton>
      </div>
      <Box display="flex" justifyContent="start" mb={2}>
        <Alert
          icon={<EventIcon fontSize="inherit" />}
          severity="info"
          style={{
            maxWidth: "400px",
            padding: "15px",
            borderRadius: "8px",
            backgroundColor: "#e3f2fd", // light blue
            color: "#0d47a1", // dark blue for text
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            fontWeight: "bold",
          }}
        >
          <Typography variant="subtitle1" component="span">
            Showing data for date:
          </Typography>{" "}
          <Typography
            variant="h6"
            component="span"
            style={{ fontWeight: "bold" }}
          >
            {reconcileDate || formatDateN(new Date())}
          </Typography>
        </Alert>
      </Box>
      <table
        id="app"
        className="datatable table table-striped"
        style={{
          boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)",
          borderRadius: "7px",
          whiteSpace: "nowrap",
        }}
      >
        <thead>
          <tr>
            <th>Date</th>
            <th>Customer&nbsp;Name</th>
            <th>Reference&nbsp;Number</th>
            <th>Bank&nbsp;Account&nbsp;Number</th>
            <th>Amount</th>
            <th>Receipt</th>
            <th>Channel</th>
            <th>Status</th>

            <th className="text-right">Action</th>
          </tr>
        </thead>
        <tbody>
          {(() => {
            try {
              return paginatedUserTypes.map((userType) => (
                <tr key={userType?.reference}>
                  <td>{userType?.timestamp}</td>
                  <td>{userType?.customer_name}</td>
                  <td>{userType?.reference}</td>
                  <td>{userType?.account_number}</td>
                  <td>{formatCurrency(userType?.amount)}</td>
                  <td>{userType?.receipt}</td>
                  <td>{userType?.channel}</td>
                  <td>
                    {receiptAvailables?.find(
                      (rec) => rec === userType?.receipt
                    ) ? (
                      <span className="custom-badge status-green">SUCCESS</span>
                    ) : (
                      <span className="custom-badge status-red">FAILED</span>
                    )}
                  </td>
                  <td class="text-right">
                    <div class="dropdown dropdown-action">
                      <span
                        href="#"
                        class="action-icon dropdown-toggle"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-ellipsis-v"></i>
                      </span>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        x-placement="bottom-end"
                        style={{
                          position: "absolute",
                          willChange: "transform",
                          top: "0px",
                          left: "0px",
                          transform: "translate3d(-102px, 27px, 0px)",
                        }}
                      >
                        {/* <UserRolePopup
                          onToggleModal={handleToggleModal}
                          onRefreshTable={handleTableRefresh}
                          btnName="Invoice"
                          iconName={"file"}
                          btnClass={"dropdown-item"}
                          formTitle={"NMB Invoice"}
                          userId={userType.id}
                        /> */}
                        <button
                          class="dropdown-item"
                          onClick={() => handleReconciliation(userType)}
                        >
                          <i class="m-r-1">
                            <RefreshIcon style={{ fontSize: "18px" }} />
                          </i>
                          Reconcile
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ));
            } catch (error) {
              console.log(error);
              return (
                <tr>
                  <td>Network Problem.Please check your Internet</td>
                </tr>
              );
            }
          })()}
        </tbody>
      </table>
      <NoRecords data={filteredUserTypes} />

      {!isModalOpen && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageCount={Math.ceil(filteredUserTypes.length / perPage)}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            nextClassName="page-item"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
          />
          <FormControl variant="normal" size="small">
            <TextField
              select
              value={perPage}
              onChange={(e) => {
                setPerPage(parseInt(e.target.value));
                // setCurrentPage(1); // Reset to first page when perPage changes
              }}
            >
              {[5, 10, 20, 50].map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Box>
      )}

      {/* {showSpinner && <CustomLoader />} */}
    </div>
  );
}
