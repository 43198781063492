import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../shared/Form.css";
import { domain } from "../auth/domain";
import CustomLoader from "../components/CustomLoader";
import PatientsPopup from "../components/PurchasePopup";
import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import "../shared/Form.css";
import swal from "sweetalert";
import Select from "react-select";
import decodeJwtToken from "../auth/decodeJwtToken";
import userImg from "../assets/img/user.jpg";
import { LinearProgress, TextField } from "@mui/material";
import moment from "moment";
import SaleDialog from "../components/PurchaseDialog";
import formatCurrency from "../shared/formatCurrency";
import { EmailShareButton, WhatsappShareButton } from "react-share";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Order() {
  const accessToken = localStorage.getItem("accessToken");
  const [sales, setSales] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [filteredSales, setFilteredSales] = useState(sales);
  const [showSpinner, setShowSpinner] = useState(false);
  const [insurances, setInsurances] = useState([]);
  const [cities, setCities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [purchaseReturned, setPurchaseReturned] = useState([]);
  const [pdfData, setPdfData] = useState("");
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const [open, setOpen] = useState(false);
  const [invoiceItems, setInvoiceItems] = useState([sales]);
  const [suppliers, setSuppliers] = useState("");
  const [supplierId, setSelectedSupplier] = useState("");

  const handleSupplierChange = (event) => {
    const selectedSupplierId = event.target.value;

    setSelectedSupplier(selectedSupplierId);
  };

  // handle react page visibility based on open/clode of popup
  const handleToggleModal = (modalState) => {
    setIsModalOpen(modalState);
  };

  const handleInputChange = (id, field, value) => {
    if (field === "order_quantity") {
      // alert(id + " " + field + " " + value + "object=>");
      setSales(
        sales.map((item) =>
          item.id === id
            ? {
                ...item,
                [field]: value,
              }
            : item
        )
      );
      // alert(JSON.stringify([...invoiceItems]));
    } else {
      setSales(
        sales.map((item) =>
          item.id === id ? { ...item, [field]: value } : item
        )
      );
    }
  };

  const generatePdf = async (event) => {
    event.preventDefault();

    // Validate order quantity for checked items
    const invalidItems = sales.filter(
      (item) =>
        item.checked === "on" &&
        (!item.order_quantity || item.order_quantity <= 0)
    );

    if (invalidItems.length > 0) {
      // Display error message or handle invalid items as needed
      console.log("Invalid items:", invalidItems);
      swal({ title: "Invalid order quantity", icon: "error" });
      return;
    }

    // Only include checked items when generating the PDF
    const updatedItems = sales.filter((item) => item.checked === "on");
    if (updatedItems.length > 0) {
      const response = await fetch(
        domain +
          "api/order-pdf/?supplier=" +
          supplierId +
          "&data=" +
          JSON.stringify([...updatedItems]),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          // body: JSON.stringify({ sale: [...sales] }),
        }
      );

      if (response.ok) {
        swal({ title: "Order created successful", icon: "success" });

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setPdfData(url);
        setOpen(true);
      } else {
        swal({ title: "Error occured", icon: "error" });
      }
    } else {
      swal({ icon: "info", title: "Choose atleast one Item" });
    }
  };

  // Implement a function to handle the search functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = sales.filter(
      (patient) => patient.name.toLowerCase().includes(query)
      // patient.stock_quantity.toLowerCase().includes(query)
      // staff.user_type.toLowerCase().includes(query)
    );
    setFilteredSales(filtered);
  };
  // Implement a function to handle the page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const formatDate = (date) => {
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");
    const billDate = moment(date);

    if (billDate.isSame(today, "d")) {
      return "TODAY";
    } else if (billDate.isSame(yesterday, "d")) {
      return "YESTERDAY";
    } else {
      return billDate.format("MMM D, YYYY");
    }
  };

  // Use the currentPage and perPage state variables to calculate
  // the slice of applications to be displayed on the current page

  const offset = currentPage * perPage;
  const paginatedPatients = filteredSales.slice(offset, offset + perPage);

  // Fetch Current patients
  const fetchSales = () => {
    setShowSpinner(true);

    fetch(domain + "api/products/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Sort the products based on stock quantity in ascending order
        const sortedProducts = data.sort(
          (a, b) => a.stock_quantity - b.stock_quantity
        );

        setSales(sortedProducts);
        setFilteredSales(data);
        setShowSpinner(false);
      })
      .catch((error) => console.error(error))
      .finally(() => setShowSpinner(false));
  };

  useEffect(() => {
    setShowSpinner(true);
    fetchSales();
    fetch(domain + "api/purchases-return/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPurchaseReturned(data);
      });
    fetch(domain + "api/suppliers/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSuppliers(data);
      });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  // Function to get insurance provider based on insurance id
  const getLocation = (insuranceId) => {
    const insurance = insurances.find(
      (insurance) => insurance.id === insuranceId
    );
    return insurance ? insurance.name : "";
  };

  // Function to get city name based on city id
  const getReturn = (saleId) => {
    const sale = purchaseReturned.find((sale) => sale.purchase === saleId);
    return sale ? sale : "";
  };

  return (
    <div className="row">
      {showSpinner && <LinearProgress />}
      <h3>Order</h3>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Order</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          {pdfData ? (
            <iframe
              title="PDF Viewer"
              src={pdfData}
              style={{ width: "100%", height: "400px" }}
            />
          ) : (
            <div>
              <LinearProgress />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-danger m-1 float-right"
            onClick={handleClose}
          >
            X
          </button>
          <EmailShareButton url={pdfData}>
            <i className="btn btn-white fa fa-envelope"></i>
          </EmailShareButton>
          <WhatsappShareButton url={pdfData}>
            <i className="btn btn-success fa fa-whatsapp"></i>
          </WhatsappShareButton>
        </DialogActions>
      </Dialog>

      <table
        id="app"
        className="datatable table table-striped"
        style={{
          boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)",
          borderRadius: "7px",
        }}
      >
        <thead>
          <tr>
            <th>#</th>

            <th>Item</th>
            <th>Stock Quantity</th>
            <th>Order Quantity</th>
          </tr>
        </thead>
        <tbody>
          {(() => {
            try {
              return sales.map((sale, index) => (
                <tr key={sale.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={sale.checked === "on"}
                      onChange={(e) =>
                        handleInputChange(
                          sale.id,
                          "checked",
                          e.target.checked ? "on" : "off"
                        )
                      }
                    />
                  </td>

                  <td>{sale.name}</td>

                  <td>{sale.stock_quantity}</td>
                  <td>
                    <TextField
                      type="text"
                      placeholder="Enter Quantity"
                      value={sale.order_quantity || ""}
                      onChange={(e) =>
                        handleInputChange(
                          sale.id,
                          "order_quantity",
                          e.target.value
                        )
                      }
                    />
                    {sale.order_quantity_error && (
                      <span style={{ color: "red" }}>
                        {sale.order_quantity_error}
                      </span>
                    )}
                  </td>
                </tr>
              ));
            } catch (error) {
              console.log(error);
              return (
                <div className="col-sm-12">
                  Network Problem.Please check your Internet
                </div>
              );
            }
          })()}
        </tbody>
      </table>
      <select
        required
        class="select form-control col-md-12 m-3"
        value={supplierId}
        onChange={handleSupplierChange}
      >
        <option>Select supplier</option>
        {(() => {
          try {
            return suppliers.map((pr) => (
              <option value={pr.full_name + ". " + pr.address} key={pr.id}>
                {pr.full_name}
              </option>
            ));
          } catch (error) {
            console.log(error);
            return <div>Network Problem.Please check your Internet</div>;
          }
        })()}
      </select>

      <div>
        <button
          disabled={sales.some(
            (item) =>
              item.checked === "on" &&
              (!item.order_quantity || item.order_quantity <= 0)
          )}
          onClick={generatePdf}
          className="btn btn-primary"
        >
          CREATE
        </button>
        {/* <EmailShareButton url="https://mui.com/material-ui/react-table/">
          <i className="fa fa-emai">email</i>
        </EmailShareButton>
        <WhatsappShareButton url="https://mui.com/material-ui/react-table/">
          whatsapp
        </WhatsappShareButton> */}
      </div>
      {/* {showSpinner && <LinearProgress/>} */}
    </div>
  );
}

export default Order;
