import { Link, NavLink, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../shared/Form.css";
import { domain } from "../auth/domain";
import CustomLoader from "../components/CustomLoader";
import AlertDialogSlide from "../components/CustomerDialog";
import LoanDialog from "../components/AlertDialogSlide";
import PatientsPopup from "../components/ProductPopup";
import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import "../shared/Form.css";
import swal from "sweetalert";
import Select from "react-select";
import decodeJwtToken from "../auth/decodeJwtToken";
import userImg from "../assets/img/user.jpg";
import { LinearProgress } from "@mui/material";
import moment from "moment";
import { useLocation } from "react-router-dom";
import formatCurrency from "../shared/formatCurrency";
import { useBranch } from "../contexts/BranchContext";
import { useLoanNotification } from "../contexts/LoanNotificationContext";
import formatRoleName from "../shared/formatRoleName";
import NoRecords from "../shared/NoRecords";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TextField, Box, MenuItem, FormControl } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Loans({ route }) {
  const location = useLocation();
  const { action } = location.state || {};
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);
  const [status, setStatus] = useState("");
  const [dataCollection, setDataCollectionFile] = useState("NONE");
  const [cv, setCvFile] = useState(null);
  const [coverLetter, setCoverLetterFile] = useState(null);
  const [consentForm, setConsentFormFile] = useState("NONE");
  const [introductionLetter, setIntroductionLetter] = useState("NONE");
  const [activeTab, setActiveTab] = useState(0);
  const [tabCompletion, setTabCompletion] = useState([false, false, false]);
  const irbApprovalRef = useRef("NONE");
  const consentFormRef = useRef("NONE");
  const dataCollectionRef = useRef("NONE");
  const introductionLetterRef = useRef("NONE");
  const studyProposalRef = useRef(null);
  const cvRef = useRef(null);
  const coverLetterRef = useRef(null);
  const navigate = useNavigate();
  const durationList = [];
  const countryOptions = [];
  const { branch, setBranch } = useBranch();
  const { loanNotification, setLoanNotification } = useLoanNotification();
  const [patients, setPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [filteredPatients, setFilteredPatients] = useState(patients);
  const [showSpinner, setShowSpinner] = useState(false);
  const [insurances, setInsurances] = useState([]);
  const [cities, setCities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { category } = useParams();
  const [refreshTable, setRefreshTable] = useState("");
  const [open, setOpen] = React.useState(false);
  const [use_description, setUseDescription] = useState(false);
  const [use_expiry_date, setUseExpiryDate] = useState(false);
  const [use_batch_number, setUseBatchNumber] = useState(false);
  const [filterMode, setFilterMode] = useState(false);

  const { customerId } = useParams();
  const handleClose = () => {
    setOpen(false);
  };
  // handle react page visibility based on open/clode of popup
  const handleToggleModal = (modalState) => {
    setIsModalOpen(modalState);
  };

  const handleTableRefresh = (info) => {
    setRefreshTable(info);
  };

  // Implement a function to handle the search functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = patients.filter(
      (patient) =>
        patient.name.toLowerCase().includes(query) ||
        patient.category.toLowerCase().includes(query) ||
        patient.description.toLowerCase().includes(query)
      // staff.user_type.toLowerCase().includes(query)
    );
    setFilteredPatients(filtered);
  };
  // Implement a function to handle the page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const formatDate = (date) => {
    const today = moment().startOf("day");
    const billDate = moment(date);
    const formattedDate = billDate.format("MMM D, yyyy");

    if (billDate.isBefore(today, "d") || billDate.isSame(today, "d")) {
      return "EXPIRED";
    } else {
      return formattedDate;
    }
  };

  const formatDateN = (date) => {
    const billDate = moment(date);

    // Check if the date is valid
    if (!billDate.isValid()) {
      return ""; // Return an empty string if the date is invalid
    }

    // Format the date if it's valid
    return billDate.format("MMM D, yyyy");
  };

  const handleApproveLoan = async (userId) => {
    try {
      const result = await swal({
        title: "Are you sure you want to Approve this Loan?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (result) {
        // Determine the status based on the user's group
        let status = "";
        if (me?.group?.name === "Cashier") {
          status = "";
        } else if (me?.group?.name === "Loan Officer") {
          status = "APPROVED_BY_LOAN_OFFICER_AND_WAIT_FOR_BRANCH_MANAGER";
        } else if (me?.group?.name === "Branch Manager") {
          status = "APPROVED_BY_BRANCH_MANAGER_AND_WAIT_FOR_GENERAL_MANAGER";
        } else if (me?.group?.name === "General Manager") {
          status = "APPROVED_BY_GENERAL_MANAGER";
        } else {
          swal({
            title: "Unauthorized",
            text: "You are not authorized to approve loans",
            icon: "error",
            buttons: false,
          });
          return;
        }

        // Send the approval request
        const response = await fetch(
          domain + "loans/loans/" + userId + "/approve/",
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: "JWT " + accessToken,
            },
            body: JSON.stringify({ status: status }),
          }
        );

        if (response.ok) {
          swal({
            title: "Success",
            text: "Loan successfully approved",
            icon: "success",
            buttons: false,
            timer: 2000,
          });
          handleTableRefresh(Math.random());
        } else {
          const body = await response.json();
          const details = body?.detail;
          swal({
            title: "Failed",
            text: details ? details : "Error occurred",
            icon: "error",
            buttons: false,
            timer: 1300,
          });
        }
      }
    } catch (error) {
      console.error("Error approving loan:", error);
      swal({
        title: "Error",
        text: "An unexpected error occurred",
        icon: "error",
        buttons: false,
      });
    }
  };

  // Use the currentPage and perPage state variables to calculate
  // the slice of applications to be displayed on the current page

  const offset = currentPage * perPage;
  const paginatedPatients = filteredPatients.slice(offset, offset + perPage);

  // handle loans filtering
  const statusOptions = [
    {
      value: "APPROVED_BY_CASHIER_AND_WAIT_FOR_LOAN_OFFICER",
      label: "Approved by Cashier, Waiting for Loan Officer",
    },
    {
      value: "APPROVED_BY_LOAN_OFFICER_AND_WAIT_FOR_BRANCH_MANAGER",
      label: "Approved by Loan Officer, Waiting for Branch Manager",
    },
    {
      value: "APPROVED_BY_BRANCH_MANAGER_AND_WAIT_FOR_GENERAL_MANAGER",
      label: "Approved by Branch Manager, Waiting for General Manager",
    },
    {
      value: "APPROVED_BY_GENERAL_MANAGER",
      label: "Approved by General Manager",
    },
    { value: "REJECTED_BY_LOAN_OFFICER", label: "Rejected by Loan Officer" },
    { value: "REJECTED_BY_CASHIER", label: "Rejected by Cashier" },
    {
      value: "REJECTED_BY_BRANCH_MANAGER",
      label: "Rejected by Branch Manager",
    },
    {
      value: "REJECTED_BY_GENERAL_MANAGER",
      label: "Rejected by General Manager",
    },
  ];
  const handleLoanStatus = () => {
    setFilterMode(true);
    setOpen(true);
    fetchPatients();
  };
  // end
  // Fetch Current patients
  const fetchPatients = () => {
    setShowSpinner(true);

    // if (!filterMode) {
    //   if (me?.group?.name === "Loan Officer") {
    //     setStatus("APPROVED_BY_CASHIER_AND_WAIT_FOR_LOAN_OFFICER");
    //   } else if (me?.group?.name === "Cashier") {
    //     setStatus("");
    //   } else if (me?.group?.name === "Branch Manager") {
    //     setStatus("APPROVED_BY_LOAN_OFFICER_AND_WAIT_FOR_BRANCH_MANAGER");
    //   } else if (me?.group?.name === "General Manager") {
    //     setStatus("APPROVED_BY_BRANCH_MANAGER_AND_WAIT_FOR_GENERAL_MANAGER");
    //   } else {
    //     if (me?.group?.name === "Admin") {
    //       setStatus("");
    //     }
    //   }
    // }

    let url = domain + `loans/loans/?branch_id=${branch?.id}&status=${status}`;
    if (customerId) {
      url += `&customer=${customerId}`;
    }
    try {
      fetch(url, {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json(); // Proceed only if the response is OK (status 200)
          } else {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }
        })
        .then((data) => {
          setPatients(data);
          setFilteredPatients(data);
          setShowSpinner(false);

          const loansWaitForYou = data.filter((loan) => {
            let s = "";
            if (me?.group?.name === "Loan Officer") {
              s = "APPROVED_BY_CASHIER_AND_WAIT_FOR_LOAN_OFFICER";
            } else if (me?.group?.name === "Cashier") {
              s = "";
            } else if (me?.group?.name === "Branch Manager") {
              s = "APPROVED_BY_LOAN_OFFICER_AND_WAIT_FOR_BRANCH_MANAGER";
            } else if (me?.group?.name === "General Manager") {
              s = "APPROVED_BY_BRANCH_MANAGER_AND_WAIT_FOR_GENERAL_MANAGER";
            } else if (me?.group?.name === "Admin") {
              s = "";
            }
            return loan?.status === s;
          });

          setLoanNotification(loansWaitForYou.length);
        })
        .catch((error) => {
          console.error(error);
          // swal({
          //   title: "Error",
          //   text: error.message,
          //   icon: "error",
          //   buttons: false,
          //   timer: 1300,
          // });
        })
        .finally(() => setShowSpinner(false));
    } catch (error) {
      swal({
        title: "Error",
        text: "An unexpected error occurred.",
        icon: "error",
        buttons: false,
        timer: 1300,
      });
    }
  };

  useEffect(() => {
    setBranch(branch);

    try {
      setShowSpinner(true);
      fetchPatients();
    } catch (error) {
      console.error(error);
    }
  }, [refreshTable, branch, status]);

  // Function to get insurance provider based on insurance id
  const getLocation = (insuranceId) => {
    const insurance = insurances.find(
      (insurance) => insurance.id === insuranceId
    );
    return insurance ? insurance.name : "";
  };

  // Function to get city name based on city id
  const getCity = (cityId) => {
    const city = cities.find((city) => city.id === cityId);
    return city ? city.name : "";
  };

  return (
    <div className="row">
      {/* dialog for filter loans */}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <FormControl fullWidth sx={{ width: "60ch" }}>
              <TextField
                label="Status"
                select
                name="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                fullWidth
                margin="normal"
              >
                <MenuItem value="">All</MenuItem>
                {statusOptions.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-light m-1 float-right"
            onClick={handleClose}
          >
            CLOSE
          </button>

          {/* <button
            type="submit"
            className="btn m-1 float-right btn-primary"
            // onClick={handleSubmit}
          >
            SEARCH
          </button> */}
        </DialogActions>
      </Dialog>
      {showSpinner && <LinearProgress />}
      <h3>Loans</h3>
      <div className="text-right mb-2">
        <span onClick={handleLoanStatus}>
          <FilterListIcon />
        </span>
      </div>

      <table
        id="app"
        className="datatable table table-striped"
        style={{
          boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)",
          borderRadius: "7px",
          whiteSpace: "nowrap",
        }}
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Customer</th>
            <th>Category</th>
            <th>Amount</th>
            {/* <th>Insurance</th> */}
            <th>Repayment</th>
            <th>Interest</th>
            <th>StartDate</th>
            <th>EndDate</th>

            <th>Status</th>

            <th className="text-right">Action</th>
          </tr>
        </thead>
        <tbody>
          {(() => {
            try {
              return paginatedPatients.map((loan, index) => {
                const groupName = me?.group?.name;
                const loanStatus = loan?.status;

                const hideButtons =
                  groupName === "Admin" ||
                  (groupName === "Loan Officer" &&
                    loanStatus !==
                      "APPROVED_BY_CASHIER_AND_WAIT_FOR_LOAN_OFFICER") ||
                  (groupName === "Branch Manager" &&
                    loanStatus !==
                      "APPROVED_BY_LOAN_OFFICER_AND_WAIT_FOR_BRANCH_MANAGER") ||
                  (groupName === "General Manager" &&
                    loanStatus !==
                      "APPROVED_BY_BRANCH_MANAGER_AND_WAIT_FOR_GENERAL_MANAGER") ||
                  groupName === "Cashier" ||
                  [
                    "REJECTED_BY_LOAN_OFFICER",
                    "REJECTED_BY_LOAN_OFFICER",
                    "REJECTED_BY_CASHIER",
                    "REJECTED_BY_CASHIER",
                    "REJECTED_BY_BRANCH_MANAGER",
                    "REJECTED_BY_BRANCH_MANAGER",
                    "REJECTED_BY_GENERAL_MANAGER",
                    "REJECTED_BY_GENERAL_MANAGER",
                  ].includes(loanStatus);

                return (
                  <tr key={loan.id}>
                    <td>{index + 1}</td>
                    <td>{loan?.customer?.full_name}</td>
                    <td>{loan?.loan_category}</td>
                    <td>{formatCurrency(loan?.amount)}</td>
                    {/* <td>{loan?.insurance * 100}%</td> */}
                    <td>{loan?.repayment_method}</td>
                    <td>{loan?.interest_rate * 100}%</td>
                    <td>{formatDateN(loan?.start_date)}</td>
                    <td>{formatDateN(loan?.end_date)}</td>

                    {/* <td>
                      {loanStatus.includes("REJECTED") ? (
                        <span className="custom-badge status-red">
                          {loanStatus !== "REJECTED" ? (
                            <>
                              {formatRoleName(loanStatus)}
                              <Tooltip title={loan.rejection_reason} arrow>
                                <i className="fa fa-info-circle ml-2"></i>
                              </Tooltip>
                            </>
                          ) : (
                            formatRoleName(loanStatus)
                          )}
                        </span>
                      ) : (
                        <span className="custom-badge status-green">
                          {formatRoleName(loanStatus)}
                        </span>
                      )}
                    </td> */}
                    <td>
                      {loanStatus.includes("REJECTED") ? (
                        <span className="custom-badge status-red">
                          {loanStatus !== "REJECTED" ? (
                            <>
                              {formatRoleName(loanStatus)}
                              <Tooltip title={loan.rejection_reason} arrow>
                                <i className="fa fa-info-circle ml-2"></i>
                              </Tooltip>
                            </>
                          ) : (
                            formatRoleName(loanStatus)
                          )}
                        </span>
                      ) : (
                        <>
                          {/* Blue badge for statuses waiting for approval by the current user's group */}
                          {formatRoleName(loanStatus).includes(
                            `Wait For ${groupName}`
                          ) ? (
                            <span className="custom-badge status-blue">
                              {formatRoleName(loanStatus)}
                              {/* {"Waiting for you"} */}
                            </span>
                          ) : loanStatus === "APPROVED_BY_GENERAL_MANAGER" ? (
                            <span className="custom-badge status-green">
                              {formatRoleName(loanStatus)}
                            </span>
                          ) : loanStatus.startsWith("APPROVED") ? (
                            <span className="custom-badge status-orange">
                              {formatRoleName(loanStatus)}
                            </span>
                          ) : (
                            <span className="custom-badge status-green">
                              {formatRoleName(loanStatus)}
                            </span>
                          )}
                        </>
                      )}
                    </td>

                    <td className="text-right">
                      <div className="dropdown dropdown-action">
                        <a
                          href="#"
                          className="action-icon dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa fa-ellipsis-v"></i>
                        </a>
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          x-placement="bottom-end"
                          style={{
                            position: "absolute",
                            willChange: "transform",
                            top: "0px",
                            left: "0px",
                            transform: "translate3d(-102px, 27px, 0px)",
                          }}
                        >
                          {groupName === "Admin" &&
                            loanStatus === "APPROVED_BY_GENERAL_MANAGER" && (
                              <LoanDialog
                                onToggleModal={handleToggleModal}
                                onRefreshTable={handleTableRefresh}
                                btnName="Edit"
                                iconName={"pencil"}
                                btnClass={"dropdown-item"}
                                formTitle={"Update Loan"}
                                userId={loan.id}
                              />
                            )}
                          {!hideButtons && (
                            <>
                              <button
                                className="dropdown-item"
                                onClick={() => handleApproveLoan(loan?.id)}
                              >
                                <i className="fa fa-check m-r-5"></i>Approve
                              </button>
                              <LoanDialog
                                onToggleModal={handleToggleModal}
                                onRefreshTable={handleTableRefresh}
                                btnName="Reject"
                                iconName={"times"}
                                btnClass={"dropdown-item"}
                                formTitle={"Reject loan"}
                                userId={loan.id}
                              />
                            </>
                          )}
                          <a
                            target="_blank"
                            className="dropdown-item"
                            href={
                              process.env.REACT_APP_ENV == "DEV"
                                ? loan?.loan_contract_url
                                : loan?.loan_contract
                            }
                          >
                            <i className="fa fa-file m-r-5"></i> Contract
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              });
            } catch (error) {
              console.log(error);
              return (
                <tr>
                  <td>Network Problem.Please check your Internet</td>
                </tr>
              );
            }
          })()}
        </tbody>
      </table>
      <NoRecords data={patients} />
      {!isModalOpen && (
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageCount={Math.ceil(filteredPatients.length / perPage)}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          nextClassName="page-item"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
        />
      )}
    </div>
  );
}

export default Loans;
