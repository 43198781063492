import { Link, NavLink } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import '../shared/Form.css';
import { domain } from '../auth/domain';
import CustomLoader from '../components/CustomLoader';
import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import '../shared/Form.css';
import swal from 'sweetalert';
import Select from 'react-select';
import decodeJwtToken from '../auth/decodeJwtToken';
import userImg from '../assets/img/user.jpg';
import moment from 'moment';




function Invoices() {
    const accessToken = localStorage.getItem('accessToken');

    let user = decodeJwtToken(accessToken);
    const [invoices, setInvoices] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const [filteredInvoices, setFilteredInvoices] = useState(invoices);
    const [showSpinner, setShowSpinner] = useState(false);
    const [patients, setPatients] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // handle react page visibility based on open/clode of popup
    const handleToggleModal = (modalState) => {
        setIsModalOpen(modalState);
    };

    // Implement a function to handle the search functionality
    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        const filtered = invoices.filter(
            (bill) =>
                bill.first_name.toLowerCase().includes(query) ||
                bill.last_name.toLowerCase().includes(query)
            // staff.user_type.toLowerCase().includes(query)
        );
        setFilteredInvoices(filtered);
    };
    // Implement a function to handle the page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Use the currentPage and perPage state variables to calculate 
    // the slice of applications to be displayed on the current page


    // Function to format the date in a human-readable way
  const formatDate = (date) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const billDate = moment(date);

    if (billDate.isSame(today, 'd')) {
      return 'TODAY';
    } else if (billDate.isSame(yesterday, 'd')) {
      return 'YESTERDAY';
    } else {
      return billDate.format('MMM D, YYYY');
    }
  };

    const offset = currentPage * perPage;
    const paginatedInvoices = filteredInvoices.slice(
        offset,
        offset + perPage
    );



    // Fetch Current patients
    const fetchInvoices = () => {
        setShowSpinner(true);

        fetch(domain + "api/invoices/", {
            method: 'GET',
            headers: {
                'Authorization': 'JWT ' + accessToken,
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setInvoices(data);
                setFilteredInvoices(data);
                setShowSpinner(false);
            })
            .catch((error) => console.error(error))
            .finally(() => setShowSpinner(false));
    };

    useEffect(() => {
        setShowSpinner(true);
        fetchInvoices();
        // Fetch patients
        fetch(domain + "api/patients/", {
            method: 'GET',
            headers: {
                'Authorization': 'JWT ' + accessToken,
            }
        })
            .then(response => response.json())
            .then(data => {
                setPatients(data);

            })
            .catch(error => console.error(error))
            .finally(() => { setShowSpinner(false) });


        // Fetch service categories
        fetch(domain + "api/services-categories/", {
            method: 'GET',
            headers: {
                'Authorization': 'JWT ' + accessToken,
            }
        })
            .then(response => response.json())
            .then(data => {
                setCategories(data);

            })
            .catch(error => console.error(error))
            .finally(() => { setShowSpinner(false) });


    }, []);

    const markAsPaid = async (invoiceId)=> {
        
        const shouldMarkAsPaid = await swal({
            title: "Are you sure?",
            text: "Once marked as paid, you cannot undo this action!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        });
    
        if (shouldMarkAsPaid) {
            const response = await fetch(domain + 'api/invoices/' + invoiceId + '/', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT ' + accessToken
                },
                body: JSON.stringify({
                    'status': 'PAID'
                })
            });
    
            if (response.ok) {
                swal({ icon: 'success', title: 'Invoice marked as paid' });

                // store paid bill at payments
                const response2 = await fetch(domain + 'api/payments/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'JWT ' + accessToken
                    },
                    body: JSON.stringify({
                        "payment_method": 'insurance',
                        "object_id": invoiceId,
                        "content_type": 48
                    })
                });
                if(response2.ok)
                {
                    swal({ icon: 'success', title: 'Invoice stored in Payments' });   
                }
                else {
                    swal({ icon: 'error', title: 'Error occurred on saving Invoice in Payment' });
                }

            } else {
                swal({ icon: 'error', title: 'Error occurred' });
            }
        } else {
            // User chose not to mark the bill as paid
            swal({ icon: 'info', title: 'Operation canceled' });
        }
}


    // Function to get patient details based on Bill id
    const getPatient = (patientId) => {
        const patient = patients.find(patient => patient.id === patientId);
        return patient ? patient.first_name +''+patient.last_name : '';
    };

    // Function to get serviice category name based on category id
    const getCategory = (categoryId) => {
        const category = categories.find(category => category.id === categoryId);
        return category ? category.name : '';
    };

    return (
        <div className="row">
            <h3>Invoices</h3>
           
            <input
                style={{ borderRadius: '10px' }}
                className='form-control'
                type="text"
                placeholder="Search..."

                onChange={handleSearch}
            />
            <table id='app' className='datatable table table-striped'>

                <thead>
                    <tr>
                        <th>#</th>
                        <th>Invoice Number</th>
                        <th>Patient</th>
                        <th>Category</th>
                        <th>Created Date</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {(() => {
                        try {
                            return paginatedInvoices.map((invoice, index) =>
                                <tr key={invoice.id}>
                                      
                                    <td>{index + 1}</td>
                                    <td>Inv{invoice.id}</td>
                                    <td>{getPatient(invoice.patient)} </td>
                                    <td>{getCategory(invoice.category)}</td>
                                    
                                    <td>
                                        
                                        {formatDate(invoice.created_date) ==='TODAY'?<span class="custom-badge status-blue">TODAY</span>:
                                        formatDate(invoice.created_date) ==='YESTERDAY'?<span class="custom-badge status-orange">YESTERDAY</span>:
                                        <span class="custom-badge">{formatDate(invoice.created_date)}</span>}
                                    </td>
                                    <td>{invoice.amount}/=Tsh</td>
                                    
                                    <td>
                                        {invoice.status ==='PAID'?<span class="custom-badge status-green">{invoice.status}</span>:
                                        <span class="custom-badge status-red">{invoice.status}</span>}
                                        </td>


                                    <td className="text-right">        
                                       
                                    <button title={'Mark as paid'} disabled={invoice.status === 'PAID'} className={'btn btn-outline-success'} onClick={()=>markAsPaid(invoice.id)} 
            ><i
                                                            className={"fa fa-check m-r-5"}>
                                                                
                                                            </i>
                                                             </button>
                               
                                    </td>
                                </tr>
                            )
                        } catch (error) {
                            console.log(error);
                            return <tr><td>Network Problem.Please check your Internet</td></tr>;
                        }
                    })()
                    }


                </tbody>
            </table>

            {!isModalOpen && (
                <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageCount={Math.ceil(filteredInvoices.length / perPage)}
                    onPageChange={handlePageChange}
                    containerClassName="pagination"
                    activeClassName="active"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                />
            )}

            {/* {showSpinner && <CustomLoader />} */}
        </div>



    );
}

export default Invoices;
