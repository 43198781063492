import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  Typography,
  Container,
  Card,
  CardContent,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import swal from "sweetalert";
import { domain } from "../auth/domain";
import OrciBanner from "../components/OrciBanner";
import decodeJwtToken from "../auth/decodeJwtToken";

function AccountActivation() {
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);
  const userId = me?.user_id;
  const location = useLocation();
  const navigate = useNavigate();
  const activationCode = location.pathname.split("/").pop();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setPasswordsMatch(event.target.value === password);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordsMatch(event.target.value === confirmPassword);
    checkPasswordStrength(event.target.value);
  };

  const checkPasswordStrength = (password) => {
    let strength = "";
    if (
      password.length >= 8 &&
      /\d/.test(password) &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password) &&
      /[\W_]/.test(password)
    ) {
      strength = "strong";
    } else if (
      password.length >= 8 &&
      /\d/.test(password) &&
      /[a-zA-Z]/.test(password)
    ) {
      strength = "medium";
    } else {
      strength = "weak";
    }
    setPasswordStrength(strength);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await fetch(`${domain}auth/users/${userId}/`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + accessToken,
      },
      body: JSON.stringify({
        password,
      }),
    });

    if (response.ok) {
      swal({
        title: "Success",
        text: "Password reset successful.",
        icon: "success",
        buttons: false,
        timer: 1300,
      });
      navigate("/dashboard");
      setPassword("");
      setConfirmPassword("");
    } else {
      swal({
        text: "Account activation Failed",
        title: "Error",
        icon: "error",
        buttons: false,
        timer: 1300,
      });
      navigate("/");
    }
  };

  return (
    <Container maxWidth="sm">
      <Card
        sx={{
          mt: 25,
          boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)",
          borderRadius: "10px",
        }}
      >
        <CardContent>
          <OrciBanner />
          <Typography variant="h5" component="h6" gutterBottom>
            Change password to continue using the system
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel htmlFor="password">New Password</InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
              <Typography
                variant="body2"
                color={
                  passwordStrength === "strong" ? "success.main" : "error.main"
                }
              >
                {passwordStrength}
              </Typography>
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel htmlFor="confirm-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="confirm-password"
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
              <Typography
                variant="body2"
                color={passwordsMatch ? "success.main" : "error.main"}
              >
                {passwordsMatch ? "Passwords match" : "Password must match"}
              </Typography>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              type="submit"
              disabled={passwordStrength === "weak" || !passwordsMatch}
            >
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
}

export default AccountActivation;
