import moment from "moment";

export let brandName = "BFL";

export const formatDateN = (date) => {
  const billDate = moment(date);
  const formattedDate = billDate.format("MMM D, yyyy");

  return formattedDate;
};
