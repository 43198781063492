import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./Popup.css";
import { domain } from "../auth/domain";
import swal from "sweetalert";
import confirmImage from "../assets/img/sent.png";
import { event, get, param } from "jquery";
import decodeJwtToken from "../auth/decodeJwtToken";
import { Tabs, Tab, Form } from "react-bootstrap";
import CustomLoader from "./CustomLoader";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Autocomplete, LinearProgress, TextField } from "@mui/material";
import formatCurrency from "../shared/formatCurrency";
// import { Select as ReactSelect } from "react-select";
// import Select from "react-select";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PurchaseDialog({
  btnName,
  btnClass,
  iconName,
  formTitle,
  userId,
  onToggleModal,
  onRefreshTable,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    onToggleModal && onToggleModal(true);
    handleEditMode();
  };

  const handleClose = () => {
    setOpen(false);
    onToggleModal(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(false);
  const [returnSale, setReturnSale] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);

  const [cities, setCities] = useState([]);

  const [showSpinner, setShowSpinner] = useState(false);
  const [patientCityId, setSelectedPatientCity] = useState("");
  const [patientInsuranceId, setSelectedPatientInsurance] = useState(0);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [category, setCategory] = useState("");
  const [lowStockThreshold, setLowStockThreshold] = useState("");
  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [purchaseDate, setPurchaseDate] = useState(0);
  const [minDate, setMinDate] = useState(0);
  // use this for update insurance data and not insurance provider data
  const [insuranceId, setInsuranceId] = useState("");

  const [firstVisit, setFirstVisit] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const [doctorId, setSelectedDoctor] = useState("");
  const [doctorFee, setDoctorFee] = useState("");
  const [doctorFees, setDoctorFees] = useState([]);

  const [activeTab, setActiveTab] = useState(0);
  const [products, setProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [supplierId, setSelectedSupplier] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [invoiceItems, setInvoiceItems] = useState([
    {
      id: 1,
      product_id: "",
      unit_price: "",
      quantity_purchased: "",
      location: 1,
    },
  ]);
  const handleEditMode = () => {
    if (btnName === "ADD" || btnName === "details") {
      fetch(domain + "api/suppliers/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setSuppliers(data);
        });
      // fetch current Product
      if (btnName === "details") {
        fetch(domain + "api/purchases-items/" + userId + "/", {
          method: "GET",
          headers: {
            Authorization: "JWT " + accessToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setInvoiceItems(data["purchase"]);
            setSelectedSupplier(data?.supplier.id);
            setPurchaseDate(data?.purchase[0]?.purchase_date);
            setInvoiceTotal(data?.purchase[0]?.total_amount);
          });
      }
      fetch(domain + "api/products/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setProducts(data);
        });
    }
  };
  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const handleReturn = (event) => {
    setReturnSale(event.target.checked);
  };

  const handleSupplierChange = (event, newValue) => {
    const selectedSupplierId = newValue ? newValue.id : null;

    setSelectedSupplier(selectedSupplierId);
  };

  const addInvoiceItem = () => {
    const highestId = Math.max(...invoiceItems.map((item) => item.id));
    setInvoiceItems([
      ...invoiceItems,
      {
        id: highestId + 1,
        product_id: "",
        unit_price: "",
        quantity_purchased: "",
        location: 1,
      },
    ]);
  };

  const removeInvoiceItem = (id) => {
    setInvoiceItems(invoiceItems.filter((item) => item.id !== id));
  };

  const handleViewReturn = async (saleId) => {
    setShowSpinner(true);
    let saleR = [];
    await fetch(domain + "api/purchases-return/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        saleR = data.find((sale) => sale.purchase === saleId);
        setShowSpinner(false);
      });
    swal({
      icon: "info",

      text:
        "Date returned: " +
        saleR?.return_date +
        "\n" +
        " Quantity returned: " +
        saleR?.quantity_returned +
        "\n" +
        "Cost: " +
        formatCurrency(saleR?.total_amount) +
        "\n" +
        "Reason: " +
        saleR?.return_reason,
    });
  };
  const handleInputChange = (id, field, value) => {
    // alert(id + " " + field + " " + value);
    if (field === "unit_price" || "quantity_purchased") {
      const newTotal = invoiceItems.reduce((acc, item) => {
        const itemTotal = item.unit_price * item.quantity_purchased;
        return acc + itemTotal;
      }, 0);
      setInvoiceTotal(newTotal);
    }

    if (field === "quantity_returned") {
      setInvoiceItems(
        invoiceItems.map((item) =>
          item.id === id
            ? {
                ...item,
                [field]: value,
                ["purchase"]: item?.id,
              }
            : item
        )
      );
    } else {
      setInvoiceItems(
        invoiceItems.map((item) =>
          item.id === id ? { ...item, [field]: value } : item
        )
      );
    }
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    onToggleModal(!isOpen);
  };

  const handleNext = () => {
    if (activeTab === 0) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    } else if (activeTab === 1) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    }

    setActiveTab(activeTab + 1);
  };

  const formatDate2 = (date) => {
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const calculateTotal = () => {
      const total = invoiceItems.reduce((acc, item) => {
        return acc + item.unit_price * item.quantity_purchased;
      }, 0);
      setInvoiceTotal(total);
    };
    calculateTotal();

    // Get today's date
    const today = new Date();

    // Calculate tomorrow's date
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate());

    // Set the minimum selectable date
    setMinDate(formatDate2(tomorrow)); // Format date if needed
  }, [invoiceItems]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (btnName === "ADD") {
      const response = await fetch(domain + "api/purchases-items/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + accessToken,
        },
        body: JSON.stringify({
          purchase: [...invoiceItems],
          supplier_id: supplierId,
          purchase_date: purchaseDate,
        }),
      });

      if (response.ok) {
        swal({ title: "Purchase added", icon: "success" });
        setInvoiceItems([
          {
            id: 1,
            product_id: "",
            unit_price: "",
            quantity_purchased: "",
            location: 1,
          },
        ]);
        setSelectedSupplier("");
        handleClose();
        onRefreshTable(Math.random());
      } else {
        swal({ title: "Error occured", icon: "error" });
      }
    }

    if (returnSale) {
      const updatedItems = invoiceItems.filter(
        (item) =>
          item.quantity_returned !== undefined && !isNaN(item.quantity_returned)
      );

      const response = await fetch(domain + "api/purchases-return/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + accessToken,
        },
        body: JSON.stringify([...updatedItems]),
      });

      if (response.ok) {
        swal({
          title: "Purchase successful returned",
          icon: "success",
        });
        handleClose();
        onRefreshTable(Math.random());
      } else {
        // const body = await response.json();
        swal({
          title: "Error occured",
          icon: "error",
        });
      }
    }

    if (btnName === "EDIT") {
      const response = await fetch(domain + "api/products/" + userId + "/", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + accessToken,
        },
        body: JSON.stringify([...invoiceItems][0]),
      });

      if (response.ok) {
        swal({
          title: "Product successful updated",
          icon: "success",
        });
        handleClose();
      } else {
        swal({ title: "Error occured", icon: "error" });
      }
    }

    if (btnName === "DELETE") {
      togglePopup();

      const response = await fetch(domain + "api/products/" + userId + "/", {
        method: "DELETE",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      });

      if (response.ok) {
        swal({ title: "Product successful deleted", icon: "success" });
      } else {
        swal({ title: "Something went wrong", icon: "error" });
      }
    }

    if (btnName === "Dr.CONSULTATION") {
      if (doctorId === "") {
        swal({ icon: "info", title: "Please select Dr" });
        return;
      }

      if (paymentMethod === "cash") {
        const response = await fetch(domain + "api/bills/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            amount: doctorFee,
            patient: userId,
            category: 1,
          }),
        });

        if (response.ok) {
          swal({ title: "Patient successful sent to Dr", icon: "success" });
        } else {
          swal({
            title: "Error occured",
            text: "Please try again or Consult System admin",
            icon: "error",
          });
        }
      }

      if (paymentMethod === "insurance") {
        const response = await fetch(domain + "api/invoices/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            amount: doctorFee,
            patient: userId,
            category: 1,
          }),
        });

        if (response.ok) {
          swal({ title: "Patient successful sent to Dr", icon: "success" });
        } else {
          swal({
            title: "Error occured",
            text: "Please try again or Consult System admin",
            icon: "error",
          });
        }
      }
    }
  };

  // Function to get city name based on city id
  const getLocation = (cityId) => {
    const city = cities.find((city) => city.id === cityId);
    return city ? city.name : "";
  };

  const getFee = (doctorId) => {
    // Check if doctorFees is available and not empty
    if (doctorFees && doctorFees.length > 0) {
      const fee = doctorFees.find((fee) => fee.doctor == doctorId);
      return fee ? fee.consultation_fee : "";
    }
    return ""; // Return empty string if doctorFees is not yet available or empty
  };

  // Handler for doctor selection
  const handleDoctorChange = (event) => {
    const selectedDoctorId = event.target.value;

    setSelectedDoctor(selectedDoctorId);
    setDoctorFee(getFee(selectedDoctorId));
  };

  return (
    <React.Fragment>
      <button
        title={btnName}
        className={"btn btn-outline-" + btnClass}
        onClick={handleClickOpen}
      >
        <i className={"fa fa-" + iconName + " m-r-5"}></i>
        {btnName}
      </button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {btnName === "DELETE" || <DialogTitle>{formTitle}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {showSpinner && <LinearProgress />}
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            {btnName === "DELETE" || (
              <>
                <div class="table-responsive">
                  <table class="table table-hover table-white">
                    <thead>
                      <tr>
                        <th style={{ width: "20px" }}>Product</th>
                        <th>Unit price </th>
                        <th>Quantity</th>

                        {/* <th>
                          {btnName === "details" && (
                            <FormControlLabel
                              sx={{ mt: 1 }}
                              control={
                                <Switch
                                  checked={returnSale}
                                  onChange={handleReturn}
                                />
                              }
                              label="Return"
                            />
                          )}
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceItems.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <Autocomplete
                              style={{ width: 300, fontSize: 16 }}
                              value={
                                products.find(
                                  (product) =>
                                    product.id ===
                                    (btnName === "ADD"
                                      ? item.product_id
                                      : item.product?.id)
                                ) || null
                              }
                              onChange={(event, newValue) => {
                                handleInputChange(
                                  item.id,
                                  "product_id",
                                  newValue ? newValue.id : null
                                );
                              }}
                              options={products}
                              getOptionLabel={(option) =>
                                option.name +
                                " - stocks " +
                                option.stock_quantity
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select product"
                                  margin="normal"
                                />
                              )}
                            />
                          </td>
                          <td>
                            <TextField
                              type="text"
                              value={
                                btnName === "ADD"
                                  ? item.unit_price
                                  : parseFloat(
                                      invoiceTotal /
                                        (item.quantity_purchased /
                                          item.product?.bulk_quantity)
                                    ).toFixed(0)
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "unit_price",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <TextField
                              type="text"
                              value={
                                btnName === "ADD"
                                  ? item.quantity_purchased
                                  : item.quantity_purchased /
                                    item.product?.bulk_quantity
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  item.id,
                                  "quantity_purchased",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          {btnName === "ADD" && (
                            <td>
                              <a
                                onClick={() => {
                                  removeInvoiceItem(item.id);
                                }}
                                class="text-danger font-18"
                                title="Remove"
                              >
                                <i class="fa fa-trash-o"></i>
                              </a>
                            </td>
                          )}
                          {/* {btnName === "details" &&
                          returnSale &&
                          item?.status == "RETURNED" ? (
                            <td>
                              <a
                                className="btn m-1 float-right btn-white"
                                onClick={() => handleViewReturn(item.id)}
                              >
                                <i className="fa fa-refresh"></i>
                              </a>
                            </td>
                          ) : (
                            btnName === "details" &&
                            returnSale &&
                            item?.status != "RETURNED" && (
                              <td>
                                <input
                                  placeholder="Enter Quantity Returned"
                                  type="number"
                                  value={item?.quantity_returned}
                                  onMouseLeave={() => {
                                    if (
                                      item.quantity_returned >
                                      item.quantity_purchased
                                    ) {
                                      alert(
                                        "Return must be less or Equal to quantity sold"
                                      );
                                      handleInputChange(
                                        item.id,
                                        "quantity_returned",
                                        ""
                                      );
                                    }
                                  }}
                                  onChange={(e) =>
                                    handleInputChange(
                                      item.id,
                                      "quantity_returned",
                                      e.target.value
                                    )
                                  }
                                />

                                <input
                                  placeholder="Enter Return Reason"
                                  type="text"
                                  value={item?.return_reason}
                                  onMouseLeave={() => {
                                    if (item.return_reason === "") {
                                      alert("Enter reason for return ");
                                      handleInputChange(
                                        item.id,
                                        "return_reason",
                                        ""
                                      );
                                    }
                                  }}
                                  onChange={(e) =>
                                    handleInputChange(
                                      item.id,
                                      "return_reason",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                            )
                          )} */}
                        </tr>
                      ))}
                      <tr>
                        <td>
                          <Autocomplete
                            style={{ width: 300, fontSize: 16 }}
                            value={
                              suppliers.find(
                                (product) => product.id === supplierId
                              ) || null
                            }
                            onChange={handleSupplierChange}
                            options={suppliers}
                            getOptionLabel={(option) => option.full_name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="supplier name"
                                margin="normal"
                              />
                            )}
                          />
                        </td>
                        <td>
                          <label htmlFor="">Date</label>
                          <br />
                          <input
                            max={minDate}
                            required
                            fullWidth
                            type="date"
                            value={purchaseDate}
                            onChange={(event) =>
                              setPurchaseDate(event.target.value)
                            }
                          />
                        </td>
                        <td style={{ color: "green", fontSize: "30px" }}>
                          TOTAL: {formatCurrency(invoiceTotal)}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>
                          {/* <FormControl sx={{ mt: 2, minWidth: 120 }}>
                            <InputLabel htmlFor="max-width">
                              maxWidth
                            </InputLabel>
                            <Select
                              autoFocus
                              value={maxWidth}
                              onChange={handleMaxWidthChange}
                              label="maxWidth"
                              inputProps={{
                                name: "max-width",
                                id: "max-width",
                              }}
                            >
                              <MenuItem value={false}>false</MenuItem>
                              <MenuItem value="xs">xs</MenuItem>
                              <MenuItem value="sm">sm</MenuItem>
                              <MenuItem value="md">md</MenuItem>
                              <MenuItem value="lg">lg</MenuItem>
                              <MenuItem value="xl">xl</MenuItem>
                            </Select>
                          </FormControl> */}
                          {/* <FormControlLabel
                            sx={{ mt: 1 }}
                            control={
                              <Switch
                                checked={fullWidth}
                                onChange={handleFullWidthChange}
                              />
                            }
                            label="Full width"
                          /> */}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        {btnName === "ADD" && (
                          <td colSpan="5">
                            <a
                              class="text-success font-18"
                              title="Add"
                              onClick={addInvoiceItem}
                            >
                              <i class="fa fa-plus"></i>
                            </a>
                          </td>
                        )}
                      </tr>
                    </tfoot>
                  </table>
                </div>
                {/* <div class="table-responsive">
                  <table class="table table-hover table-white">
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td
                          style={{
                            textAlign: "right",
                            paddingRight: "30px",
                          }}
                        >
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
              </>
            )}
            {btnName === "DELETE" && (
              <div class="text-center">
                <img src={confirmImage} alt="" width="50" height="46" />
                <h3>Are you sure want to Delete this product ?</h3>
                <p> This will even delete the associated Sales and Purchases</p>
                <div class="m-t-20"> </div>
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-white m-1 float-right"
            onClick={handleClose}
          >
            CLOSE
          </button>
          {btnName === "DELETE" && (
            <button
              type="submit"
              className="btn m-1 float-right btn-white"
              onClick={handleSubmit}
            >
              DELETE
            </button>
          )}
          {returnSale && (
            <button
              type="submit"
              className="btn m-1 float-right btn-primary"
              onClick={handleSubmit}
            >
              RETURN
            </button>
          )}
          {btnName === "ADD" && (
            <button
              disabled={supplierId === "" || purchaseDate == "" ? true : false}
              type="submit"
              className="btn m-1 float-right btn-primary"
              onClick={handleSubmit}
            >
              SUBMIT
            </button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
