import {
  Link,
  NavLink,
  Location,
  useLocation,
  useParams,
} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactPaginate from "react-paginate";
import "../shared/Form.css";
import { domain } from "../auth/domain";
import CustomLoader from "../components/CustomLoader";
import Popup from "../components/CustomerStatementsDialog";
import PopupD from "../components/CustomerDialog";
import React, { useState, useEffect, useRef, Suspense } from "react";
// import { Tabs, Tab, Form } from "react-bootstrap";
// import "animate.css";
import PropTypes from "prop-types";
// import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
} from "@mui/material";
import "../shared/Form.css";
import swal from "sweetalert";
import Select from "react-select";
import decodeJwtToken from "../auth/decodeJwtToken";
import userImg from "../assets/img/user.jpg";
import usersImg from "../assets/img/logo-Photoroom.png";
import NoRecords from "../shared/NoRecords";
import { LinearProgress } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import monthNameToNumber from "../shared/monthNameToNumber";
// import timeDifference from "../shared/timeDifference";
import formatCurrency from "../shared/formatCurrency";
// import claimedDaysByStatus from "../shared/claimedDaysByStatus";
import formatRoleName from "../shared/formatRoleName";
// import canBeDoubleClaimed from "../shared/canBeDoubleClaimed";
// import getDayOfWeek from "../shared/getDayOfWeek";
// import isWeekend from "../shared/isWeekend";
// import isHoliday from "../shared/isHoliday";
// import CustomPagination from "../components/customPagination2";
// import MuiTable from "../components/MuiTable";
import Alert from "@mui/material/Alert";
import { formatDate } from "@canvasjs/charts";
// import { useUserActiveRole } from "../contexts/UserActiveRoleContext";
import exportToPDF from "../components/statementHintToPDF";

function CustomerStatements() {
  const accessToken = localStorage.getItem("accessToken");
  let user = decodeJwtToken(accessToken);
  const [patients, setPatients] = useState([]);
  // const [currentPage, setCurrentPage] = useState(0);
  // const { userActiveRole } = useUserActiveRole();
  const [showSpinner, setShowSpinner] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState("");
  const [attendances, setAttendances] = useState([]);
  const [repayments, setRepayments] = useState([]);
  const [claimItems, setClaimItems] = useState([]);
  const location = useLocation();
  const { isMultipleClaimed } = location.state || {}; // Get the passed state
  const claimId = location.pathname.split("/").pop();
  const theme = useTheme();
  const [bulkClaim, setBulkClaim] = useState(null);
  const [bulkClaimMode, setBulkClaimMode] = useState(null);
  const [totalCost, setTotalCost] = useState(0);
  const [doubleClaimedDates, setDoubleClaimedDates] = useState([]);
  const isHOD = false;
  const isED = false;
  const isHR = false;
  const isDFP = false;
  const isSUPERVISOR = true;
  const [noCreatedStatusItem, setNoCreatedStatusItems] = useState(false);
  const [isModificationMode, setIsModificationMode] = useState(false);
  const [printStatement, setPrintStatement] = useState(false);
  const [attendanceAndClaimeItemsTabs, setAttendanceAndClaimeItemsTabs] =
    useState([{ label: "Loans" }]);
  const [currentPageAttendace, setCurrentPageAttendace] = useState(0);
  const [perPageAttendace, setPerPageAttendace] = useState(10);

  const [attendancesWithRosterInfo, setAttendancesWithRosterInfo] = useState(
    []
  );
  const [profileImg, setProfileImg] = useState(userImg);
  const [value, setValue] = React.useState(
    location.pathname.includes("section")
      ? 2
      : location.pathname.includes("department")
      ? 2
      : location.pathname.includes("approvals")
      ? 1
      : 0
  );
  // update index of tabs conditionally
  const visibleTabs = [
    !location.pathname.includes("approvals"), // First tab visibility
    !(
      location.pathname.includes("section") ||
      location.pathname.includes("department")
    ), // Second tab visibility
  ];

  const adjustedValue =
    visibleTabs.slice(0, value + 1).filter(Boolean).length - 1;

  useEffect(() => {
    if (!visibleTabs[value]) {
      setValue(adjustedValue >= 0 ? adjustedValue : 0);
    }
  }, [location.pathname]);

  // end
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allRowIds = bulkClaim?.claims.map((item) => item.id);
      setSelectedRows(allRowIds);
      // alert(allRowIds);
    } else {
      setSelectedRows([]);
    }
  };

  const noCreatedStatus = (claimItems) => {
    return claimItems.every((item) => item.claimItemStatus !== "CREATED");
  };

  const handleRowSelect = (event, id) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, id]);
      // alert(selectedRows);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((rowId) => rowId !== id)
      );
    }
  };
  // Implement a function to handle the page change
  // const handlePageChange = ({ selected }) => {
  //   setCurrentPage(selected);
  // };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // pagination for attendances
  // const handlePageChangeAttendace = ({ selected }) => {
  // setCurrentPageAttendace(selected);
  // };

  // const handleChangeRowsPerPageAttendace = (event) => {
  //   setPerPageAttendace(parseInt(event.target.value, 10));
  //   setCurrentPageAttendace(0);
  // };

  // const offsetAttendace = currentPageAttendace * perPageAttendace;
  // const paginatedAttendace = attendances.slice(
  // offsetAttendace,
  // offsetAttendace + perPageAttendace
  // );

  const columns = [
    { id: "name", label: "Name" },
    { id: "age", label: "Age" },
    { id: "email", label: "Email" },
  ];

  const rows = [
    { id: 1, name: "John Doe", age: 28, email: "john@example.com" },
    { id: 2, name: "Jane Doe", age: 25, email: "jane@example.com" },
    { id: 3, name: "Sam Smith", age: 22, email: "sam@example.com" },
    // Add more rows as needed...
  ];
  // const rowsPerPageOptions = [5, 10, 25];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  // const paginatedRows = attendances.slice(
  //   page * rowsPerPage,
  //   page * rowsPerPage + rowsPerPage
  // );
  let paginatedRows = [];

  try {
    paginatedRows = patients.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  } catch (error) {
    console.error("Error slicing attendance array:", error);

    // Optionally, set a fallback value or show an error message
    paginatedRows = []; // Fallback to an empty array or handle the error gracefully
  }

  // end attendances

  // pagination for claimItems
  const [pageClaim, setPageClaim] = useState(0);
  const [rowsPerPageClaim, setRowsPerPageClaim] = useState(10);

  const handleChangePageClaim = (event, newPage) => {
    setPageClaim(newPage);
  };

  const handleChangeRowsPerPageClaim = (event) => {
    setRowsPerPageClaim(parseInt(event.target.value, 10));
    setPageClaim(0); // Reset to the first page
  };

  const paginatedRowsClaim = claimItems.slice(
    pageClaim * rowsPerPageClaim,
    pageClaim * rowsPerPageClaim + rowsPerPageClaim
  );
  // end

  const handleChangeIndex = (index) => {
    if (location.pathname.includes("approvals")) {
      setValue(1);
    } else if (
      location.pathname.includes("section") ||
      location.pathname.includes("department")
    ) {
      setValue(2);
    } else {
      setValue(index);
    }
  };

  const handleClaimedItemsDate = (tarehe) => {};

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const navigate = useNavigate();

  // handle react page visibility based on open/clode of popup
  const handleToggleModal = (modalState) => {
    setIsModalOpen(modalState);
  };

  const handleTableRefresh = (info) => {
    setRefreshTable(info);
  };
  // Implement a function to handle the search functionality
  const handleUserActivation = (userId, isActive) => {
    swal({
      text: isActive
        ? "Are you sure you want to ACTIVATE this user ?"
        : "Are you sure you want to DEACTIVATE this user ?",
      icon: "warning",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (result) => {
      if (result) {
        setShowSpinner(true);
        const response = await fetch("/api/v1/users/" + userId, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
          // body: JSON.stringify(modifiedUserData),
        });

        if (response.ok) {
          setShowSpinner(false);
          setRefreshTable(Math.random());
          swal({
            text: isActive ? "User activated" : "User Deactivated",
            // text: data.message,
            icon: "success",
          });
        } else {
          setShowSpinner(false);
          const data = response.json();
          swal({
            title: "Error",
            text: data?.data?.message,
            icon: "error",
          });
        }
      }
    });
  };

  // for claimed itemes
  // const notInRoster =  (date) =>  {
  //   // alert(`in ${date}`)
  //   const record =  attendances.find((record)=>record?.date == date)
  //   // alert(`out ${JSON.stringify(record?.existsInRoster)}`)
  //   return record?.existsInRoster
  // };

  const notInRoster = async (date) => {
    const record = attendances.filter((record) => record?.date === date);
    return record?.existsInRoster;
  };

  const [isNotInRoster, setIsNotInRoster] = useState({});

  const alreadyClaimed = (attendance) => {
    const claimed = claimItems.find((claim) => claim?.claimDate === attendance);
    return claimed ? true : false;
  };
  const getDoubleClaimedDates = (claims) => {
    const dateCount = claims.reduce((acc, claim) => {
      acc[claim.claimDate] = (acc[claim.claimDate] || 0) + 1;
      return acc;
    }, {});

    return Object.keys(dateCount).filter((date) => dateCount[date] > 1);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setPerPage(parseInt(event.target.value, 10));
  //   setCurrentPage(0);
  // };

  // const handleSubmit = async (event) => {
  //   event.preventDefault(); // Prevent form from submitting immediately

  //   // Show a loading spinner or message if needed here while checking the claims
  //   setShowSpinner(true);

  //   // Check claims not in the roster
  //   const countNotInRoster = await checkNotInRosterClaims();

  //   // Stop the spinner after the check
  //   setShowSpinner(false);

  //   // If claims are found that are not in the roster, show a warning and prevent submission
  //   if (countNotInRoster > 0) {
  //     await swal({
  //       title: "Attention",
  //       text: `${countNotInRoster} claims are not in the roster. Please review them before submitting.`,
  //       icon: "warning",
  //     });
  //     return; // Exit the function to prevent further submission
  //   }

  //   const action = event.target.textContent;
  //   try {
  //     const result = await swal({
  //       title: `Are you sure you want to ${action} this claim?`,
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: true,
  //     });

  //     if (result) {
  //       // Send PATCH request with only modified fields
  //       const requestBody =
  //         action === "SUBMIT"
  //           ? {
  //               action: user?.user?.roles?.includes("SUPERVISOR")
  //                 ? "SUPERVISOR_APPROVED"
  //                 : user?.user?.roles?.includes("HEAD_OF_DEPARTMENT")
  //                 ? "HEAD_OF_DEPARTMENT_APPROVED"
  //                 : user?.user?.roles?.includes("HR")
  //                 ? "HR_APPROVED"
  //                 : "SUBMITTED",
  //               remarks: "ABC",
  //             }
  //           : {
  //               action: user?.user?.roles?.includes("SUPERVISOR")
  //                 ? "CANCELLED"
  //                 : user?.user?.roles?.includes("HEAD_OF_DEPARTMENT")
  //                 ? "CANCELLED"
  //                 : "CANCELLED",
  //             };

  //       const patchResponse = await fetch("/api/v1/claims/actions/" + claimId, {
  //         method: "PATCH",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: "Bearer " + accessToken,
  //         },
  //         body: JSON.stringify(requestBody),
  //       });

  //       if (patchResponse.ok) {
  //         swal({
  //           title: "Successful",
  //           icon: "success",
  //         });
  //         setRefreshTable(Math.random());
  //       } else {
  //         const body = await patchResponse.json();
  //         swal({ text: body?.message, icon: "error" });
  //       }
  //     }
  //   } catch (error) {
  //     setShowSpinner(false);
  //     swal({
  //       text: error,
  //       icon: "error",
  //     });
  //   }
  // };

  // Function to turn on roster modification mode
  const handleSecondOnCall = async (event) => {
    const action = event.target.textContent;
    try {
      if (true) {
        const requestBody = {
          isSecondOnCall: !isModificationMode,
        };

        const patchResponse = await fetch("/api/v1/claims/" + claimId, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
          body: JSON.stringify(requestBody),
        });

        if (patchResponse.ok) {
          setIsModificationMode(!isModificationMode);
          setRefreshTable(Math.random());
        } else {
          const body = await patchResponse.json();
          swal({ text: body?.message, icon: "error" });
        }
      }
    } catch (error) {
      setShowSpinner(false);
      swal({
        text: error,
        icon: "error",
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form from submitting immediately

    // Show a loading spinner or message while checking the claims
    setShowSpinner(true);

    // Check claims not in the roster
    const countNotInRoster = await checkNotInRosterClaims();

    // Stop the spinner after the check
    setShowSpinner(false);

    // If claims are found that are not in the roster, show a popup requiring comments
    if (countNotInRoster > 0) {
      // Create a new textarea element manually for SweetAlert to use
      const textarea = document.createElement("textarea");
      textarea.placeholder = "Enter your comments here";
      textarea.rows = 5;
      textarea.cols = 40;

      // Show the SweetAlert with the textarea
      const result = await swal({
        title: "Attention",
        text: `${countNotInRoster} Claimed Item(s) are not in the roster. Please provide comments before proceeding.`,
        content: textarea, // Attach the textarea to SweetAlert
        buttons: {
          cancel: true, // Allow the user to cancel the process
          confirm: {
            text: "Proceed",
            value: true,
          },
        },
        dangerMode: true,
      });

      // Check if the result is valid (user did not cancel the popup)
      if (result === null || result === false) {
        return; // Exit if the user cancels
      }

      const comments = textarea.value.trim(); // Get the value from the textarea and trim whitespace

      // If no comments entered, alert the user and prevent submission
      if (!comments) {
        swal({
          text: "Please enter comments to proceed.",
          icon: "warning",
        });
        return; // Stop the submission process if comments are empty
      }

      // Proceed with submission, and include the comments in the request
      await submitClaim(event, comments); // Call a helper function to handle the rest of the submission process
    } else {
      // If there are no claims outside the roster, ask for confirmation before proceeding
      const action = event.target.textContent;
      const confirmationResult = await swal({
        title: `Are you sure you want to ${action} this claim?`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (confirmationResult) {
        // Proceed with submission without comments
        await submitClaim(event);
      }
    }
  };

  const handlePrintStatement = async () => {
    setShowSpinner(true);
    const response = await fetch(domain + "customers/payments/", {
      method: "POST",
      headers: {
        Authorization: "JWT " + accessToken,
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        customer: patients[0]?.customer?.id,
        outstanding_amount: 2000,
        amount: 2000,
        payment_type: "statement",
        paid: false,
      }),
    });
    if (response.ok) {
      setShowSpinner(false);
      swal({
        title: "succes",
        text: "Successful printed",
        icon: "success",
        timer: 1300,
        buttons: false,
      });
      exportToPDF(patients, 1234);
    } else {
      setShowSpinner(false);
      const body = await response.json();

      if (body) {
        const errors = body;
        for (const [field, messages] of Object.entries(errors)) {
          messages.forEach((message) => {
            swal({
              title: "Error",
              text: `${message}`,
              icon: "error",
              timer: 3000,
              buttons: false,
            });
          });
        }
      } else {
        setShowSpinner(false);
        swal({
          title: "Error",
          text: "An unexpected error occurred",
          icon: "error",
          timer: 2000,
          buttons: false,
        });
      }
    }
  };
  const submitClaim = async (event, comments = null) => {
    const action = event.target.textContent;

    try {
      const requestBody =
        action === "SUBMIT"
          ? {
              action: "SUBMITTED",
              remarks: comments || "No additional remarks", // Include the comments if provided
            }
          : {
              action: "CANCELLED",
              remarks: comments || "No additional remarks", // Include the comments if provided
            };

      const patchResponse = await fetch("/api/v1/claims/actions/" + claimId, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: JSON.stringify(requestBody),
      });

      if (patchResponse.ok) {
        swal({
          title: "Successful submitted",
          icon: "success",
          timer: 1300,
          buttons: false,
        });
        setRefreshTable(Math.random());
      } else {
        const body = await patchResponse.json();
        swal({ text: body?.message, icon: "error" });
      }
    } catch (error) {
      setShowSpinner(false);
      swal({
        text: error,
        icon: "error",
      });
    }
  };

  const fetchPatients = () => {
    let bulckCliamSecond = null;
    setShowSpinner(true);

    fetch(domain + "loans/loans/?customer=" + claimId, {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["detail"]) {
          swal({ icon: "info", text: data["detail"] });
          navigate("/dashboard");
          return; // Exit function if there's an error
        }

        setPatients(data);
        setIsModificationMode(data.data.isSecondOnCall);
        // alert(data.data.isSecondOnCall);
        bulckCliamSecond = data.data;
      })

      .catch((error) => {
        console.error(error);
        setShowSpinner(false);
      });
  };
  // calculateTotalCost(bulkClaim)
  const calculateTotalCost = () => {};

  const getButtonProps = (date, claimType) => {};

  useEffect(() => {
    // setIsModificationMode(bulkClaim?.is)
    const checkRosterStatus = async () => {
      const status = {};
      if (Array.isArray(attendances)) {
        for (let attendance of attendances) {
          const result = await notInRoster(attendance.date);
          status[attendance.date] = result;
        }
      }
      setIsNotInRoster(status);
    };
    checkRosterStatus();
  }, [attendances, refreshTable]);

  useEffect(() => {
    setShowSpinner(true);

    if (
      location.pathname.includes("department") ||
      location.pathname.includes("section")
    ) {
      const { state } = location;

      setBulkClaim(state.data);
      setBulkClaimMode(state.mode);
      setShowSpinner(false);
    }

    Number(claimId) && fetchPatients();
    if (location.pathname.includes("approvals")) {
      setValue(1);
      setShowSpinner(false);
    }

    // // / Ensure all rows are selected when bulkClaim is loaded or updated
    // if (bulkClaim?.claims) {
    //   const allRowIds = bulkClaim.claims.map((claim) => claim.id);
    //   setSelectedRows(allRowIds);
    // }

    setTotalCost(calculateTotalCost());
  }, [refreshTable, bulkClaim, selectedRows, claimId]);

  const checkNotInRosterClaims = async () => {
    // That fun enable entering of remarks before submit claim if claimed items are outside of roster
    let count = 0;

    for (let claim of claimItems) {
      const isInRoster = await notInRoster(claim.claimDate);
      if (!isInRoster) {
        count += 1;
      }
    }

    return count;
  };

  // Function to get insurance provider based on insurance id
  // const getInsurance = (insuranceId) => {
  //   const insurance = insurances.find(
  //     (insurance) => insurance.id === insuranceId
  //   );
  //   return insurance ? insurance.insurance_provider : "";
  // };

  // // Function to get city name based on city id
  // const getRole = (cityId) => {
  //   const city = cities.find((city) => city.id === cityId);
  //   return city ? city.name : "";
  // };

  return (
    <div className="row">
      <div className="col-sm-1">
        {/* <button
          className="btn btn-primary float-left"
          onClick={() => navigate(-1)}
          style={{ marginBottom: "30px" }}
        >
          <ArrowBackIcon />
        </button> */}
      </div>
      {showSpinner && <LinearProgress />}

      <h3>Customer Statements</h3>

      <div
        className="auth-form-light text-left py-5 px-4 px-sm-5 card"
        style={{
          boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)",
          borderRadius: "7px",
        }}
      >
        <div className="profile-img-wrap">
          <div className="profile-img">
            <a href="#">
              <img
                className="avatar"
                // style={{ backgroundColor: "grey" }}
                src={
                  patients[0]?.customer?.photo_url
                    ? patients[0]?.customer?.photo_url
                    : userImg
                }
                alt="user image"
              />
            </a>
          </div>
        </div>
        <div className="profile-basic">
          <div className="row">
            <div className="col-md-5">
              <div
                className={
                  location.pathname.includes("department") ||
                  location.pathname.includes("section") ||
                  "profile-info-left"
                }
              >
                <h3 className="user-name m-t-0 mb-0">
                  {claimId && patients[0]?.customer?.full_name}
                </h3>
                <small className="text-muted">
                  {claimId && patients[0]?.customer?.gender === "F"
                    ? "FEMALE"
                    : "MALE"}
                </small>
                <div className="text-muted">
                  {claimId && patients[0]?.customer?.phone_number}
                </div>

                <div className="staff-id">
                  {claimId && patients[0]?.customer?.bank_reference_number}
                </div>

                <div className="staff-msg">
                  <div style={{ display: "flex" }}>
                    <button
                      style={{ backgroundColor: "#800b33", color: "white" }}
                      onClick={handlePrintStatement}
                      className="btn mr-1"
                    >
                      PRINT
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {location.pathname.includes("department") ||
              location.pathname.includes("section") || (
                <div className="col-md-7">
                  <ul className="personal-info">
                    <li>
                      <span className="title">Branch:</span>
                      <span className="text">
                        {claimId && patients[0]?.customer?.branch?.name}
                      </span>
                    </li>
                    <li>
                      <span className="title">Retreive Date:</span>
                      <span className="text">{formatDate(new Date())}</span>
                    </li>
                    <li>
                      <span className="title">Retreived By:</span>
                      <span className="text">{user?.full_name}</span>
                    </li>

                    <li>
                      {location.pathname.includes("approvals") ||
                        ((patients?.claimStatus === "CREATED" ||
                          patients?.claimStatus === "SUPERVISOR_REJECTED" ||
                          patients?.claimStatus ===
                            "HEAD_OF_DEPARTMENT_REJECTED") &&
                          patients?.claimType === "ON_CALL" && (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isModificationMode}
                                  onChange={handleSecondOnCall}
                                  name="secondOnCall"
                                  color="primary"
                                />
                              }
                              label="2nd on call"
                            />
                          ))}
                    </li>
                  </ul>
                </div>
              )}
          </div>
        </div>
      </div>

      <div>
        {showSpinner && <LinearProgress />}

        <Tabs
          hidden={location.pathname.includes("approvals")}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {attendanceAndClaimeItemsTabs.map((tab, index) => {
            return (
              <Tab key={index + 1} label={tab.label} {...a11yProps(index)} />
            );
          })}
        </Tabs>

        <div
          className="card-body p-0"
          style={{
            boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)",
            borderRadius: "7px",
          }}
        >
          <SwipeableViews
            hidden={location.pathname.includes("approvals")}
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            {/* {!location.pathname.includes("approvals") && ( */}
            <>
              <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                hidden={location.pathname.includes("approvals")}
              >
                <table
                  id="app"
                  className="datatable table table-striped"
                  style={{ whiteSpace: "nowrap" }}
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Amount</th>
                      <th>Repayment Amount</th>
                      <th>Start</th>
                      <th>End</th>
                      <th>Returned</th>
                      <th>Outstanding</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(() => {
                      try {
                        return paginatedRows.map((patient, index) => (
                          <tr key={patient?.id}>
                            <td>{index + 1}</td>
                            <td>{formatCurrency(patient?.amount)}</td>
                            <td>{formatCurrency(patient?.repayment_amount)}</td>
                            <td>{patient?.start_date || "N/A"}</td>
                            <td>{patient?.end_date || "N/A"}</td>
                            <td>
                              {formatCurrency(patient?.total_amount_returned)}
                            </td>
                            <td>
                              {formatCurrency(patient?.total_amount_remain)}
                            </td>
                            <td className="text-right">
                              <div style={{ display: "flex" }}>
                                <Popup
                                  popupData={patient}
                                  // onToggleModal={handleToggleModal}
                                  iconName={"eye"}
                                  btnName="VIEW"
                                  btnClass={"primary"}
                                  formTitle={"Customer statements"}
                                  userId={patients?.user?.attendanceId}
                                  onRefreshTable={handleTableRefresh}
                                  isDisabled={
                                    !patient?.attended && isModificationMode
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        ));
                      } catch (error) {
                        // console.log(error);
                        return (
                          <tr>
                            <td colSpan="7" style={{ padding: "20px" }}>
                              <Alert severity="error">
                                Failed to fetch Customer's loans
                              </Alert>
                            </td>
                          </tr>
                        );
                      }
                    })()}
                  </tbody>
                </table>
                <NoRecords data={patients || []} />
                {!isModalOpen && (
                  <>
                    <div style={{ display: "flex" }}>
                      <TablePagination
                        component="div"
                        count={attendances ? attendances.length : 0}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                      {/* <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          pageCount={Math.ceil(
                            attendances.length / perPageAttendace
                          )}
                          onPageChange={handlePageChangeAttendace}
                          containerClassName="pagination"
                          activeClassName="active"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          nextClassName="page-item"
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                        /> */}
                    </div>
                  </>
                )}
                {/* <MuiTable rows={rows} columns={columns} /> */}
              </TabPanel>
            </>
          </SwipeableViews>
        </div>
      </div>
    </div>
  );
}
export default CustomerStatements;
