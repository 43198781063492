import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Upload from "@mui/icons-material/Upload";
import Slide from "@mui/material/Slide";
import "./Popup.css";
import { domain } from "../auth/domain";
import swal from "sweetalert";
import confirmImage from "../assets/img/sent.png";
import { event, get } from "jquery";
import decodeJwtToken from "../auth/decodeJwtToken";
import { Tabs, Tab, Form } from "react-bootstrap";
import CustomLoader from "./CustomLoader";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  formatPhoneNumber,
  formatNidaId,
  formatCureny3F,
  formatVoterId,
} from "../shared/formatter";
import {
  FormHelperText,
  InputAdornment,
  TextField,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  btnName,
  btnClass,
  iconName,
  formTitle,
  userId,
  onToggleModal,
  onRefreshTable,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    onToggleModal(true);
    handleEditMode();
  };

  const handleRefresh = (data) => {
    onRefreshTable(data);
  };

  const handleClose = () => {
    setOpen(false);
    onToggleModal(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);
  const navigate = useNavigate();
  const [cities, setCities] = useState([]);
  const [error, setError] = useState({
    id_type: "",
    id_number: "",
    full_name: "",
    email: "",
    phone_number: "",
    signature: "",
    photo: null,
    branch_id: "",
    groups: "",
  });
  const [staffData, setStaffData] = useState({
    id_type: "",
    id_number: "",
    full_name: "",
    email: "",
    phone_number: "",
    signature: "",
    photo: null,
    my_branch: null,
    groups: "",
    password: "bfl@2024",
  });

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [patientInsuranceId, setSelectedPatientInsurance] = useState(0);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [category, setCategory] = useState("");
  const [lowStockThreshold, setLowStockThreshold] = useState("");
  const [location, setLocation] = useState("");
  const [branches, setBranches] = useState(0);
  const [memberShipNumber, setMemberShipNumber] = useState(0);
  // use this for update insurance data and not insurance provider data
  const [insuranceId, setInsuranceId] = useState("");

  const [firstVisit, setFirstVisit] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [invoiceCategory, setInvoiceCategory] = useState("");
  const [showLabel, setShowLabel] = useState(false);

  const [nextOfKinFirstName, setNextOfKinFirstName] = useState("");
  const [nextOfKinLastName, setNextOfKinLastName] = useState("");
  const [nextOfKinMiddleName, setNextOfKinMiddleName] = useState("");

  const [nextOfKinGender, setNextOfKinGender] = useState("");
  const [nextOfKinPhone, setNextOfKinPhone] = useState("");
  const [productFieldSetting, setProductFieldSetting] = useState({});
  const [use_description, setUseDescription] = useState(false);
  const [use_expiry_date, setUseExpiryDate] = useState(false);
  const [use_batch_number, setUseBatchNumber] = useState(false);
  const [use_category, setUseCategory] = useState(false);
  const [doctorId, setSelectedDoctor] = useState("");
  const [doctorFee, setDoctorFee] = useState("");
  const [doctorFees, setDoctorFees] = useState([]);

  const [groups, setGroups] = useState([]);
  const [groupId, setSelectedGroupId] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("bfl@2024");
  const [email, setEmail] = useState("");
  const [username, setUserName] = useState("");

  const [activeTab, setActiveTab] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };
  // Toggle password visibility
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    // Check if the selected file is an image
    if (file && file.type.startsWith("image/")) {
      setStaffData({
        ...staffData,
        [name]: file,
      });
    } else {
      swal({
        title: "Error",
        text: "Please select valid image file.",
        timer: 1300,
        buttons: false,
        icon: "error",
      });
      setStaffData({
        ...staffData,
        [name]: null,
      });
    }

    setStaffData({
      ...staffData,
      [name]: files[0],
    });
  };

  const handleInputChange = (e) => {
    const { name, value: rawValue } = e.target;

    let formattedValue = rawValue;

    // Handle phone number formatting separately
    if (name === "phone_number") {
      formattedValue = formatPhoneNumber(rawValue);
      setStaffData({
        ...staffData,
        [name]: formattedValue,
      });
      return; // Exit the function early since phone_number change does not need further processing
    }

    // fullnames into uppercases changes
    if (name === "full_name") {
      setStaffData({
        ...staffData,
        full_name: rawValue.toUpperCase(),
      });
      return; // Exit the function early since id_type change does not need further processing
    }

    // Clear id_number if id_type changes
    if (name === "id_type") {
      setStaffData({
        ...staffData,
        id_type: rawValue,
        id_number: "", // Clear id_number field
      });
      return; // Exit the function early since id_type change does not need further processing
    }

    // Format id_number based on id_type
    if (name === "id_number") {
      formattedValue =
        staffData.id_type === "NIDA"
          ? formatNidaId(rawValue)
          : staffData.id_type === "VOTER_ID"
          ? formatVoterId(rawValue)
          : rawValue;
    }

    setStaffData({
      ...staffData,
      [name]: formattedValue,
    });
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    onToggleModal(!isOpen);
    handleEditMode();
  };
  const handleEditMode = () => {
    if (btnName === "Edit" || btnName === "ADD") {
      fetch(domain + "auth/users/" + userId + "/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const groupId = data.groups?.id;
          const branchId = data.my_branch?.id;
          setStaffData({
            ...data,
            groups: groupId, // Store the ID only
            photo: null,
            my_branch: branchId,
          });
        });
      // Fetch groups

      fetch(domain + "profiles/roles/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setGroups(data);
        })
        .catch((error) => console.error(error));

      fetch(domain + "branches/branches/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setBranches(data);
        })
        .catch((error) => console.error(error));
    }
  };
  const handleNext = () => {
    if (activeTab === 0) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    } else if (activeTab === 1) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    }

    setActiveTab(activeTab + 1);
  };

  useEffect(() => {
    // fetch current Product
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (btnName === "ADD") {
      const formData = new FormData();
      formData.append("password", "bfl@2024");

      // Populate formData, conditionally excluding `my_branch` if groups is 1 or 2
      for (const key in staffData) {
        if (key === "my_branch" && [1, 2].includes(staffData.groups)) {
          // Skip adding my_branch to formData for groups 1 and 2
          continue;
        }
        formData.append(key, staffData[key]);
      }

      // Perform validations
      let hasError = false;
      if (!staffData.full_name) {
        setError((prevError) => ({
          ...prevError,
          full_name: "Full name is required",
        }));
        hasError = true;
      }

      if (!staffData.photo) {
        setError((prevError) => ({
          ...prevError,
          photo: "Photo is required",
        }));
        hasError = true;
      }

      if (!staffData.email) {
        setError((prevError) => ({
          ...prevError,
          email: "Email is required",
        }));
        hasError = true;
      }

      if (!staffData.phone_number) {
        setError((prevError) => ({
          ...prevError,
          phone_number: "Phone number is required",
        }));
        hasError = true;
      }

      if (!staffData.groups) {
        setError((prevError) => ({
          ...prevError,
          groups: "Role is required",
        }));
        hasError = true;
      }

      if (![1, 2].includes(staffData.groups)) {
        // If groups is not 1 or 2, ensure my_branch is required
        if (!staffData.my_branch) {
          setError((prevError) => ({
            ...prevError,
            my_branch: "Branch is required",
          }));
          hasError = true;
        }
      }

      if (!staffData.id_type) {
        setError((prevError) => ({
          ...prevError,
          id_type: "ID type is required",
        }));
        hasError = true;
      }

      if (!staffData.id_number) {
        setError((prevError) => ({
          ...prevError,
          id_number: "ID Number is required",
        }));
        hasError = true;
      }

      if (hasError) return; // Prevent submission if there are errors

      // Clear errors before sending request
      setError({
        full_name: "",
        email: "",
        password: "",
        groupId: "",
      });

      const response = await fetch(domain + "auth/users/", {
        method: "POST",
        headers: {
          Authorization: "JWT " + accessToken,
        },
        body: formData,
      });

      if (response.ok) {
        swal({
          title: "Success",
          text: "User added",
          icon: "success",
          timer: 1500,
          buttons: false,
        });
        handleClose();
        handleRefresh(Math.random());
        setFirstName("");
        setLastName("");
        setUserName("");
        setEmail("");
        setPassword("");
        setSelectedGroupId("");
      } else {
        const body = await response.json();
        swal({
          title: "Error",
          text: "Error occurred",
          icon: "error",
          buttons: false,
          timer: 1300,
        });
      }
    }

    if (btnName === "Edit") {
      // check if fields are empty and display errors
      let hasError = false;
      if (!staffData.full_name) {
        setError((prevError) => ({
          ...prevError,
          full_name: "Full name is required",
        }));
        hasError = true;
      }

      if (!staffData.email) {
        setError((prevError) => ({
          ...prevError,
          email: "Email is required",
        }));
        hasError = true;
      }

      if (!staffData.phone_number) {
        setError((prevError) => ({
          ...prevError,
          phone_number: "Phone number is required",
        }));
        hasError = true;
      }
      if (!staffData.groups) {
        setError((prevError) => ({
          ...prevError,
          groups: "Role is required",
        }));
        hasError = true;
      }
      if (![1, 2].includes(staffData.groups)) {
        if (!staffData.my_branch) {
          setError((prevError) => ({
            ...prevError,
            my_branch: "Branch is required",
          }));
          hasError = true;
        }
      }
      if (!staffData.id_type) {
        setError((prevError) => ({
          ...prevError,
          id_type: "ID type is required",
        }));
        hasError = true;
      }
      if (!staffData.id_number) {
        setError((prevError) => ({
          ...prevError,
          id_number: "ID Number is required",
        }));
        hasError = true;
      }
      if (hasError) return; // Prevent submission if there are errors
      setError({
        full_name: "",
        email: "",
        password: "",
        groupId: "",
      }); // Clear errors
      const formData = new FormData();

      // Check if the key is not my_branch and photo before appending
      for (const key in staffData) {
        // Skip my_branch and photo initially, and skip null or empty values
        if (
          key !== "my_branch" &&
          key !== "photo" &&
          staffData[key] !== "" &&
          staffData[key] !== null
        ) {
          formData.append(key, staffData[key]);
        }
      }

      // Conditionally append my_branch if the user is not in Admin or General Manager group
      const isAdminOrGeneralManager = [1, 2].includes(staffData.groups); // Assuming 1 = Admin, 2 = General Manager
      if (
        !isAdminOrGeneralManager &&
        staffData.my_branch !== null &&
        staffData.my_branch !== ""
      ) {
        formData.append("my_branch_id", staffData.my_branch);
      }

      // Check if the key is for a photo and validate the image
      if (staffData.photo instanceof File) {
        const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
        if (validImageTypes.includes(staffData.photo.type)) {
          formData.append("photo", staffData.photo);
        } else {
          alert("Please upload a valid image file (JPEG, PNG, GIF).");
          return;
        }
      }

      // Proceed with form submission or handle any further logic

      const response = await fetch(domain + "auth/users/" + userId + "/", {
        method: "PATCH",
        headers: {
          Authorization: "JWT " + accessToken,
        },
        body: formData,
      });

      if (response.ok) {
        swal({
          title: "Success",
          text: "User updated",
          icon: "success",
          timer: 1500,
          buttons: false,
        });

        handleClose();

        handleRefresh(Math.random());
        setFirstName("");
        setLastName("");
        setUserName("");
        setEmail("");
        setPassword("");
        setSelectedGroupId("");
        // navigate("/users");
      } else {
        swal({
          title: "Error",
          text: "Error occured",
          icon: "error",
          timer: 1300,
          buttons: false,
        });
      }
    }

    if (btnName === "SETTING") {
      const response = await fetch(
        domain + "api/product-field-settings/" + 1 + "/",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            use_description,
            use_expiry_date,
            use_batch_number,
            use_category,
          }),
        }
      );

      if (response.ok) {
        swal({
          title: "Product settings successful updated",
          icon: "success",
        });
        handleClose();
        // onRefreshTable("update");
        handleRefresh(Math.random());
      } else {
        swal({ title: "Error occured", icon: "error" });
      }
    }

    if (btnName === "Delete") {
      if (!password) {
        setError((prev) => ({
          ...prev,
          password: "Password is required",
        }));
        return;
      }
      setError({
        full_name: "",

        email: "",
        password: "",
        groupId: "",
      }); // Clear errors
      const response = await fetch(domain + "auth/users/" + userId + "/", {
        method: "DELETE",
        headers: {
          Authorization: "JWT " + accessToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          current_password: password,
        }),
      });

      if (response.ok) {
        swal({
          title: "success",
          text: "User successful deleted",
          icon: "success",
          buttons: false,
          timer: 1300,
        });
        handleClose();
        // onRefreshTable("update");
        handleRefresh(Math.random());
      } else {
        const body = await response.json();
        let details = "";

        if (Array.isArray(body?.current_password)) {
          details = body.current_password[0];
        } else if (body?.detail) {
          details = body.detail;
        } else {
          details = "Failed to Delete user";
        }

        swal({
          title: "Error",
          text: details,
          icon: "error",
          buttons: false,
          timer: 1300,
        });
      }
    }

    if (btnName === "Dr.CONSULTATION") {
      if (paymentMethod === "cash") {
        const response = await fetch(domain + "api/bills/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            amount: doctorFee,
            patient: userId,
            category: 1,
          }),
        });

        if (response.ok) {
          swal({ title: "Patient successful sent to Dr", icon: "success" });
        } else {
          swal({
            title: "Error occured",
            text: "Please try again or Consult System admin",
            icon: "error",
          });
        }
      }
    }
  };

  // Function to get city name based on city id
  const getLocation = (cityId) => {
    const city = cities.find((city) => city.id === cityId);
    return city ? city.name : "";
  };

  const getFee = (doctorId) => {
    // Check if doctorFees is available and not empty
    if (doctorFees && doctorFees.length > 0) {
      const fee = doctorFees.find((fee) => fee.doctor == doctorId);
      return fee ? fee.consultation_fee : "";
    }
    return ""; // Return empty string if doctorFees is not yet available or empty
  };

  const getInvoiceCategory = (categoryId) => {
    const category = invoiceCategory.find(
      (category) => category.id === categoryId
    );
    return category ? category.name : "";
  };

  // Handler for doctor selection
  const handleDoctorChange = (event) => {
    const selectedDoctorId = event.target.value;

    setSelectedDoctor(selectedDoctorId);
    setDoctorFee(getFee(selectedDoctorId));
  };

  return (
    <React.Fragment>
      <button title={btnName} className={btnClass} onClick={handleClickOpen}>
        <i className={"fa fa-" + iconName + " m-r-5"}></i>
        {btnName}
      </button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {btnName === "DELETE" || <DialogTitle>{formTitle}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            {(btnName === "ADD" || btnName === "Edit") && (
              <>
                <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                  <TextField
                    fullWidth
                    name="full_name"
                    type="text"
                    required
                    id="outlined-required"
                    label="Full name"
                    value={staffData.full_name}
                    onChange={handleInputChange}
                    error={!!error.full_name}
                    helperText={error.full_name}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                  <TextField
                    fullWidth
                    name="email"
                    type="email"
                    required
                    id="outlined-required"
                    label="Email"
                    value={staffData.email}
                    onChange={handleInputChange}
                    error={!!error.email}
                    helperText={error.email}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                  <TextField
                    label="Phone Number *"
                    name="phone_number"
                    value={staffData.phone_number}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={!!error.phone_number}
                    helperText={error.phone_number}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                  <TextField
                    label="ID Type *"
                    select
                    name="id_type"
                    value={staffData.id_type}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    error={!!error.id_type}
                    helperText={error.id_type}
                  >
                    <MenuItem value="NIDA">NIDA</MenuItem>
                    <MenuItem value="VOTER_ID">Voter ID</MenuItem>
                  </TextField>
                </FormControl>
                {staffData?.id_type !== "" && (
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <TextField
                      label="ID Number *"
                      name="id_number"
                      value={staffData.id_number}
                      onChange={handleInputChange}
                      fullWidth
                      margin="normal"
                      error={!!error.id_number}
                      helperText={error.id_number}
                    />
                  </FormControl>
                )}

                <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                  <TextField
                    name="groups"
                    select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Role"
                    required
                    value={staffData.groups}
                    onChange={handleInputChange}
                    error={!!error.groups}
                    helperText={error.groups}
                  >
                    {(() => {
                      try {
                        return groups.map((group) => (
                          <MenuItem value={group.id}>{group.name}</MenuItem>
                        ));
                      } catch (error) {
                        console.log(error);
                        return (
                          <option>
                            Network Problem.Please check your Internet
                          </option>
                        );
                      }
                    })()}
                  </TextField>
                </FormControl>
                {/* hide branch when role is admin/ General manager */}
                {![1, 2].includes(staffData.groups) && (
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <TextField
                      name="my_branch"
                      select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Branch"
                      required
                      value={staffData.my_branch}
                      onChange={handleInputChange}
                      error={!!error.my_branch}
                      helperText={error.my_branch}
                    >
                      {(() => {
                        try {
                          return branches.map((group) => (
                            <MenuItem value={group.id}>{group.name}</MenuItem>
                          ));
                        } catch (error) {
                          console.log(error);
                          return (
                            <option>
                              Network Problem.Please check your Internet
                            </option>
                          );
                        }
                      })()}
                    </TextField>
                  </FormControl>
                )}

                <Button
                  variant="contained"
                  component="label"
                  startIcon={<Upload />}
                >
                  Upload Photo
                  <input
                    type="file"
                    name="photo"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </Button>
                <FormHelperText error>{error.photo}</FormHelperText>
              </>
            )}

            {btnName === "Delete" && (
              <FormControl fullWidth sx={{ width: "60ch" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Enter your Password *
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  required
                  label="Enter your Password *"
                  onChange={(event) => setPassword(event.target.value)}
                  error={!!error.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {error.password && (
                  <FormHelperText error>{error.password}</FormHelperText>
                )}
              </FormControl>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-light m-1 float-right"
            onClick={handleClose}
          >
            CLOSE
          </button>
          {btnName === "Delete" ? (
            <button
              type="submit"
              className="btn m-1 float-right btn-primary"
              onClick={handleSubmit}
            >
              DELETE
            </button>
          ) : (
            <button
              type="submit"
              className="btn m-1 float-right btn-primary"
              onClick={handleSubmit}
            >
              SUBMIT
            </button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
