export default  function formatRoleName (roles)  {
    if (Array.isArray(roles)) {
      return roles
        .map(role =>
          role
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        )
        .join(', ');
    }
    return roles
      .toLowerCase()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  
  
    