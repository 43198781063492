import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import Slide from "@mui/material/Slide";
import "./Popup.css";
import { domain } from "../auth/domain";
import swal from "sweetalert";
import confirmImage from "../assets/img/sent.png";
import decodeJwtToken from "../auth/decodeJwtToken";
import { LinearProgress } from "@mui/material";
import {
  FormControl,
  TextField,
  Checkbox,
  Autocomplete,
  Box,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AnnouncementDialog({
  btnName,
  btnClass,
  iconName,
  formTitle,
  userId,
  onToggleModal,
  onRefreshTable,
  disabled,
}) {
  const [open, setOpen] = React.useState(false);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [branches, setBranches] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [message, setMessage] = useState({
    title: "",
    body: "",
    is_active_till: "",
    category: "",
    branches: [],
  });
  const [error, setError] = useState({
    title: "",
    body: "",
    branches: "",
    is_active_till: "",
  });

  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);

  useEffect(() => {
    if (btnName === "NEW") {
      fetch(domain + "branches/branches/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setBranches(data);
        })
        .catch((error) => console.error(error));
    }
  }, [btnName, accessToken]);

  useEffect(() => {
    setMessage((prevMessage) => ({
      ...prevMessage,
      branches: selectedBranches.map((branch) => branch.id),
    }));
  }, [selectedBranches]);

  const handleClickOpen = () => {
    setOpen(true);
    onToggleModal && onToggleModal(true);
    handleEditMode();
  };

  const handleMessageInputChange = (e) => {
    const { name, value } = e.target;
    setMessage({
      ...message,
      [name]: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
    onToggleModal(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (btnName === "NEW") {
      setShowSpinner(true);
      let hasError = false;
      if (!message?.title) {
        setError((prevError) => ({
          ...prevError,
          title: "Title is required",
        }));
        hasError = true;
      }
      if (!message?.body) {
        setError((prevError) => ({ ...prevError, body: "Body is required" }));
        hasError = true;
      }
      if (!message?.category) {
        setError((prevError) => ({
          ...prevError,
          category: "Category is required",
        }));
        hasError = true;
      }
      if (message.branches.length === 0) {
        setError((prevError) => ({
          ...prevError,
          branches: "Select at least one branch",
        }));
        hasError = true;
      }
      if (!message.is_active_till) {
        setError((prevError) => ({
          ...prevError,
          is_active_till: "Active till is required",
        }));
        hasError = true;
      }
      if (hasError) {
        setShowSpinner(false);
        return; // Prevent submission if there are errors
      }

      setError({
        title: "",
        body: "",
        branches: "",
        category: "",
        is_active_till: "",
      }); // Clear errors

      const response = await fetch(domain + "announcements/announcements/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + accessToken,
        },
        body: JSON.stringify(message),
      });

      if (response.ok) {
        swal({
          title: "Success",
          text: "Announcement published",
          icon: "success",
          buttons: false,
          timer: 2000,
        });

        handleClose();
        setShowSpinner(false);
        onRefreshTable(Math.random()); // Ensure you have a method to refresh your table data
        setMessage({
          title: "",
          body: "",
          is_active_till: null,
          category: "",
          branches: [],
        });
      } else {
        setShowSpinner(false);
        const body = await response.json();

        if (body) {
          const errors = body;
          for (const [field, messages] of Object.entries(errors)) {
            messages.forEach((message) => {
              swal({
                title: "Error",
                text: `${field}: ${message}`,
                icon: "error",
                timer: 3000,
                buttons: false,
              });
            });
          }
        } else {
          swal({
            title: "Error",
            text: "An unexpected error occurred",
            icon: "error",
            timer: 2000,
            buttons: false,
          });
        }
      }
    }

    // Handle other button types (e.g., EDIT, DELETE) if needed
  };

  const handleEditMode = () => {
    if (btnName === "EDIT") {
      fetch(domain + "api/running-expenses/" + userId + "/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setMessage({
            ...message,
            title: data?.title || "",
            body: data?.body || "",
            is_active_till: data?.is_active_till || null,
            category: data?.category || "",
          });
          setSelectedBranches(data?.branches || []);
        });
    }
  };

  return (
    <React.Fragment>
      <button
        title={btnName}
        className={btnClass}
        onClick={handleClickOpen}
        disabled={disabled}
      >
        <i className={"fa fa-" + iconName + " m-r-5"}></i>
        {btnName}
      </button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {showSpinner && <LinearProgress />}
        {btnName !== "DELETE" && <DialogTitle>{formTitle}</DialogTitle>}

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            {btnName === "NEW" && (
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <TextField
                        label="Title"
                        required
                        type="text"
                        name="title"
                        value={message.title}
                        onChange={handleMessageInputChange}
                        fullWidth
                        margin="normal"
                        error={!!error.title}
                        helperText={error.title}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <TextField
                        label="Body"
                        name="body"
                        multiline
                        required
                        rows={7}
                        type="text"
                        value={message.body}
                        onChange={handleMessageInputChange}
                        fullWidth
                        margin="normal"
                        error={!!error.body}
                        helperText={error.body}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <TextField
                        label="Category"
                        select
                        name="category"
                        value={message.category}
                        error={!!error.category}
                        helperText={error.category}
                        onChange={handleMessageInputChange}
                        fullWidth
                        margin="normal"
                        required
                      >
                        <MenuItem value="GENERAL">General</MenuItem>
                        <MenuItem value="IMPORTANT">Important</MenuItem>
                        <MenuItem value="URGENT">Urgent</MenuItem>
                      </TextField>
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={branches}
                        value={selectedBranches}
                        onChange={(e, v) => {
                          setSelectedBranches(v);
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            error={!!error.branches}
                            helperText={error.branches}
                            {...params}
                            label="Branches"
                            placeholder=""
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <TextField
                        label="Active till"
                        name="is_active_till"
                        required
                        type="date"
                        value={message.is_active_till}
                        onChange={handleMessageInputChange}
                        fullWidth
                        margin="normal"
                        error={!!error.is_active_till}
                        helperText={error.is_active_till}
                        inputProps={{
                          min: new Date().toISOString().split("T")[0], // Set minimum date to today
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
              </>
            )}

            {btnName === "DELETE" && (
              <div className="text-center">
                <img src={confirmImage} width="80" alt="confirm" />
                <h5 className="text-danger mt-3">
                  Are you sure you want to delete?
                </h5>
              </div>
            )}
          </Box>
        </DialogContent>
        {showSpinner || (
          <DialogActions>
            <button className="btn btn-light" onClick={handleClose}>
              CANCEL
            </button>
            <button className="btn btn-primary" onClick={handleSubmit}>
              PUBLISH
            </button>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
}
