import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../shared/Form.css";
import { domain } from "../auth/domain";
import CustomLoader from "../components/CustomLoader";
import Popup from "../components/staffsDialog";
import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import "../shared/Form.css";
import swal from "sweetalert";
import Select from "react-select";
import decodeJwtToken from "../auth/decodeJwtToken";
import userImg from "../assets/img/user.jpg";
import NoRecords from "../shared/NoRecords";
import { LinearProgress } from "@mui/material";

function Patients() {
  const accessToken = localStorage.getItem("accessToken");
  const [applicationAbstract, setApplicationAbstract] = useState("");
  const [applicationTitle, setApplicationTitle] = useState("");
  const [studyTypeId, setSelectedStudyType] = useState("");
  const [studyDesignId, setSelectedStudyDesign] = useState("");
  const [researchFieldId, setSelectedRsesearchField] = useState("");
  const [researchFields, setResearchFields] = useState([]);
  const [studyDesigns, setStudyDesigns] = useState([]);
  const [studyTypes, setStudyTypes] = useState([]);
  const [studyPurpose, setStudyPurpose] = useState("");
  const [duration, setDuration] = useState("");
  const [sponsor, setSponsor] = useState("");
  const [piName, setPiName] = useState("");
  const [piEmail, setPiEmail] = useState("");
  const [piMobile, setPiMobile] = useState("");
  const [piCountryId, setPiCountryId] = useState("");
  const [countries, setCountries] = useState([]);
  const [isStudent, setIsStudent] = useState(true);
  const [studyProposal, setStudyProposalFile] = useState(null);
  const [irbApproval, setIrbApprovalFile] = useState("NONE");
  const [dataCollection, setDataCollectionFile] = useState("NONE");
  const [cv, setCvFile] = useState(null);
  const [coverLetter, setCoverLetterFile] = useState(null);
  const [consentForm, setConsentFormFile] = useState("NONE");
  const [introductionLetter, setIntroductionLetter] = useState("NONE");
  const [activeTab, setActiveTab] = useState(0);
  const [tabCompletion, setTabCompletion] = useState([false, false, false]);
  const irbApprovalRef = useRef("NONE");
  const consentFormRef = useRef("NONE");
  const dataCollectionRef = useRef("NONE");
  const introductionLetterRef = useRef("NONE");
  const studyProposalRef = useRef(null);
  const cvRef = useRef(null);
  const coverLetterRef = useRef(null);
  const navigate = useNavigate();
  const durationList = [];
  const countryOptions = [];
  let user = decodeJwtToken(accessToken);
  const [patients, setPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [filteredPatients, setFilteredPatients] = useState(patients);
  const [showSpinner, setShowSpinner] = useState(false);
  const [insurances, setInsurances] = useState([]);
  const [cities, setCities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState("");
  // handle react page visibility based on open/clode of popup
  const handleToggleModal = (modalState) => {
    setIsModalOpen(modalState);
  };

  const handleTableRefresh = (info) => {
    setRefreshTable(info);
  };
  // Implement a function to handle the search functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = patients.filter(
      (patient) =>
        patient.first_name.toLowerCase().includes(query) ||
        patient.last_name.toLowerCase().includes(query)
      // staff.user_type.toLowerCase().includes(query)
    );
    setFilteredPatients(filtered);
  };
  // Implement a function to handle the page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Use the currentPage and perPage state variables to calculate
  // the slice of applications to be displayed on the current page

  const offset = currentPage * perPage;
  const paginatedPatients = filteredPatients.slice(offset, offset + perPage);

  const handleDeleteUser = async (userId) => {
    try {
      const result = await swal({
        title: "Are you sure you want to Delete this user ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (result) {
        const response = await fetch(domain + "auth/users/" + userId + "/", {
          method: "DELETE",
          headers: {
            Authorization: "JWT " + accessToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            current_password: 1234,
          }),
        });

        if (response.ok) {
          swal({
            title: "success",
            text: "User successful deleted",
            icon: "success",
            buttons: false,
            timer: 1300,
          });
          // handleClose();
          // onRefreshTable("update");
          handleTableRefresh(Math.random());
        } else {
          const body = await response.json();
          const details = body?.detail;
          swal({
            title: "Failed",
            text: details ? details : "Error occured",
            icon: "error",
            buttons: false,
            timer: 1300,
          });
        }
      }
    } catch (error) {}
  };
  // Fetch Current patients
  const fetchPatients = () => {
    setShowSpinner(true);

    fetch(domain + "auth/users/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPatients(data);
        setFilteredPatients(data);
        setShowSpinner(false);
      })
      .catch((error) => console.error(error))
      .finally(() => setShowSpinner(false));
  };

  useEffect(() => {
    setShowSpinner(true);
    fetchPatients();
  }, [refreshTable]);

  // Function to get insurance provider based on insurance id
  const getInsurance = (insuranceId) => {
    const insurance = insurances.find(
      (insurance) => insurance.id === insuranceId
    );
    return insurance ? insurance.insurance_provider : "";
  };

  // Function to get city name based on city id
  const getRole = (cityId) => {
    const city = cities.find((city) => city.id === cityId);
    return city ? city.name : "";
  };

  return (
    <div className="row">
      {showSpinner && <LinearProgress />}
      <h3>Users</h3>
      <div class="text-right">
        <a class="">
          <Popup
            onToggleModal={handleToggleModal}
            iconName={"plus"}
            btnName="ADD"
            btnClass={"btn btn-outline-primary mb-1"}
            formTitle={"Add user"}
            onRefreshTable={handleTableRefresh}
          />
        </a>
      </div>
      {/* <input
        style={{ borderRadius: "10px" }}
        className="form-control"
        type="text"
        placeholder="Search..."
        onChange={handleSearch}
      /> */}
      <table
        id="app"
        className="datatable table table-striped"
        style={{
          boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)",
          borderRadius: "7px",
        }}
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Role</th>
            <th>Branch</th>
            <th className="text-right">Action</th>
          </tr>
        </thead>
        <tbody>
          {(() => {
            try {
              return paginatedPatients.map((patient, index) => (
                <tr key={patient.id}>
                  <td>{index + 1}</td>
                  <td>
                    <img
                      width="28"
                      height="28"
                      src={userImg}
                      class="rounded-circle m-r-5"
                      alt=""
                    />
                    {`${patient?.full_name}`}
                  </td>
                  <td>{patient.email}</td>
                  <td>{patient?.phone_number}</td>
                  <td>{patient?.groups?.name}</td>
                  <td>{patient?.my_branch?.name}</td>

                  <td className="text-right">
                    <div class="dropdown dropdown-action">
                      <a
                        href="#"
                        class="action-icon dropdown-toggle"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-ellipsis-v"></i>
                      </a>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        x-placement="bottom-end"
                        style={{
                          position: "absolute",
                          willChange: "transform",
                          top: "0px",
                          left: "0px",
                          transform: "translate3d(-102px, 27px, 0px)",
                        }}
                      >
                        <Popup
                          onToggleModal={handleToggleModal}
                          onRefreshTable={handleTableRefresh}
                          btnName="Edit"
                          iconName={"pencil"}
                          btnClass={"dropdown-item"}
                          formTitle={"Update user"}
                          userId={patient.id}
                        />

                        {/* <button
                          className="dropdown-item"
                          onClick={() => handleDeleteUser(patient?.id)}
                        >
                          <i className="fa fa-trash-o m-r-5"></i>Delete
                        </button> */}

                        <Popup
                          onToggleModal={handleToggleModal}
                          onRefreshTable={handleTableRefresh}
                          btnName="Delete"
                          iconName={"trash-o"}
                          btnClass={"dropdown-item"}
                          formTitle={"Delete user"}
                          userId={patient.id}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ));
            } catch (error) {
              console.log(error);
              return (
                <tr>
                  <td>Network Problem.Please check your Internet</td>
                </tr>
              );
            }
          })()}
        </tbody>
      </table>
      <NoRecords data={patients} />
      {!isModalOpen && (
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageCount={Math.ceil(filteredPatients.length / perPage)}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          nextClassName="page-item"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
        />
      )}

      {/* {showSpinner && <CustomLoader />} */}
    </div>
  );
}

export default Patients;
