import jsPDF from "jspdf";
import "jspdf-autotable";
import formatCurrency from "../shared/formatCurrency";
import formatRoleName from "../shared/formatRoleName";
import TzCoatOfArms from "../assets/img/logo-Photoroom.png";
import userImg from "../assets/img/user.jpg";
import { formatDate } from "@canvasjs/charts";
import decodeJwtToken from "../auth/decodeJwtToken";

export default function statementDetailsToPDF(data, repayments) {
  const accessToken = localStorage.getItem("accessToken");
  let user = decodeJwtToken(accessToken);

  const calculateTotalCost = (data) => {
    if (!data?.claims) return 0;
    return data.claims.reduce((acc, claim) => {
      const claimTotal = claim.rate * 12;
      return acc + claimTotal;
    }, 0);
  };

  const total = calculateTotalCost(data);
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();

  // Primary color for headers, lines, and key sections
  const primaryColor = "#800b33";

  // Add logo on the left
  const leftLogoWidth = 25;
  const leftLogoHeight = 25;
  doc.addImage(TzCoatOfArms, "PNG", 10, 10, leftLogoWidth, leftLogoHeight);

  // Add customer photo on the right
  const rightLogoWidth = 25;
  const rightLogoHeight = 25;
  const rightLogoX = pageWidth - rightLogoWidth - 10;
  doc.addImage(
    data?.customer?.photo_url ? data?.customer?.photo_url : userImg,
    "PNG",
    rightLogoX,
    10,
    rightLogoWidth,
    rightLogoHeight
  );

  // Set modern header fonts and colors
  doc.setFont("helvetica", "bold");
  doc.setTextColor(primaryColor);
  doc.setFontSize(16);
  doc.text("BELLICKS FINANCE LTD", pageWidth / 2, 40, { align: "center" });

  doc.setFontSize(14);
  doc.text("LOAN STATEMENT", pageWidth / 2, 50, { align: "center" });

  // Customer Information
  doc.setFont("helvetica", "normal");
  doc.setTextColor(primaryColor);
  doc.setFontSize(12);
  doc.text(`CUSTOMER INFO`, pageWidth / 2, 70, {
    align: "center",
  });
  doc.setTextColor(0, 0, 0);
  doc.text(`Full Name: ${data?.customer?.full_name}`, pageWidth / 2, 80, {
    align: "center",
  });
  doc.text(`Phone Number: ${data?.customer?.phone_number}`, pageWidth / 2, 90, {
    align: "center",
  });
  doc.text(`Residence: ${data?.customer?.residence}`, pageWidth / 2, 100, {
    align: "center",
  });

  // Guarantor Information
  doc.setFontSize(12);
  doc.setTextColor(primaryColor);
  doc.text(`GUARANTOR INFO`, pageWidth / 2, 110, { align: "center" });
  doc.setTextColor(0, 0, 0);
  doc.text(
    `Full Name: ${data?.customer?.guarantor?.full_name}`,
    pageWidth / 2,
    120,
    {
      align: "center",
    }
  );
  doc.text(
    `Phone Number: ${data?.customer?.guarantor?.phone_number}`,
    pageWidth / 2,
    130,
    {
      align: "center",
    }
  );

  // Loan Information
  doc.setFontSize(12);
  doc.setTextColor(primaryColor);
  doc.text("LOAN INFO", pageWidth / 2, 140, { align: "center" });
  doc.setTextColor(0, 0, 0);
  doc.text(`Start Date: ${data?.start_date}`, pageWidth / 2, 150, {
    align: "center",
  });
  doc.text(`End Date: ${data?.end_date}`, pageWidth / 2, 160, {
    align: "center",
  });
  doc.text(
    `Loan Amount: ${formatCurrency(data?.repayment_amount)}`,
    pageWidth / 2,
    170,
    {
      align: "center",
    }
  );
  doc.text(
    `Amount Returned: ${formatCurrency(data?.total_amount_returned)}`,
    pageWidth / 2,
    180,
    { align: "center" }
  );

  doc.text(
    `Amount Remaining: ${formatCurrency(data?.total_amount_remain)}`,
    pageWidth / 2,
    190,
    { align: "center" }
  );

  doc.setTextColor(primaryColor);
  doc.text(`REPAYMENT HISTORY`, 15, 198);

  // Repayments Table
  doc.autoTable({
    startY: 200,
    theme: "grid",
    styles: {
      halign: "center",
      valign: "middle",
      lineColor: primaryColor,
      lineWidth: 0.2,
      fontSize: 10,
    },
    headStyles: {
      fillColor: primaryColor,
      textColor: "#ffffff",
    },
    columns: [
      { header: "#", dataKey: "index" },
      { header: "Date", dataKey: "Date" },
      { header: "Amount Paid", dataKey: "AmountPaid" },
      { header: "Loan Outstanding Amount", dataKey: "LoanOutstandingAmount" },
      { header: "Receipt Number", dataKey: "ReceiptNumber" },
    ],
    body: repayments.map((repayment, index) => ({
      index: index + 1,
      Date: repayment?.date,
      AmountPaid: formatCurrency(repayment?.amount),
      LoanOutstandingAmount: formatCurrency(
        repayment?.schedule?.loan?.repayment_amount - repayment.totalAmountPaid
      ),
      ReceiptNumber: repayment?.receipt_number,
    })),
  });

  // Footer
  const footerY = doc.autoTable.previous.finalY + 20;
  doc.setFont("helvetica", "normal");
  doc.setTextColor(primaryColor);
  doc.text(`Prepared by: ${user?.full_name}`, 20, footerY);
  doc.text(`Date: ${formatDate(new Date())}`, 20, footerY + 10);

  // Save the PDF
  doc.save(`customer-statement-${data?.customer?.full_name}.pdf`);
}
