import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./Popup.css";
import { domain } from "../auth/domain";
import swal from "sweetalert";
import confirmImage from "../assets/img/sent.png";
import { event, get } from "jquery";
import decodeJwtToken from "../auth/decodeJwtToken";
import { Tabs, Tab, Form } from "react-bootstrap";
import CustomLoader from "./CustomLoader";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import { TextField, FormHelperText } from "@mui/material";
import AOWStepper from "./AOWStepper";
import { formatPhoneNumber } from "../shared/formatter";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Autocomplete } from "@mui/material";
import Upload from "@mui/icons-material/Upload";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormsDialog({
  btnName,
  btnClass,
  iconName,
  formTitle,
  userId,
  onToggleModal,
  onRefreshTable,
  disabled,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    onToggleModal && onToggleModal(true);
    handleEditMode();
  };

  const handleRefresh = (data) => {
    onRefreshTable(data);
  };

  const handleClose = () => {
    setOpen(false);
    onToggleModal(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);
  const [branches, setBranches] = useState([]);
  const [forms, setForms] = useState([]);
  const [cities, setCities] = useState([]);
  const [error, setError] = useState({
    branch_id: "",
    total_forms: "",
    form_file: "",
  });

  const [productName, setProductName] = useState("");
  // use this for update insurance data and not insurance provider data
  const [form_file, setFormFile] = useState(null);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [firstVisit, setFirstVisit] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [invoiceCategory, setInvoiceCategory] = useState("");
  const [showLabel, setShowLabel] = useState(false);
  const [selectedBranches, setSelectedBranches] = useState("");
  const [total_forms, setTotalForms] = useState("");
  const [address, setAddress] = useState("");
  const [reason, setReason] = useState([]);
  const [cost, setCost] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [condition, setCondition] = useState("");
  const [mobile, setMobile] = useState("");
  const [invoiceItems, setInvoiceItems] = useState([
    {
      id: 1,
      name: "",
      category: "",
      low_stock_threshold: "",
      description: "",
      batch_number: "",
      expiry_date: "",
      location: 1,
    },
  ]);
  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    // Check if the selected file is an image
    if (file && file.type.startsWith("application/pdf")) {
      setFormFile(file);
    } else {
      swal({
        title: "Error",
        text: "Please select valid PDF file.",
        timer: 1300,
        buttons: false,
        icon: "error",
      });
      setFormFile(null);
    }
  };

  const handleEditMode = () => {
    if (btnName === "Edit") {
      fetch(domain + "forms/forms/" + userId + "/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setTotalForms(data?.total_forms);
          setFormFile(data?.form_file);
        });
    }
  };

  const handleInputChange = (id, field, value) => {
    setInvoiceItems(
      invoiceItems.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    onToggleModal(!isOpen);
  };

  const handleNext = () => {
    if (activeTab === 0) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    } else if (activeTab === 1) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    }

    setActiveTab(activeTab + 1);
  };

  useEffect(() => {
    if (btnName == "Reject") {
      setMaxWidth("lg");
    }

    const fetchData = async () => {
      try {
        // Fetch forms and branches concurrently
        const [formsResponse, branchesResponse] = await Promise.all([
          fetch(domain + "forms/forms/", {
            method: "GET",
            headers: {
              Authorization: "JWT " + accessToken,
            },
          }),
          fetch(domain + "branches/branches/", {
            method: "GET",
            headers: {
              Authorization: "JWT " + accessToken,
            },
          }),
        ]);

        // Check if the responses are ok
        if (!formsResponse.ok || !branchesResponse.ok) {
          throw new Error("Network response was not ok.");
        }

        // Parse JSON responses
        const formsData = await formsResponse.json();
        const branchesData = await branchesResponse.json();

        // Filter branches that are not in forms
        const formsBranchIds = formsData.map((form) => form.branch.id);
        const filteredBranches = branchesData.filter(
          (branch) => !formsBranchIds.includes(branch.id)
        );

        // Update state
        setForms(formsData);
        setBranches(filteredBranches);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (btnName === "ADD") {
      let hasError = false;
      if (!selectedBranches) {
        setError((prevError) => ({
          ...prevError,
          branch_id: "Branch is required",
        }));
        hasError = true;
      }
      if (!total_forms) {
        setError((prevError) => ({
          ...prevError,
          total_forms: "Number of forms is required",
        }));
        hasError = true;
      }

      if (!form_file) {
        setError((prevError) => ({
          ...prevError,
          form_file: "Form is required",
        }));
        hasError = true;
      }
      if (hasError) return; // Prevent submission if there are errors

      setError({ branch_id: "", total_forms: "", form_file: "" }); // Clear errors

      setError("");
      const formData = new FormData();
      formData.append("branch_id", selectedBranches);
      formData.append("total_forms", total_forms);
      formData.append("form_file", form_file);

      const response = await fetch(domain + "forms/forms/", {
        method: "POST",
        headers: {
          Authorization: "JWT " + accessToken,
        },
        body: formData,
      });

      if (response.ok) {
        swal({
          text: "Form added",
          title: "success",
          icon: "success",
          timer: 1300,
          buttons: false,
        });

        handleClose();
        // onRefreshTable("added");
        handleRefresh(Math.random());
        setAddress("");
        setName("");
        setMobile("");
      } else {
        swal({
          title: "Error",
          text: "Error occured",
          icon: "error",
          timer: 1300,
          buttons: false,
        });
      }
    }

    if (btnName === "Edit") {
      let hasError = false;
      if (!total_forms) {
        setError((prevError) => ({
          ...prevError,
          total_forms: "Number of forms is required",
        }));
        hasError = true;
      }

      if (hasError) return; // Prevent submission if there are errors

      setError({ branch_id: "", total_forms: "", form_file: "" }); // Clear errors

      const formDataEdit = new FormData();
      formDataEdit.append("total_forms", total_forms);

      // Append form_file only if it's a new upload
      if (form_file && form_file.type === "application/pdf") {
        formDataEdit.append("form_file", form_file);
      }

      try {
        const response = await fetch(domain + "forms/forms/" + userId + "/", {
          method: "PATCH",
          headers: {
            Authorization: "JWT " + accessToken,
            // Do not set Content-Type here if using FormData
          },
          body: formDataEdit, // Using FormData
        });

        if (response.ok) {
          swal({
            title: "success",
            text: "Form updated",
            icon: "success",
            buttons: false,
            timer: 2000,
          });
          setReason("");
          handleClose();
          handleRefresh(Math.random());
        } else {
          const body = await response.json();
          const details = body?.detail;
          swal({
            title: "Failed",
            text: details ? details : "Error occurred",
            icon: "error",
            buttons: false,
            timer: 2000,
          });
          handleClose();
        }
      } catch (error) {
        swal({
          title: "Error",
          text: "Error occurred",
          icon: "error",
          buttons: false,
          timer: 2000,
        });
      }
    }

    if (btnName === "Dr.CONSULTATION") {
      // if (doctorId === "") {
      //   swal({ icon: "info", title: "Please select Dr" });
      //   return;
      // }

      if (paymentMethod === "cash") {
      }

      if (paymentMethod === "insurance") {
        const response = await fetch(domain + "api/invoices/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            patient: userId,
            category: 1,
          }),
        });

        if (response.ok) {
          swal({ title: "Patient successful sent to Dr", icon: "success" });
        } else {
          swal({
            title: "Error occured",
            text: "Please try again or Consult System admin",
            icon: "error",
          });
        }
      }
    }
  };

  // Function to get city name based on city id
  const getLocation = (cityId) => {
    const city = cities.find((city) => city.id === cityId);
    return city ? city.name : "";
  };

  const getInvoiceCategory = (categoryId) => {
    const category = invoiceCategory.find(
      (category) => category.id === categoryId
    );
    return category ? category.name : "";
  };

  return (
    <React.Fragment>
      <button
        title={btnName}
        className={btnClass}
        onClick={handleClickOpen}
        disabled={disabled}
      >
        <i className={"fa fa-" + iconName + " m-r-5"}></i>
        {btnName}
      </button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {btnName === "DELETE" || <DialogTitle>{formTitle}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            {(btnName === "ADD" || btnName === "Edit") && (
              <>
                <div className="row">
                  <div className="col-lg-12">
                    {btnName === "Edit" || (
                      <FormControl fullWidth sx={{ m: 1 }}>
                        <TextField
                          label="Branches"
                          select
                          name="branch_id"
                          value={selectedBranches}
                          error={!!error.branch_id}
                          helperText={error.branch_id}
                          onChange={(e) => setSelectedBranches(e.target.value)}
                          fullWidth
                          margin="normal"
                          required
                        >
                          {(() => {
                            try {
                              return branches.map((group) => (
                                <MenuItem value={group.id}>
                                  {group.name}
                                </MenuItem>
                              ));
                            } catch (error) {
                              console.log(error);
                              return (
                                <option>
                                  Network Problem.Please check your Internet
                                </option>
                              );
                            }
                          })()}
                        </TextField>
                      </FormControl>
                    )}
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <TextField
                        type="number"
                        label="Number"
                        error={!!error.total_forms}
                        helperText={error.total_forms}
                        value={total_forms}
                        onChange={(event) => {
                          setTotalForms(event.target.value);
                        }}
                      />
                    </FormControl>
                    <Button
                      variant="contained"
                      component="label"
                      startIcon={<Upload />}
                    >
                      Upload form
                      <input
                        type="file"
                        name="form_file"
                        accept="application/pdf"
                        onChange={handleFileChange}
                      />
                    </Button>
                    <FormHelperText error>{error.form_file}</FormHelperText>
                  </div>
                </div>
              </>
            )}
            {btnName == "Reject" && (
              <div className="col-lg-12">
                <FormControl fullWidth sx={{ width: "80ch" }}>
                  <TextField
                    label="Rejection reason"
                    multiline
                    required
                    rows={3}
                    type="text"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!error}
                    helperText={error}
                  />
                </FormControl>
              </div>
            )}
            {btnName === "DELETE" && (
              <div class="text-center">
                <img src={confirmImage} alt="" width="50" height="46" />
                <h3>Are you sure want to Delete this Wastage ?</h3>

                <div class="m-t-20"> </div>
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-light m-1 float-right"
            onClick={handleClose}
          >
            CLOSE
          </button>
          {btnName === "DELETE" ? (
            <button
              type="submit"
              className="btn m-1 float-right btn-danger"
              onClick={handleSubmit}
            >
              DELETE
            </button>
          ) : (
            <button
              type="submit"
              className="btn m-1 float-right btn-primary"
              onClick={handleSubmit}
            >
              SUBMIT
            </button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
