import React, { createContext, useContext, useState } from "react";
import decodeJwtToken from "../auth/decodeJwtToken";

const accessToken = localStorage.getItem("accessToken");
const user = decodeJwtToken(accessToken);
const LoanNotificationContext = createContext();

export const LoanNotificationProvider = ({ children }) => {
  const [loanNotification, setLoanNotification] = useState(0); //default value

  return (
    <LoanNotificationContext.Provider
      value={{ loanNotification, setLoanNotification }}
    >
      {children}
    </LoanNotificationContext.Provider>
  );
};

export const useLoanNotification = () => {
  return useContext(LoanNotificationContext);
};
