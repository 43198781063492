import React, { createContext, useContext, useState } from "react";
import decodeJwtToken from "../auth/decodeJwtToken";

const accessToken = localStorage.getItem("accessToken");
const user = decodeJwtToken(accessToken);
const BranchContext = createContext();

export const BranchProvider = ({ children }) => {
  const allBranch = { id: "", name: "All" };
  // set allbrach if group is Admini i.e 1 or General manager i.e 2
  const defaultBranch = ["General Manager", "Admin"].includes(user?.group?.name)
    ? allBranch
    : user?.branch;
  const [branch, setBranch] = useState(defaultBranch); // Initialize with null or default value

  return (
    <BranchContext.Provider value={{ branch, setBranch }}>
      {children}
    </BranchContext.Provider>
  );
};

export const useBranch = () => {
  return useContext(BranchContext);
};
