import React, { useState } from "react";
import { FormControl, TextField, MenuItem, Select, LinearProgress } from "@mui/material";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import swal from "sweetalert";
import moment from "moment";
import { domain } from "../auth/domain";
import formatCurrency from "../shared/formatCurrency";
import { useNavigate } from "react-router-dom";
import ExportToGraph from "../components/ExportToGraph";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Reportes() {
  const accessToken = localStorage.getItem("accessToken");
  const [sales, setSales] = useState([]);
  const [reportIsRead, setReportIsRead] = useState(false);
  const [perPage, setPerPage] = useState(5);
  const [filteredSales, setFilteredSales] = useState(sales);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [type, setType] = useState("");
  const [profit, setProfit] = useState("");
  const [purchasesTotal, setPurchasesTotal] = useState("");
  const [expensesTotal, setExpensesTotal] = useState("");
  const [wastagesTotal, setWastagesTotal] = useState("");
  const [salesTotal, setSalesTotal] = useState("");
  const [returnedSalesTotal, setReturnedSalesTotal] = useState("");
  const [returnedPurchasesTotal, setReturnedPurchasesTotal] = useState("");
  const [associatedCostsTotal, setAssociatedCostsTotal] = useState("");
  const [interval, setInterval] = useState('monthly');
  const [trend, setTrend] = useState([]);
  const navigate = useNavigate();

  const chartData = {
    labels: trend.map(item => item.period),
    datasets: [
      {
        label: 'Profit',
        data: trend.map(item => item.total_profit),
        borderColor: 'green',
        backgroundColor: 'rgba(0, 255, 0, 0.2)',
      }
    ]
  };

  const handleToggleModal = (modalState) => {
    setIsModalOpen(modalState);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const url = type === 'income'
      ? `${domain}api/profitloss/`
      : `${domain}api/profitloss/profit_loss_over_time/?start_date=${startDate}&end_date=${endDate}&interval=${interval}`;

    const options = {
      method: type === 'income' ? 'POST' : 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${accessToken}`
      }
    };

    if (type === 'income') {
      options.body = JSON.stringify({
        start_date: startDate,
        end_date: endDate
      });
    }

    const response = await fetch(url, options);

    if (response.ok) {
      const data = await response.json();

      if (type === 'income') {
        setReportIsRead(true)
        setProfit(data.profit);
        setPurchasesTotal(data.purchases_total);
        setSalesTotal(data.sales_total);
        setExpensesTotal(data.expenses_total);
        setWastagesTotal(data?.wastages_total);
        setReturnedSalesTotal(data.returned_sales_total);
        setReturnedPurchasesTotal(data.returned_purchases_total);
        setAssociatedCostsTotal(data.associated_costs_total);
        setStartDate(data.start_date);
        setEndDate(data.end_date);
      } else {
        setTrend(data);
        setReportIsRead(true)
        // alert(trend.length)
        
      }

      setOpen(true);
    } else {
      const body = await response.json();
      if (body["detail"]) {
        swal({ icon: "info", title: body["detail"] });
        navigate("/dashboard");
      }

      swal({
        title: body["error"] ? body["error"] : "Error occurred",
        icon: "error",
      });
    }
  };

  return (
    <div className="row">
      <Dialog
        // open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{type === 'income' ? 'Income Statement' : 'Profit/Loss Trend - '+interval}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {showSpinner && <LinearProgress />}{" "}
            <h4>{` ${startDate} - ${endDate}`}</h4>
          </DialogContentText>
    
          
        </DialogContent>
        <DialogActions>
          <button className="btn btn-light m-1 float-right" onClick={handleClose}>
            CLOSE
          </button>
        </DialogActions>
      </Dialog>
      <h3>Financial Reports</h3>
      
      <div className="auth-form-light text-left py-5 px-4 px-sm-5 card" style={{ boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)", borderRadius: "7px" }}>
        <form className="pt-3 form-custom" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-12">
              <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
                <TextField
                  label="Type"
                  select
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value="income">Income statement</MenuItem>
                  <MenuItem value="trend">Profit/Loss Trend</MenuItem>
                </TextField>
              </FormControl>
            </div>
            {type === 'trend' && (
              <div className="col-lg-12">
                <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
                  <TextField
                    label="Interval"
                    select
                    value={interval}
                    onChange={(e) => setInterval(e.target.value)}
                  >
                    <MenuItem value="daily">daily</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="quarterly">Quarterly</MenuItem>
                    <MenuItem value="yearly">Yearly</MenuItem>
                  </TextField>
                </FormControl>
              </div>
            )}
            <div className="col-lg-6">
              <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
                <TextField
                  type="date"
                  required
                  label="From"
                  value={startDate}
                  onChange={(event) => setStartDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </div>
            <div className="col-lg-6">
              <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
                <TextField
                  type="date"
                  required
                  label="To"
                  value={endDate}
                  onChange={(event) => setEndDate(event.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </div>
          </div>
          {showSpinner ? (
            <LinearProgress />
          ) : (
            <div className="mt-3">
              <button className="btn btn-primary btn-lg font-weight-medium auth-form-btn" type="submit">
                GENERATE
              </button>
            </div>
          )}
          
        </form>
      </div>
      {reportIsRead === true &&(
      <div  className="auth-form-light text-left p</>y-5 px-4 px-sm-5 card" style={{ boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)", borderRadius: "7px" }}>
      {(type === 'trend') &&
          <ExportToGraph data={trend} period={{start:startDate, end:endDate, interval:interval}}/>

           }
                 {type === 'income' && (
            <div className="row">
              <div className="col-lg-12">
                <h1>{type === 'income' ? 'Income Statement' : 'Profit/Loss Trend - '+interval}</h1>
                <h4>{` ${startDate} - ${endDate}`}</h4>
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>ITEM</th>
                      <th>AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> Purchases</td>
                      <td>{formatCurrency(purchasesTotal)}</td>
                    </tr>
                    <tr>
                      <td>Returned Purchases</td>
                      <td>{formatCurrency(returnedPurchasesTotal)}</td>
                    </tr>
                    <tr>
                      <td> Sales</td>
                      <td>{formatCurrency(salesTotal)}</td>
                    </tr>
                    <tr>
                      <td>Returned Sales</td>
                      <td>{formatCurrency(returnedSalesTotal)}</td>
                    </tr>
                    <tr>
                      <td> Expenses</td>
                      <td>{formatCurrency(expensesTotal)}</td>
                    </tr>
                    <tr>
                      <td> Wastages</td>
                      <td>{formatCurrency(wastagesTotal)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-sm-5">
                <table className="table mb-0">
                  <tbody>
                    <tr>
                      <th>
                        <h3>Profit/Loss:</h3>
                      </th>
                      <td className="text-right text-primary">
                        <h3>{formatCurrency(profit)}</h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          </div>
)}
    </div>
      
  );
}

export default Reportes;
