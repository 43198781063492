import React, { useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles"; // Import ThemeProvider
import theme from "./components/theme";
import Login from "./pages/Login";
// import "../assets/fonts/font-awesome.min.css";
import MainLayout from "./layout/MainLayout";
import Dashboard from "./pages/Dashboard";
import Staffs from "./pages/Staffs";
import UpdateApplication from "./pages/UpdateApplication";
import AccountActivation from "./pages/AccountActivation";
import ForgotPassword from "./pages/ForgotPassword";
import UserProfile from "./pages/UserProfile";
import Settings from "./pages/Settings";
import UserRole from "./pages/UserRole";
import Invoices from "./pages/Invoices";
import Payments from "./pages/Payments";
import Loans from "./pages/Products";
import Purchases from "./pages/Purchases";
import Reportes from "./pages/Reportes";
import Expenses from "./pages/Expenses";
import PageNotFound from "./pages/PageNotFound";
import Order from "./pages/Order";
import Customers from "./pages/Customers";
import { BranchProvider } from "./contexts/BranchContext";
import { LoanNotificationProvider } from "./contexts/LoanNotificationContext";
import { ExpensesNotificationProvider } from "./contexts/ExpensesNotificationContext";
import Inventories from "./pages/Inventories";
import Branches from "./pages/Branches";
import Sms from "./pages/Sms";
import Forms from "./pages/Forms";
import Announcement from "./pages/Announcement";
import Repayments from "./pages/repayments";
import LoanReport from "./pages/LoanReport";
import GeneralReport from "./pages/GeneralReport";
import Reconciliations from "./pages/Reconciliations";
import CustomerStatements from "./pages/CustomerStatements";
import decodeJwtToken from "./auth/decodeJwtToken";
import ProtectedRoute from "./auth/ProtectedRoute";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("accessToken") || false
  );

  const me =
    isAuthenticated && decodeJwtToken(localStorage.getItem("accessToken"));

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  const PrivateRoute = ({ path, element }) => {
    return isAuthenticated ? (
      <Route path={path} element={element} />
    ) : (
      <Navigate to="/" replace />
    );
  };

  // authorization config
  const hasAccess = (role, allowedRoles) => allowedRoles.includes(role);

  return (
    <ExpensesNotificationProvider>
      <LoanNotificationProvider>
        <BranchProvider>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Login
                      onLogin={handleLogin}
                      isAuthenticated={isAuthenticated}
                    />
                  }
                />

                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="*" element={<PageNotFound />} />
                <Route
                  path="/account-activation/:activationCode?"
                  element={<AccountActivation />}
                />
                <Route
                  path="/general-report"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<GeneralReport />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/update-application/:id"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<UpdateApplication />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/customers"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<Customers />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/reconciliations"
                  element={
                    <ProtectedRoute
                      element={() => (
                        <MainLayout
                          onLogout={handleLogout}
                          activePage={<Reconciliations />}
                        />
                      )}
                      isAuthenticated={isAuthenticated}
                      hasAccess={hasAccess(me?.group?.name, [me?.group?.name])}
                      requiredRoles={[
                        "Admin",
                        "General Manager, Cashier, Loan Officer, Branch Manager",
                      ]}
                      redirectTo="/dashboard" // or any route you consider a safe fallback
                    />
                  }
                />

                <Route
                  path="/forms"
                  element={
                    <ProtectedRoute
                      element={() => (
                        <MainLayout
                          onLogout={handleLogout}
                          activePage={<Forms />}
                        />
                      )}
                      isAuthenticated={isAuthenticated}
                      hasAccess={hasAccess(me?.group?.name, [
                        "Admin",
                        "General Manager",
                      ])}
                      requiredRoles={["Admin", "General Manager"]}
                      redirectTo="/dashboard" // or any route you consider a safe fallback
                    />
                  }
                />
                <Route
                  path="/customer-statements/:customerId"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<CustomerStatements />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/repayments/:loanId?"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<Repayments />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/announcement"
                  element={
                    <ProtectedRoute
                      element={() => (
                        <MainLayout
                          onLogout={handleLogout}
                          activePage={<Announcement />}
                        />
                      )}
                      isAuthenticated={isAuthenticated}
                      hasAccess={hasAccess(me?.group?.name, [
                        "Admin",
                        "General Manager",
                      ])}
                      requiredRoles={["Admin", "General Manager"]}
                      redirectTo="/dashboard" // or any route you consider a safe fallback
                    />
                  }
                />
                <Route
                  path="/branches"
                  element={
                    <ProtectedRoute
                      element={() => (
                        <MainLayout
                          onLogout={handleLogout}
                          activePage={<Branches />}
                        />
                      )}
                      isAuthenticated={isAuthenticated}
                      hasAccess={hasAccess(me?.group?.name, ["Admin"])}
                      redirectTo="/dashboard" // or any route you consider a safe fallback
                    />
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<Dashboard />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/users"
                  element={
                    <ProtectedRoute
                      element={() => (
                        <MainLayout
                          onLogout={handleLogout}
                          activePage={<Staffs />}
                        />
                      )}
                      isAuthenticated={isAuthenticated}
                      hasAccess={hasAccess(me?.group?.name, ["Admin"])}
                      redirectTo="/dashboard" // or any route you consider a safe fallback
                    />
                  }
                />
                <Route
                  path="/loans/:customerId?"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<Loans />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/loan-report"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<LoanReport />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/order"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<Order />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />

                <Route
                  path="/inventories"
                  element={
                    <ProtectedRoute
                      element={() => (
                        <MainLayout
                          onLogout={handleLogout}
                          activePage={<Inventories />}
                        />
                      )}
                      isAuthenticated={isAuthenticated}
                      hasAccess={hasAccess(me?.group?.name, [
                        "General Manager",
                        "Branch Manager",
                        "Admin",
                      ])}
                      redirectTo="/dashboard" // or any route you consider a safe fallback
                    />
                  }
                />
                <Route
                  path="/roles"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<UserRole />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/purchases"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<Purchases />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/reports"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<Reportes />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/expenses"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<Expenses />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/invoices"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<Invoices />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/payments"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<Payments />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/user-profile"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<UserProfile />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/settings"
                  element={
                    isAuthenticated ? (
                      <MainLayout
                        onLogout={handleLogout}
                        activePage={<Settings />}
                      ></MainLayout>
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/sms"
                  element={
                    <ProtectedRoute
                      element={() => (
                        <MainLayout
                          onLogout={handleLogout}
                          activePage={<Sms />}
                        />
                      )}
                      isAuthenticated={isAuthenticated}
                      hasAccess={hasAccess(me?.group?.name, [
                        "Admin",
                        "General Manager",
                      ])}
                      requiredRoles={["Admin", "General Manager"]}
                      redirectTo="/dashboard" // or any route you consider a safe fallback
                    />
                  }
                />
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </BranchProvider>
      </LoanNotificationProvider>
    </ExpensesNotificationProvider>
  );
}

export default App;
