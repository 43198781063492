import React from "react";
import { Route, Navigate } from "react-router-dom";

// const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
//     return (
//         <Route
//             {...rest}
//             element={
//                 isAuthenticated ? (
//                     <Component />
//                 ) : (
//                     <Navigate to="/" replace />
//                 )
//             }
//         />
//     );
// };

// export default PrivateRoute;

const ProtectedRoute = ({
  element: Component,
  isAuthenticated,
  hasAccess,
  requiredRoles,
  redirectTo,
}) => {
  // If not authenticated, redirect to the login or landing page
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  // If authenticated but lacks required role, redirect to dashboard or other safe route
  if (!hasAccess) {
    return <Navigate to={redirectTo} replace />;
  }

  // If authenticated and has access, render the intended component
  return <Component />;
};

export default ProtectedRoute;
