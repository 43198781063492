import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ExportToGraph = ({ data, period }) => {
	// Transforming data to fit CanvasJS format
	const dataPoints = data.map(item => ({
		x: new Date(item.period),
		y: item.total_profit,
	}));

	const options = {
		animationEnabled: true,
		exportEnabled: true,
		theme: "light3",
		title: {
			text: "Profit and Loss Over Time"
		},
		axisY: {
			title: "Amount",
			prefix: "TZS"
		},
		axisX: {
			title: "Period     ("+period?.start+" - "+period?.start+") " +period?.interval
		},
		data: [{
			type: "line",
			toolTipContent: "Date: {x}<br/>Profit: TZS{y}",
			dataPoints: dataPoints
		}]
	};

	return (
		<div>
			<CanvasJSChart options={options} />
		</div>
	);
};

export default ExportToGraph;