import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../shared/Form.css";
import { domain } from "../auth/domain";
import formatCurrency from "../shared/formatCurrency";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import NoRecords from "../shared/NoRecords";
import { IconButton, LinearProgress } from "@mui/material";
import { formatDateN } from "../shared/setting";
import { useBranch } from "../contexts/BranchContext";
import { Inventory } from "@mui/icons-material";
import decodeJwtToken from "../auth/decodeJwtToken";
import formatCureny3F from "../shared/formatCurrency3F";
import formatRoleName from "../shared/formatRoleName";
import Alert from "@mui/material/Alert";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/ClearAll";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TextField, Box, MenuItem, FormControl } from "@mui/material";
import "./repayments.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Repayments() {
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);
  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-CA");
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [filteredUserTypes, setFilteredUserTypes] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState("");
  const navigate = useNavigate();
  const { branch, setBranch } = useBranch();
  const [repaymentDate, setRepaymentDate] = useState(formattedDate);
  const [repaymentStatus, setRepaymentStatus] = useState("");
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState("lg");
  const [totalPages, setTotalPages] = useState(0);
  const location = useLocation();
  // const { loanId } = location.state || {};
  const [loanId, setLoanId] = useState(location.state?.loanId || undefined);

  const handleClose = () => {
    setOpen(false);
  };
  // handle react page visibility based on open/clode of popup
  const handleToggleModal = (modalState) => {
    setIsModalOpen(modalState);
  };

  const handleTableRefresh = (info) => {
    setRefreshTable(info);
  };

  const handleDeleteInventory = async (userId) => {
    try {
      const result = await swal({
        title: "Are you sure you want to Delete this inventory item ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (result) {
        const response = await fetch(
          domain + "inventories/inventories/" + userId + "/",
          {
            method: "DELETE",
            headers: {
              Authorization: "JWT " + accessToken,
            },
          }
        );

        if (response.ok) {
          swal({
            title: "success",
            text: "Item successful deleted",
            icon: "success",
            buttons: false,
            timer: 2000,
          });
          // handleClose();
          // onRefreshTable("update");
          handleTableRefresh(Math.random());
        } else {
          const body = await response.json();
          const details = body?.detail;
          swal({
            title: "Failed",
            text: details ? details : "Error occured",
            icon: "error",
            buttons: false,
          });
        }
      }
    } catch (error) {}
  };
  // Implement a function to handle the search functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = userTypes.filter((userType) =>
      userType.name.toLowerCase().includes(query)
    );
    setFilteredUserTypes(filtered);
  };

  const handleRepaymentsFilter = () => {
    alert(1234);
  };
  // handle loans filtering
  const statusOptions = [
    { value: "over_paid", label: "Over paid" },
    { value: "partial_paid", label: "Partial paid" },
    {
      value: "fully_paid",
      label: "Fully Paid",
    },
  ];
  // Implement a function to handle the page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Use the currentPage and perPage state variables to calculate
  // the slice of applications to be displayed on the current page

  const offset = currentPage * perPage;
  const paginatedUserTypes = filteredUserTypes.slice(offset, offset + perPage);

  // fetch Current users
  // useEffect(() => {
  //   setShowSpinner(true);
  //   setBranch(branch);
  //   // Fetch user types
  //   fetch(
  //     domain +
  //       `repayments/repayments/?status=${repaymentStatus}&date=${repaymentDate}&page=${
  //         currentPage + 1
  //       }&page_size=${perPage}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         Authorization: "JWT " + accessToken,
  //       },
  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data["detail"]) {
  //         swal({ icon: "info", title: data["detail"] });
  //         navigate("/dashboard");
  //       }
  //       setUserTypes(data?.results);
  //       setFilteredUserTypes(data?.results);
  //       setShowSpinner(false);
  //     })
  //     .catch((error) => console.error(error))
  //     .finally(() => {
  //       setShowSpinner(false);
  //     });
  // }, [refreshTable, branch, repaymentDate, repaymentStatus]);

  useEffect(() => {
    const fetchRepayments = async () => {
      setShowSpinner(true);
      try {
        const response = await fetch(
          `${domain}repayments/repayments/?loan=${
            loanId ? loanId : ""
          }&status=${repaymentStatus}&date=${
            loanId ? "" : repaymentDate
          }&page=${currentPage + 1}&page_size=${perPage}`,
          {
            method: "GET",
            headers: {
              Authorization: `JWT ${accessToken}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch repayments.");
        }
        const data = await response.json();
        setUserTypes(data.results);
        setTotalPages(Math.ceil(data.count / perPage));
      } catch (err) {
        // setError(err.message);
      } finally {
        setShowSpinner(false);
      }
    };
    fetchRepayments();
  }, [currentPage, perPage, repaymentDate, repaymentStatus, loanId]);

  return (
    <div className="row">
      {/* dialog for filter repayments */}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <FormControl fullWidth sx={{ width: "60ch" }}>
              <TextField
                label="Status"
                select
                name="status"
                value={repaymentStatus}
                onChange={(e) => setRepaymentStatus(e.target.value)}
                fullWidth
                margin="normal"
              >
                <MenuItem value="">All</MenuItem>
                {statusOptions.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl fullWidth sx={{ width: "60ch" }}>
              <TextField
                label="Date"
                type="date"
                name="date"
                value={repaymentDate}
                onChange={(e) => setRepaymentDate(e.target.value)}
                fullWidth
                margin="normal"
              ></TextField>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-light m-1 float-right"
            onClick={handleClose}
          >
            CLOSE
          </button>

          {/* <button
            type="submit"
            className="btn m-1 float-right btn-primary"
            // onClick={handleSubmit}
          >
            SEARCH
          </button> */}
        </DialogActions>
      </Dialog>
      {showSpinner && <LinearProgress />}
      <h3>Repayments</h3>
      <div className="text-right">
        <IconButton title="Filter">
          <FilterListIcon onClick={() => setOpen(true)} />
        </IconButton>
        <IconButton title="Clear">
          <ClearIcon onClick={() => setLoanId(undefined)} />
        </IconButton>
      </div>
      <div style={{ overflow: "auto" }}>
        <table
          id="app"
          className="datatable table table-striped"
          style={{
            boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)",
            borderRadius: "7px",
          }}
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Customer</th>
              <th>Branch</th>
              <th>Mobile</th>
              <th>Loan&nbsp;amount</th>
              <th>Amount&nbsp;Due</th>
              <th>Total&nbsp;Amount&nbsp;Paid</th>
              <th>Outstanding&nbsp;Balance</th>
              <th>Installment&nbsp;Amount</th>
              <th>Penalty</th>
              <th>Amount&nbsp;Paid</th>
              <th>Status</th>
              <th>Periods&nbsp;Paid</th>
              <th>Periods&nbsp;Due</th>
              <th>Date&nbsp;Paid</th>
              <th>Next&nbsp;Payment&nbsp;Date</th>
            </tr>
          </thead>
          <tbody>
            {(() => {
              try {
                return userTypes.map((userType, index) => (
                  <tr key={userType.id} style={{ whiteSpace: "nowrap" }}>
                    <td>{index + 1}</td>
                    <td>{userType.schedule.loan.customer?.full_name}</td>
                    <td>{userType.schedule.loan.customer?.branch?.name}</td>
                    <td>{userType.schedule.loan.customer?.phone_number}</td>
                    <td>{formatCurrency(userType.schedule.loan.amount)}</td>
                    <td>
                      {formatCurrency(userType.schedule.loan.repayment_amount)}
                    </td>
                    <td>{formatCurrency(userType.totalAmountPaid)}</td>
                    <td>
                      {formatCurrency(
                        userType.schedule.loan.repayment_amount -
                          userType.totalAmountPaid
                      )}
                    </td>
                    <td>
                      {formatCurrency(userType?.schedule?.payment_amount)}
                    </td>
                    <td>{formatCurrency(userType?.penalties)}</td>
                    <td>{formatCurrency(userType?.amount)}</td>
                    <td>
                      {formatRoleName(userType?.schedule?.status || "N/A")}
                    </td>
                    <td>
                      {userType.schedule.loan.repayment_method === "DAILY"
                        ? `${userType.periods_paid} Days`
                        : userType.schedule.loan.repayment_method === "WEEKLY"
                        ? `${userType.schedule.periods_paid} Weeks`
                        : userType.schedule.loan.repayment_method === "MONTHLY"
                        ? `${userType?.periods_paid} Months`
                        : ""}
                    </td>
                    <td>
                      {userType.schedule.loan.repayment_method === "DAILY"
                        ? `${userType.schedule.loan.duration} Days`
                        : userType.schedule.loan.repayment_method === "WEEKLY"
                        ? `${userType.schedule.loan.duration} Weeks`
                        : userType.schedule.loan.repayment_method === "MONTHLY"
                        ? `${userType.schedule.loan.duration} Months`
                        : ""}
                    </td>
                    <td>{formatDateN(userType.date)}</td>
                    <td>
                      {!userType?.next_payment_date ? (
                        <span className="custom-badge status-green">DONE</span>
                      ) : (
                        formatDateN(userType?.next_payment_date)
                      )}
                    </td>
                  </tr>
                ));
              } catch (error) {
                console.log(error);
                return (
                  <tr>
                    <td colSpan={15}>
                      <Alert severity="error">{"Something went wrong"}</Alert>
                    </td>
                  </tr>
                );
              }
            })()}
          </tbody>
        </table>
        <NoRecords data={userTypes} />

        {!isModalOpen && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
          >
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageCount={totalPages}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              nextClassName="page-item"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
            />
            <FormControl variant="normal" size="small">
              <TextField
                select
                value={perPage}
                onChange={(e) => {
                  setPerPage(parseInt(e.target.value));
                  // setCurrentPage(1); // Reset to first page when perPage changes
                }}
              >
                {[5, 10, 20, 50].map((size) => (
                  <MenuItem key={size} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Box>
        )}
      </div>

      {/* {showSpinner && <CustomLoader />} */}
    </div>
  );
}

export default Repayments;
