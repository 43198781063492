import jsPDF from "jspdf";
import "jspdf-autotable";
import formatCurrency from "../shared/formatCurrency";
import formatRoleName from "../shared/formatRoleName";
// import orciLogo from "../assets/img/orci-logo-2.png";
import TzCoatOfArms from "../assets/img/logo-Photoroom.png";
import userImg from "../assets/img/user.jpg";
import { formatDate } from "@canvasjs/charts";
import decodeJwtToken from "../auth/decodeJwtToken";

export default function statementHintToPDF(data, paymentCheckNumber) {
  const accessToken = localStorage.getItem("accessToken");
  let user = decodeJwtToken(accessToken);
  const calculateTotalCost = (data) => {
    if (!data?.claims) return 0;
    return data.claims.reduce((acc, claim) => {
      if (true) {
        const claimTotal = claim.rate * 12;
        return acc + claimTotal;
      }
      return acc;
    }, 0);
  };
  const total = calculateTotalCost(data);
  const doc = new jsPDF();
  // Get the page width and height
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const department = `BELLICKS FINANCE LTD`;
  const section = `LOAN STATEMENT`;

  //  ONE LOGO CONFIG

  //   // Add the logo image
  //   const imgWidth = 30; // Adjust the width as needed
  //   const imgHeight = 30; // Adjust the height as needed
  //   const imgX = (pageWidth - imgWidth) / 2; // Center horizontally
  //   const imgY = 20; // Adjust the y-coordinate as needed
  //   doc.addImage(TzCoatOfArms, 'PNG', imgX, imgY, imgWidth, imgHeight);

  //   // Add the text lines one below the other
  //   doc.setFontSize(12);
  //   doc.setFont('helvetica');

  //   const line1Y = imgY + imgHeight + 8; // Adjust the y-coordinate for the first line
  //   doc.text('OCEAN ROAD CANCER INSTITUTE', (pageWidth - doc.getTextWidth('OCEAN ROAD CANCER INSTITUTE')) / 2, line1Y); // Center horizontally

  //   const line2Y = line1Y + 8; // Adjust the y-coordinate for the second line
  //   doc.text('APPROVED CLAIMS SUMMARY', (pageWidth - doc.getTextWidth('APPROVED CLAIMS SUMMARY')) / 2, line2Y); // Center horizontally

  //   const line3Y = line2Y +8; // Adjust the y-coordinate for the second line
  //   doc.text(department, (pageWidth - doc.getTextWidth(department)) / 2, line3Y); // Center horizontally

  //   const line4Y = line3Y + 8; // Adjust the y-coordinate for the second line
  //   doc.text(section, (pageWidth - doc.getTextWidth(section)) / 2, line4Y); // Center horizontally

  // TWO LOGOS CONFIG

  // Add the logo image on the left
  const leftLogoWidth = 20; // Adjust the width as needed
  const leftLogoHeight = 20; // Adjust the height as needed
  const leftLogoX = 10; // Adjust the x-coordinate for the left logo
  const leftLogoY = 10; // Adjust the y-coordinate for the left logo
  doc.addImage(
    TzCoatOfArms,
    "PNG",
    leftLogoX,
    leftLogoY,
    leftLogoWidth,
    leftLogoHeight
  );

  // Add the logo image on the right
  const rightLogoWidth = 20; // Adjust the width as needed
  const rightLogoHeight = 20; // Adjust the height as needed
  const rightLogoX = pageWidth - rightLogoWidth - 10; // Adjust the x-coordinate for the right logo
  const rightLogoY = 10; // Adjust the y-coordinate for the right logo
  doc.addImage(
    data[0]?.customer?.photo_url ? data[0]?.customer?.photo_url : userImg,
    "PNG",
    rightLogoX,
    rightLogoY,
    rightLogoWidth,
    rightLogoHeight
  );

  // Add the text lines one below the other
  doc.setFontSize(12);
  doc.setFont("helvetica");

  const line1Y =
    Math.max(leftLogoY + leftLogoHeight, rightLogoY + rightLogoHeight) + 0; // Adjust the y-coordinate for the first line
  doc.text(department, (pageWidth - doc.getTextWidth(department)) / 2, line1Y); // Center horizontally

  const line3Y = line1Y + 8; // Adjust the y-coordinate for the second line
  doc.text(section, (pageWidth - doc.getTextWidth(section)) / 2, line3Y); // Center horizontally

  const line4Y = line3Y + 8; // Adjust the y-coordinate for the second line
  doc.text(
    "CUSTOMER: " + data[0]?.customer?.full_name,
    (pageWidth - doc.getTextWidth("CUSTOMER" + data[0]?.customer?.full_name)) /
      2,
    line4Y
  ); // Center horizontally

  const line5Y = line4Y + 8; // Adjust the y-coordinate for the second line
  doc.text(
    "" + data[0]?.customer?.phone_number,
    (pageWidth - doc.getTextWidth("" + data[0]?.customer?.phone_number)) / 2,
    line5Y
  ); // Center horizontally

  doc.autoTable({
    startY: line4Y + 15,

    columns: [
      { header: "#", dataKey: "index" },
      // { header: 'ClaimDate', dataKey: 'claimMonth' },
      // { header: 'ClaimType', dataKey: 'claimType' },
      { header: "Amount", dataKey: "amount" },
      // { header: 'Claimed', dataKey: 'claimItems' },
      // { header: 'Passed', dataKey: 'approvedDays' },
      // { header: 'Rate', dataKey: 'rate' },
      { header: "Repayment Amount", dataKey: "RepaymentAmount" },
      { header: "Start", dataKey: "Start" },
      { header: "End", dataKey: "End" },
      { header: "Returned", dataKey: "Returned" },
      { header: "Outstanding", dataKey: "Outstanding" },
      // { header: 'Status', dataKey: 'claimStatus' },
    ],
    body: data.map((patient, index) => ({
      index: index + 1,
      // claimMonth: `${patient.claimMonth} ${patient.claimYear}`,
      // claimType: formatRoleName(patient.claimType),
      amount: formatCurrency(patient?.amount),
      RepaymentAmount: formatCurrency(patient?.repayment_amount),
      // approvedDays: ` days`,
      // rate: formatCurrency(patient.rate),
      Start: patient?.start_date,
      End: patient?.end_date,
      Returned: formatCurrency(patient?.total_amount_returned),
      // sectionName: patient.user.reference.sectionName,
      // departmentName: patient.user.reference.departmentName,
      Outstanding: formatCurrency(patient?.total_amount_remain),
    })),
  });

  // Add the leaders who approved the claims
  const approvedByY = doc.autoTable.previous.finalY + 12; // Adjust the y-coordinate for the approved by section
  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");
  // doc.text(`TOTAL: ${formatCurrency(total)}`, 20, approvedByY);

  doc.setFontSize(12);
  doc.setFont("helvetica", "normal");

  const line1XX = 20;
  const line2XX = 85;
  const line3XX = 155;
  const line1YY = approvedByY + 10;
  doc.text(`Prepared by:  ${user?.full_name}`, line1XX, line1YY);
  doc.text(`Date:  ${formatDate(new Date())}`, line1XX, line1YY + 10);

  // const line2YY = line1YY + 10;
  // doc.text(`Checked by: ....................`, line2XX, line1YY);
  // doc.text(`Date:  .................................`, line2XX, line1YY + 10);

  // const line3YY = line2YY + 10;
  // doc.text(`Approved by:  ....................`, line3XX, line1YY);
  // doc.text(`Date:  .................................`, line3XX, line1YY + 10);

  doc.save(`customer-statement${department}-${section}.pdf`);
}
