import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  LinearProgress,
} from "@mui/material";
import { domain } from "../auth/domain";
import formatCurrency from "../shared/formatCurrency";

const GeneralReport = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [branchId, setBranchId] = useState("");
  const [branches, setBranches] = useState([]);
  const [reportData, setReportData] = useState(null);
  const accessToken = localStorage.getItem("accessToken");
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState({
    start_date: "",
    end_date: "",
  });
  // Fetch branches using Fetch API
  useEffect(() => {
    setShowSpinner(true);
    const fetchBranches = async () => {
      try {
        const response = await fetch(`${domain}branches/branches/`, {
          method: "GET",
          headers: {
            Authorization: "JWT " + accessToken,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setBranches(data);
      } catch (error) {
        console.error("Error fetching branches:", error);
      } finally {
        setShowSpinner(false);
      }
    };
    fetchBranches();
    handleFetchReport();
  }, [startDate, endDate, branchId]);

  // Handle fetch report using Fetch API
  const handleFetchReport = async () => {
    let hasError = false;
    if (!startDate) {
      setError((prevError) => ({
        ...prevError,
        start_date: "Start date is required",
      }));
      hasError = true;
    }

    if (!endDate) {
      setError((prevError) => ({
        ...prevError,
        end_date: "End date is required",
      }));
      hasError = true;
    }

    if (hasError) return; // Prevent submission if there are errors

    setError({
      start_date: "",
      end_date: "",
    }); // Clear errors

    try {
      const params = new URLSearchParams({
        start_date: startDate,
        end_date: endDate,
        branch_id: branchId,
      });
      const response = await fetch(`${domain}reports/reports/?${params}`, {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setReportData(data);
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  return (
    <Card>
      {showSpinner && <LinearProgress />}
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Generate Report
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              error={!!error.start_date}
              helperText={error.start_date}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              error={!!error.end_date}
              helperText={error.end_date}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="branch-select-label">Branch</InputLabel>
              <Select
                labelId="branch-select-label"
                value={branchId}
                onChange={(e) => setBranchId(e.target.value)}
                label="Branch"
              >
                <MenuItem value="">
                  <em>All Branches</em>
                </MenuItem>
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.id}>
                    {branch.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleFetchReport}
            >
              Generate Report
            </Button>
          </Grid> */}
        </Grid>
        {reportData && (
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <Typography variant="h6">Report Summary</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">
                Total Insurance Amount:
              </Typography>
              <Typography variant="body1">
                {formatCurrency(reportData.total_insurance_amount)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">
                Total Registration Payment:
              </Typography>
              <Typography variant="body1">
                {formatCurrency(reportData.total_registration_payment)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">
                Total Visiting Payment:
              </Typography>
              <Typography variant="body1">
                {formatCurrency(reportData.total_visiting_payment)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">Total Penalties:</Typography>
              <Typography variant="body1">
                {formatCurrency(reportData.total_penalties)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">Total Expenses:</Typography>
              <Typography variant="body1">
                {formatCurrency(reportData.total_expenses)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">
                Total Loans Disbursed:
              </Typography>
              <Typography variant="body1">
                {formatCurrency(reportData.total_loans_disbursed)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">Total Collections:</Typography>
              <Typography variant="body1">
                {formatCurrency(reportData.total_collections)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">Total Repayments:</Typography>
              <Typography variant="body1">
                {formatCurrency(reportData.total_repayments)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle1">Exceeded Amount:</Typography>
              <Typography variant="body1">
                {formatCurrency(reportData.exceeded_amount)}
              </Typography>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default GeneralReport;
