import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../shared/Form.css";
import { domain } from "../auth/domain";
import CustomLoader from "../components/CustomLoader";
import UserRolePopup from "../components/branchesDialog";
import formatCurrency from "../shared/formatCurrency";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import NoRecords from "../shared/NoRecords";
import { LinearProgress } from "@mui/material";
import { formatDateN } from "../shared/setting";
import { useBranch } from "../contexts/BranchContext";
import { Inventory } from "@mui/icons-material";

function Branches() {
  const accessToken = localStorage.getItem("accessToken");
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [filteredUserTypes, setFilteredUserTypes] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState("");
  const navigate = useNavigate();
  const { branch, setBranch } = useBranch();

  // handle react page visibility based on open/clode of popup
  const handleToggleModal = (modalState) => {
    setIsModalOpen(modalState);
  };

  const handleTableRefresh = (info) => {
    setRefreshTable(info);
  };

  const handleDeleteInventory = async (userId) => {
    try {
      const result = await swal({
        title: "Are you sure you want to Delete this branch ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (result) {
        const response = await fetch(
          domain + "branches/branches/" + userId + "/",
          {
            method: "DELETE",
            headers: {
              Authorization: "JWT " + accessToken,
            },
          }
        );

        if (response.ok) {
          swal({
            title: "success",
            text: "Branch deleted",
            icon: "success",
            buttons: false,
            timer: 2000,
          });
          // handleClose();
          // onRefreshTable("update");
          handleTableRefresh(Math.random());
        } else {
          const body = await response.json();
          const details = body?.detail;
          swal({
            title: "Failed",
            text: details ? details : "Error occured",
            icon: "error",
            buttons: false,
          });
        }
      }
    } catch (error) {}
  };
  // Implement a function to handle the search functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = userTypes.filter((userType) =>
      userType.name.toLowerCase().includes(query)
    );
    setFilteredUserTypes(filtered);
  };
  // Implement a function to handle the page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Use the currentPage and perPage state variables to calculate
  // the slice of applications to be displayed on the current page

  const offset = currentPage * perPage;
  const paginatedUserTypes = filteredUserTypes.slice(offset, offset + perPage);

  // fetch Current users
  useEffect(() => {
    setShowSpinner(true);
    setBranch(branch);
    // Fetch user types
    fetch(domain + `branches/branches/`, {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["detail"]) {
          swal({ icon: "info", title: data["detail"] });
          navigate("/dashboard");
        }
        setUserTypes(data);
        setFilteredUserTypes(data);
        setShowSpinner(false);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setShowSpinner(false);
      });
  }, [refreshTable, branch]);

  return (
    <div className="row">
      {showSpinner && <LinearProgress />}
      <h3>Branches</h3>
      <div class="text-right">
        <a class="">
          <UserRolePopup
            onToggleModal={handleToggleModal}
            onRefreshTable={handleTableRefresh}
            btnName="ADD"
            iconName={"plus"}
            btnClass={"btn btn-outline-primary mb-1"}
            formTitle={"Add Branch"}
          />
        </a>
      </div>

      {/* <input
        style={{ borderRadius: "10px" }}
        className="form-control"
        type="text"
        placeholder="Search..."
        onChange={handleSearch}
      />  */}
      <table
        id="app"
        className="datatable table table-striped"
        style={{
          boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)",
          borderRadius: "7px",
        }}
      >
        <thead>
          <tr>
            <th>#</th>

            <th>Name</th>
            <th>Address</th>
            <th>Mobile</th>

            <th className="text-right">Action</th>
          </tr>
        </thead>
        <tbody>
          {(() => {
            try {
              return paginatedUserTypes.map((userType, index) => (
                <tr key={userType.id}>
                  <td>{index + 1}</td>
                  <td>{userType.name}</td>
                  <td>{userType?.address}</td>
                  <td>{userType.phone_number}</td>

                  <td className="text-right">
                    <div class="dropdown dropdown-action">
                      <a
                        href="#"
                        class="action-icon dropdown-toggle"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-ellipsis-v"></i>
                      </a>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        x-placement="bottom-end"
                        style={{
                          position: "absolute",
                          willChange: "transform",
                          top: "0px",
                          left: "0px",
                          transform: "translate3d(-102px, 27px, 0px)",
                        }}
                      >
                        <UserRolePopup
                          onToggleModal={handleToggleModal}
                          onRefreshTable={handleTableRefresh}
                          btnName="Edit"
                          iconName={"pencil"}
                          btnClass={"dropdown-item"}
                          formTitle={"Update branch"}
                          userId={userType.id}
                        />

                        <button
                          className="dropdown-item"
                          onClick={() => handleDeleteInventory(userType?.id)}
                        >
                          <i className="fa fa-trash-o m-r-5"></i>Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ));
            } catch (error) {
              console.log(error);
              return (
                <tr>
                  <td>Network Problem.Please check your Internet</td>
                </tr>
              );
            }
          })()}
        </tbody>
      </table>
      <NoRecords data={userTypes} />

      {!isModalOpen && (
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageCount={Math.ceil(filteredUserTypes.length / perPage)}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          nextClassName="page-item"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
        />
      )}

      {/* {showSpinner && <CustomLoader />} */}
    </div>
  );
}

export default Branches;
