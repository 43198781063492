import React, { useState, useEffect } from "react";
import "./Popup.css";
import { domain } from "../auth/domain";
import swal from "sweetalert";
import confirmImage from "../assets/img/sent.png";
import { event, get } from "jquery";
import decodeJwtToken from "../auth/decodeJwtToken";
import { Tabs, Tab, Form } from "react-bootstrap";
import CustomLoader from "./CustomLoader";
import formatCurrency from "../shared/formatCurrency";

function PurchasePopup({
  btnName,
  btnClass,
  iconName,
  formTitle,
  userId,
  onToggleModal,
  disabled,
  returnedPurchase,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);

  const [cities, setCities] = useState([]);

  const [insurances, setInsurances] = useState([]);
  const [patientCityId, setSelectedPatientCity] = useState("");
  const [patientInsuranceId, setSelectedPatientInsurance] = useState(0);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [unitePrice, setUnitePrice] = useState("");
  const [quantityPurchased, setQuantityPurchased] = useState(0);
  const [quantityReturned, setQuantityReturned] = useState("");
  const [location, setLocation] = useState("");
  const [cardNumber, setCardNumber] = useState(0);
  const [memberShipNumber, setMemberShipNumber] = useState(0);
  // use this for update insurance data and not insurance provider data
  const [insuranceId, setInsuranceId] = useState("");

  const [firstVisit, setFirstVisit] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [invoiceCategory, setInvoiceCategory] = useState("");
  const [showLabel, setShowLabel] = useState(false);

  const [nextOfKinFirstName, setNextOfKinFirstName] = useState("");
  const [nextOfKinLastName, setNextOfKinLastName] = useState("");
  const [nextOfKinMiddleName, setNextOfKinMiddleName] = useState("");
  const [purchaseReturned, setPurchaseReturned] = useState([]);
  const [nextOfKinGender, setNextOfKinGender] = useState("");
  const [nextOfKinPhone, setNextOfKinPhone] = useState("");
  const [products, setProducts] = useState("");
  const [suppliers, setSuppliers] = useState("");
  const [productId, setSelectedProduct] = useState("");
  const [supplierId, setSelectedSupplier] = useState("");
  const [doctorFee, setDoctorFee] = useState("");
  const [doctorFees, setDoctorFees] = useState([]);

  const [activeTab, setActiveTab] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [purchaseItems, setPurchaseItems] = useState([
    {
      productId: "",
      supplierId: "",
      unitePrice: "",
      quantityPurchased: 0,
    },
  ]);

  const togglePopup = () => {
    setIsOpen(!isOpen);
    onToggleModal(!isOpen);
  };

  // alert(btnName);

  const handleNext = () => {
    if (activeTab === 0) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    } else if (activeTab === 1) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    }

    setActiveTab(activeTab + 1);
  };
  // Handler for product selection
  const handleProductChange = (event) => {
    const selectedProductId = event.target.value;

    setSelectedProduct(selectedProductId);
  };

  const handleSupplierChange = (event) => {
    const selectedSupplierId = event.target.value;

    setSelectedSupplier(selectedSupplierId);
  };

  useEffect(() => {
    // fetch current Product

    fetch(domain + "api/sales/" + userId + "/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectedProduct(data?.product?.id);
        setUnitePrice(data.unit_price);
        setQuantityPurchased(data.quantity_sold);
        setLocation(1);
      });

    fetch(domain + "api/products/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      });

    fetch(domain + "api/suppliers/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSuppliers(data);
      });
    fetch(domain + "api/purchases-return/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPurchaseReturned(
          data.find((purchase) => purchase.purchase == userId)
        );
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (btnName === "ADD") {
      const response = await fetch(domain + "api/purchases/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + accessToken,
        },
        body: JSON.stringify({
          product_id: productId,
          unit_price: unitePrice,
          supplier_id: supplierId,
          quantity_purchased: quantityPurchased,
          location: 1,
        }),
      });

      if (response.ok) {
        swal({ title: "Purchase added", icon: "success" });
        setSelectedProduct("");
        setSelectedSupplier("");
        setUnitePrice("");
        setQuantityPurchased("");
        togglePopup();
      } else {
        const body = await response.json();

        swal({
          title: body["error"] ? body["error"] : "Error occured",
          icon: "error",
        });
      }
    }

    if (btnName === "return") {
      const response = await fetch(domain + "api/purchases-return/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + accessToken,
        },
        body: JSON.stringify({
          purchase: userId,
          quantity_returned: quantityReturned,
          location: 1,
        }),
      });

      if (response.ok) {
        swal({
          title: "Purchase successful returned",
          icon: "success",
        });
        setQuantityReturned("");
      } else {
        swal({ title: "Error occured", icon: "error" });
        setQuantityReturned("");
      }
    }

    if (btnName === "DELETE") {
      togglePopup();

      const response = await fetch(domain + "api/sales/" + userId + "/", {
        method: "DELETE",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      });

      if (response.ok) {
        swal({ title: "Sale successful deleted", icon: "success" });
      } else {
        swal({ title: "Something went wrong", icon: "error" });
      }
    }
  };

  // Function to get city name based on city id
  const getLocation = (cityId) => {
    const city = cities.find((city) => city.id === cityId);
    return city ? city.name : "";
  };

  const handleAddItem = () => {
    setPurchaseItems((prevItems) => [
      ...prevItems,
      {
        productId: "",
        supplierId: "",
        unitePrice: "",
        quantityPurchased: 0,
      },
    ]);
  };

  const getFee = (doctorId) => {
    // Check if doctorFees is available and not empty
    if (doctorFees && doctorFees.length > 0) {
      const fee = doctorFees.find((fee) => fee.doctor == doctorId);
      return fee ? fee.consultation_fee : "";
    }
    return ""; // Return empty string if doctorFees is not yet available or empty
  };

  const getInvoiceCategory = (categoryId) => {
    const category = invoiceCategory.find(
      (category) => category.id === categoryId
    );
    return category ? category.name : "";
  };

  return (
    <>
      <button
        disabled={disabled}
        title={btnName}
        className={"btn btn-outline-" + btnClass}
        onClick={togglePopup}
      >
        <i className={"fa fa-" + iconName + " m-r-5"}></i>
      </button>

      {isOpen && (
        <div className="popup m-5 mb-7">
          <div>
            <div class="row">
              <div class="col-md-12 align-items-center">
                {btnName === "view" && (
                  <>
                    <div class="modal-content">
                      <div class="modal-body text-center">
                        <img src={confirmImage} alt="" width="50" height="46" />
                        <h3>{formTitle}</h3>
                        <div class="m-t-20">
                          <p>
                            <h3>Return Date:</h3>
                            <h4>{returnedPurchase?.return_date}</h4>
                          </p>
                          <p>
                            <h3>Quantity Returned:</h3>
                            <h4>
                              {returnedPurchase?.quantity_returned} unit(s)
                            </h4>
                          </p>
                          <p>
                            <h3>Cost:</h3>
                            <h4>
                              {formatCurrency(returnedPurchase?.total_amount)}
                            </h4>
                          </p>
                          <button
                            className="btn btn-danger float-right m-3"
                            onClick={togglePopup}
                          >
                            X
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {btnName === "return" && (
                  <>
                    <div className="row">
                      <div className="card-box form-card">
                        <h6 className="card-title">{formTitle}</h6>
                        <form onSubmit={handleSubmit}>
                          <div class="col-lg-12">
                            Quantity
                            <input
                              required
                              type="number"
                              placeholder="Quantity Sold"
                              value={quantityReturned}
                              onChange={(event) =>
                                setQuantityReturned(event.target.value)
                              }
                              class="form-control"
                            />
                          </div>

                          <Form.Group className="button-group m-3">
                            <button type="submit" className="btn btn-primary">
                              RETURN
                            </button>

                            <button
                              className="btn btn-danger m-1"
                              onClick={togglePopup}
                            >
                              X
                            </button>
                          </Form.Group>
                        </form>
                      </div>
                    </div>
                  </>
                )}{" "}
                {btnName === "ADD" && (
                  <div className="col-md-12">
                    <div className="card-box form-card">
                      <h6 className="card-title">{formTitle}</h6>

                      <form onSubmit={handleSubmit}>
                        <Tabs activeKey={activeTab} id="tabs-example">
                          <Tab eventKey={0}>
                            <div class="form-group row">
                              <div class="col-lg-12">
                                <div class="row">
                                  <div class="col-md-6">
                                    Product Name
                                    <select
                                      required
                                      class="select form-control"
                                      value={productId}
                                      onChange={handleProductChange}
                                    >
                                      <option>Select product</option>
                                      {(() => {
                                        try {
                                          return products.map((pr) => (
                                            <option value={pr.id} key={pr.id}>
                                              {pr.name +
                                                " - stocks " +
                                                pr.stock_quantity}
                                            </option>
                                          ));
                                        } catch (error) {
                                          console.log(error);
                                          return (
                                            <option>
                                              Network Problem.Please check your
                                              Internet
                                            </option>
                                          );
                                        }
                                      })()}
                                    </select>
                                  </div>
                                  <div class="col-md-6">
                                    Supplier Name
                                    <select
                                      required
                                      class="select form-control"
                                      value={supplierId}
                                      onChange={handleSupplierChange}
                                    >
                                      <option>Select supplier</option>
                                      {(() => {
                                        try {
                                          return suppliers.map((pr) => (
                                            <option value={pr.id} key={pr.id}>
                                              {pr.full_name}
                                            </option>
                                          ));
                                        } catch (error) {
                                          console.log(error);
                                          return (
                                            <option>
                                              Network Problem.Please check your
                                              Internet
                                            </option>
                                          );
                                        }
                                      })()}
                                    </select>
                                  </div>
                                  <div class="col-md-6">
                                    Unit price
                                    <input
                                      required
                                      type="number"
                                      placeholder="Unite price"
                                      value={unitePrice}
                                      onChange={(event) =>
                                        setUnitePrice(event.target.value)
                                      }
                                      class="form-control"
                                    />
                                  </div>
                                  <div class="col-md-6">
                                    Quantity purchased
                                    <input
                                      required
                                      type="number"
                                      placeholder="Quantity purchased"
                                      value={quantityPurchased}
                                      onChange={(event) =>
                                        setQuantityPurchased(event.target.value)
                                      }
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-lg-12">
                                <div class="row"></div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-lg-12">
                                <div class="row"></div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey={1}>
                            <div class="form-group row">
                              <div class="col-lg-12">
                                <div class="row">
                                  <div class="col-md-6">
                                    Gender
                                    <select
                                      name="gender"
                                      id=""
                                      className="form-control"
                                      value={nextOfKinGender}
                                      onChange={(event) =>
                                        setNextOfKinGender(event.target.value)
                                      }
                                    >
                                      <option value="">select Gender</option>
                                      <option value="Male">Male</option>
                                      <option value="Female">Female</option>
                                    </select>
                                  </div>
                                  <div class="col-md-6">
                                    Phone
                                    <input
                                      type="phone"
                                      placeholder="Phone"
                                      value={nextOfKinPhone}
                                      onChange={(event) =>
                                        setNextOfKinPhone(event.target.value)
                                      }
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-lg-12">
                                <div class="row"></div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <div class="col-lg-12">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class=" m-b-20"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab eventKey={2}>
                            <h4 className="text-center">BILLING INFO</h4>
                            <div class="form-group row">
                              <div class="col-lg-12">
                                <div class="row">
                                  {paymentMethod === "insurance" && (
                                    <div class="col-md-12">
                                      <div class="row"></div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Tab>
                        </Tabs>

                        {/* Previous, Next, and Submit buttons */}
                        <Form.Group className="button-group m-3">
                          {activeTab > 0 && (
                            <button
                              type="button"
                              className="btn btn-outline-primary mr-1"
                              onClick={() => {
                                setActiveTab(activeTab - 1);
                              }}
                            >
                              Previous
                            </button>
                          )}

                          {activeTab < 0 && (
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                              onClick={() => handleNext()}
                            >
                              Next
                            </button>
                          )}

                          {activeTab === 0 &&
                            (showSpinner ? (
                              <CustomLoader />
                            ) : (
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>
                            ))}
                          <button
                            className="btn btn-danger m-1"
                            onClick={togglePopup}
                          >
                            X
                          </button>
                        </Form.Group>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PurchasePopup;
