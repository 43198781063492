import React, { useState, useEffect } from "react";
import "./Popup.css";
import { domain } from "../auth/domain";
import swal from "sweetalert";
import confirmImage from "../assets/img/sent.png";
import { event, get } from "jquery";
import decodeJwtToken from "../auth/decodeJwtToken";
import { Tabs, Tab, Form } from "react-bootstrap";
import CustomLoader from "./CustomLoader";
import Dialog from "@mui/material/Dialog";

function ProductPopup({
  btnName,
  btnClass,
  iconName,
  formTitle,
  userId,
  onToggleModal,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);

  const [cities, setCities] = useState([]);

  const [insurances, setInsurances] = useState([]);
  const [patientCityId, setSelectedPatientCity] = useState("");
  const [patientInsuranceId, setSelectedPatientInsurance] = useState(0);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [category, setCategory] = useState("");
  const [lowStockThreshold, setLowStockThreshold] = useState("");
  const [location, setLocation] = useState("");
  const [cardNumber, setCardNumber] = useState(0);
  const [memberShipNumber, setMemberShipNumber] = useState(0);
  // use this for update insurance data and not insurance provider data
  const [insuranceId, setInsuranceId] = useState("");

  const [firstVisit, setFirstVisit] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [invoiceCategory, setInvoiceCategory] = useState("");
  const [showLabel, setShowLabel] = useState(false);

  const [nextOfKinFirstName, setNextOfKinFirstName] = useState("");
  const [nextOfKinLastName, setNextOfKinLastName] = useState("");
  const [nextOfKinMiddleName, setNextOfKinMiddleName] = useState("");

  const [nextOfKinGender, setNextOfKinGender] = useState("");
  const [nextOfKinPhone, setNextOfKinPhone] = useState("");
  const [doctors, setDoctors] = useState("");
  const [doctorId, setSelectedDoctor] = useState("");
  const [doctorFee, setDoctorFee] = useState("");
  const [doctorFees, setDoctorFees] = useState([]);

  const [activeTab, setActiveTab] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);

  const [invoiceItems, setInvoiceItems] = useState([
    {
      id: 1,
      name: "",
      category: "",
      low_stock_threshold: "",
      description: "",
      batch_number: "",
      expiry_date: "",
      location: 1,
    },
  ]);

  const addInvoiceItem = () => {
    const highestId = Math.max(...invoiceItems.map((item) => item.id));
    setInvoiceItems([
      ...invoiceItems,
      {
        id: highestId + 1,
        name: "",
        category: "",
        low_stock_threshold: "",
        description: "",
        batch_number: "",
        expiry_date: "",
        location: 1,
      },
    ]);
  };

  const removeInvoiceItem = (id) => {
    setInvoiceItems(invoiceItems.filter((item) => item.id !== id));
  };

  const handleInputChange = (id, field, value) => {
    setInvoiceItems(
      invoiceItems.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    onToggleModal(!isOpen);
  };

  const handleNext = () => {
    if (activeTab === 0) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    } else if (activeTab === 1) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    }

    setActiveTab(activeTab + 1);
  };

  useEffect(() => {
    // fetch current Product

    fetch(domain + "api/products/" + userId + "/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setProductName(data.name);
        setProductDescription(data.description);
        setCategory(data.category);
        setLowStockThreshold(data.low_stock_threshold);
        setBatchNumber(data.batch_number);
        setExpiryDate(data.expiry_date);
        setLocation(1);
      });

    // fetch(domain + "api/profile/", {
    //   method: "GET",
    //   headers: {
    //     Authorization: "JWT " + accessToken,
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setLocation(data.filter((data) => data.user === me.user_id));
    //   });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (btnName === "ADD") {
      const response = await fetch(domain + "api/products/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + accessToken,
        },
        body: JSON.stringify([
          // name: productName,
          // description: productDescription,
          // expiry_date: expiryDate,
          // batch_number: batchNumber,
          // category,
          // low_stock_threshold: lowStockThreshold,
          // location: 1,
          ...invoiceItems,
        ]),
      });

      if (response.ok) {
        swal({ title: "Product added", icon: "success" });
        setProductName("");
        setBatchNumber("");
        setExpiryDate("");
        setCategory("");
        setLowStockThreshold("");
        togglePopup();
      } else {
        swal({ title: "Error occured", icon: "error" });
      }
    }

    if (btnName === "EDIT") {
      const response = await fetch(domain + "api/products/" + userId + "/", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + accessToken,
        },
        body: JSON.stringify({
          name: productName,
          description: productDescription,
          expiry_date: expiryDate,
          batch_number: batchNumber,
          category,
          low_stock_threshold: lowStockThreshold,
          location: 1,
        }),
      });

      if (response.ok) {
        swal({
          title: "Product successful updated",
          icon: "success",
        });
      } else {
        swal({ title: "Error occured", icon: "error" });
      }
    }

    if (btnName === "DELETE") {
      togglePopup();

      const response = await fetch(domain + "api/products/" + userId + "/", {
        method: "DELETE",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      });

      if (response.ok) {
        swal({ title: "Product successful deleted", icon: "success" });
      } else {
        swal({ title: "Something went wrong", icon: "error" });
      }
    }

    if (btnName === "Dr.CONSULTATION") {
      if (doctorId === "") {
        swal({ icon: "info", title: "Please select Dr" });
        return;
      }

      if (paymentMethod === "cash") {
        const response = await fetch(domain + "api/bills/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            amount: doctorFee,
            patient: userId,
            category: 1,
          }),
        });

        if (response.ok) {
          swal({ title: "Patient successful sent to Dr", icon: "success" });
        } else {
          swal({
            title: "Error occured",
            text: "Please try again or Consult System admin",
            icon: "error",
          });
        }
      }

      if (paymentMethod === "insurance") {
        const response = await fetch(domain + "api/invoices/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            amount: doctorFee,
            patient: userId,
            category: 1,
          }),
        });

        if (response.ok) {
          swal({ title: "Patient successful sent to Dr", icon: "success" });
        } else {
          swal({
            title: "Error occured",
            text: "Please try again or Consult System admin",
            icon: "error",
          });
        }
      }
    }
  };

  // Function to get city name based on city id
  const getLocation = (cityId) => {
    const city = cities.find((city) => city.id === cityId);
    return city ? city.name : "";
  };

  const getFee = (doctorId) => {
    // Check if doctorFees is available and not empty
    if (doctorFees && doctorFees.length > 0) {
      const fee = doctorFees.find((fee) => fee.doctor == doctorId);
      return fee ? fee.consultation_fee : "";
    }
    return ""; // Return empty string if doctorFees is not yet available or empty
  };

  const getInvoiceCategory = (categoryId) => {
    const category = invoiceCategory.find(
      (category) => category.id === categoryId
    );
    return category ? category.name : "";
  };

  // Handler for doctor selection
  const handleDoctorChange = (event) => {
    const selectedDoctorId = event.target.value;

    setSelectedDoctor(selectedDoctorId);
    setDoctorFee(getFee(selectedDoctorId));
  };

  return (
    <>
      <button
        title={btnName}
        className={"btn btn-outline-" + btnClass}
        onClick={togglePopup}
      >
        <i className={"fa fa-" + iconName + " m-r-5"}></i>
      </button>

      {isOpen && (
        <div className="popup m-5 mb-7 slimscroll">
          <div>
            <div class="row">
              <div class="col-md-12 align-items-center">
                {true &&
                  (btnName === "DELETE" ? (
                    <>
                      <div class="modal-content">
                        <div class="modal-body text-center">
                          <img
                            src={confirmImage}
                            alt=""
                            width="50"
                            height="46"
                          />
                          <h3>Are you sure want to Delete this product ?</h3>
                          <p>
                            {" "}
                            This will even delete the associated Sales and
                            Purchases
                          </p>
                          <div class="m-t-20">
                            {" "}
                            <button
                              className="btn btn-white m-3"
                              onClick={togglePopup}
                            >
                              X
                            </button>
                            <button
                              onClick={handleSubmit}
                              className="btn btn-danger"
                            >
                              DELETE
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : btnName === "NEXTOFKIN" ? (
                    <>
                      <div class="modal-content">
                        <div class="modal-body text-center">
                          <div class="col-md-12">
                            <div class="profile-view">
                              <div class="profile-img-wrap">
                                <div class="profile-img">
                                  <a href="#">
                                    <img
                                      class="avatar"
                                      src="assets/img/doctor-03.jpg"
                                      alt="nextofkin"
                                    />
                                  </a>
                                </div>
                              </div>
                              <div class="profile-basic">
                                <div class="row">
                                  <div class="col-md-5">
                                    <div class="profile-info-left">
                                      <h3 class="user-name m-t-0 mb-0">
                                        {nextOfKinFirstName +
                                          " " +
                                          nextOfKinLastName}
                                      </h3>
                                    </div>
                                  </div>
                                  <div class="col-md-7">
                                    <ul class="personal-info">
                                      <li>
                                        <span class="title">Phone:</span>
                                        <span class="text">
                                          <a href="#">{nextOfKinPhone}</a>
                                        </span>
                                      </li>
                                      <li>
                                        <span class="title">Gender:</span>
                                        <span class="text">
                                          <a href="#">{nextOfKinGender}</a>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="m-t-20 staff-msg">
                            {" "}
                            <button
                              className="btn btn-danger m-3"
                              onClick={togglePopup}
                            >
                              X
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : btnName === "Dr.CONSULTATION" ? (
                    <>
                      <div className="row">
                        <div className="card-box form-card">
                          <form onSubmit={handleSubmit}>
                            <h4 className="text-center">DR CONSULTATION </h4>

                            <div class="col-lg-12">
                              Doctor
                              <select
                                required
                                class="select form-control"
                                value={doctorId}
                                onChange={handleDoctorChange}
                              >
                                <option>Select Doctor</option>
                                {(() => {
                                  try {
                                    return doctors.map((dr) => (
                                      <option value={dr.id} key={dr.id}>
                                        Dr. {dr.first_name}
                                      </option>
                                    ));
                                  } catch (error) {
                                    console.log(error);
                                    return (
                                      <option>
                                        Network Problem.Please check your
                                        Internet
                                      </option>
                                    );
                                  }
                                })()}
                              </select>
                            </div>

                            <Form.Group className="button-group m-3">
                              <button type="submit" className="btn btn-primary">
                                Submit
                              </button>

                              <button
                                className="btn btn-danger m-1"
                                onClick={togglePopup}
                              >
                                X
                              </button>
                            </Form.Group>
                          </form>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <div className="col-md-12">
                        <div className="card-box form-card">
                          <h6 className="card-title">{formTitle}</h6>

                          <form onSubmit={handleSubmit}>
                            <Tabs activeKey={activeTab} id="tabs-example">
                              <Tab eventKey={0}>
                                <div class="form-group row">
                                  <div class="col-lg-12">
                                    <div class="row">
                                      <div class="col-md-4">
                                        Name
                                        <input
                                          required
                                          type="text"
                                          placeholder="Name"
                                          value={productName}
                                          onChange={(event) =>
                                            setProductName(event.target.value)
                                          }
                                          class="form-control"
                                        />
                                      </div>
                                      <div class="col-md-4">
                                        Category
                                        <input
                                          required
                                          type="text"
                                          placeholder="Category"
                                          value={category}
                                          onChange={(event) =>
                                            setCategory(event.target.value)
                                          }
                                          class="form-control"
                                        />
                                      </div>
                                      <div class="col-md-4">
                                        Low Stock Threshold
                                        <input
                                          required
                                          type="number"
                                          placeholder="Low Stock Threshold"
                                          value={lowStockThreshold}
                                          onChange={(event) =>
                                            setLowStockThreshold(
                                              event.target.value
                                            )
                                          }
                                          class="form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-lg-12">
                                    <div class="row">
                                      <div class="col-md-4">
                                        Description
                                        <input
                                          required
                                          type="text"
                                          placeholder="Description"
                                          value={productDescription}
                                          onChange={(event) =>
                                            setProductDescription(
                                              event.target.value
                                            )
                                          }
                                          class="form-control"
                                        />
                                      </div>
                                      <div class="col-md-4">
                                        Batch Number
                                        <input
                                          required
                                          type="phone"
                                          placeholder="Batch Number"
                                          value={batchNumber}
                                          onChange={(event) =>
                                            setBatchNumber(event.target.value)
                                          }
                                          class="form-control"
                                        />
                                      </div>
                                      <div class="col-md-4">
                                        Expire date
                                        <input
                                          required
                                          type="date"
                                          placeholder="Expire date"
                                          value={expiryDate}
                                          onChange={(event) =>
                                            setExpiryDate(event.target.value)
                                          }
                                          class="form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-lg-12">
                                    <div class="row"></div>
                                  </div>
                                </div>
                              </Tab>
                              <Tab eventKey={1}>
                                <div class="form-group row">
                                  <div class="col-lg-12">
                                    <div class="row">
                                      <div class="col-md-6">
                                        Gender
                                        <select
                                          name="gender"
                                          id=""
                                          className="form-control"
                                          value={nextOfKinGender}
                                          onChange={(event) =>
                                            setNextOfKinGender(
                                              event.target.value
                                            )
                                          }
                                        >
                                          <option value="">
                                            select Gender
                                          </option>
                                          <option value="Male">Male</option>
                                          <option value="Female">Female</option>
                                        </select>
                                      </div>
                                      <div class="col-md-6">
                                        Phone
                                        <input
                                          type="phone"
                                          placeholder="Phone"
                                          value={nextOfKinPhone}
                                          onChange={(event) =>
                                            setNextOfKinPhone(
                                              event.target.value
                                            )
                                          }
                                          class="form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-lg-12">
                                    <div class="row"></div>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-lg-12">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class=" m-b-20"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tab>
                              <Tab eventKey={2}>
                                <h4 className="text-center">BILLING INFO</h4>
                                <div class="form-group row">
                                  <div class="col-lg-12">
                                    <div class="row">
                                      {paymentMethod === "insurance" && (
                                        <div class="col-md-12">
                                          <div class="row"></div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Tab>
                            </Tabs>

                            <Form.Group className="button-group m-3">
                              {activeTab > 0 && (
                                <button
                                  type="button"
                                  className="btn btn-outline-primary mr-1"
                                  onClick={() => {
                                    setActiveTab(activeTab - 1);
                                  }}
                                >
                                  Previous
                                </button>
                              )}

                              {activeTab < 0 && (
                                <button
                                  type="button"
                                  className="btn btn-outline-primary"
                                  onClick={() => handleNext()}
                                >
                                  Next
                                </button>
                              )}

                              {activeTab === 0 &&
                                (showSpinner ? (
                                  <CustomLoader />
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Submit
                                  </button>
                                ))}
                              <button
                                className="btn btn-danger m-1"
                                onClick={togglePopup}
                              >
                                X
                              </button>
                            </Form.Group>
                          </form>
                        </div>
                      </div>
 */}
                      <div class="col-md-12 col-sm-12">
                        <div className="card-box form-card">
                          <h6 className="card-title">{formTitle}</h6>
                          <div class="table-responsive">
                            <table class="table table-hover table-white">
                              <thead>
                                <tr>
                                  <th style={{ width: "20px" }}>Name</th>
                                  <th class="col-sm-2">Category</th>
                                  <th class="col-md-6">Description</th>
                                  <th style={{ width: "100px" }}>LST</th>
                                  <th style={{ width: "80px" }}>BatchNumber</th>
                                  <th>ExpireDate</th>
                                  <th> </th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoiceItems.map((item) => (
                                  <tr key={item.id}>
                                    <td>
                                      <input
                                        type="text"
                                        value={item.name}
                                        onChange={(e) =>
                                          handleInputChange(
                                            item.id,
                                            "name",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={item.category}
                                        onChange={(e) =>
                                          handleInputChange(
                                            item.id,
                                            "category",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={item.description}
                                        onChange={(e) =>
                                          handleInputChange(
                                            item.id,
                                            "description",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      {" "}
                                      <input
                                        type="number"
                                        value={item.low_stock_threshold}
                                        onChange={(e) =>
                                          handleInputChange(
                                            item.id,
                                            "low_stock_threshold",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        value={item.batch_number}
                                        onChange={(e) =>
                                          handleInputChange(
                                            item.id,
                                            "batch_number",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="date"
                                        value={item.expiry_date}
                                        onChange={(e) =>
                                          handleInputChange(
                                            item.id,
                                            "expiry_date",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <a
                                        onClick={() => {
                                          {
                                            invoiceItems.length === 1
                                              ? alert(
                                                  "Atleast one Item required"
                                                )
                                              : removeInvoiceItem(item.id);
                                          }
                                        }}
                                        class="text-danger font-18"
                                        title="Remove"
                                      >
                                        <i class="fa fa-trash-o"></i>
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>

                                  <td colSpan="5">
                                    <a
                                      class="text-success font-18"
                                      title="Add"
                                      onClick={addInvoiceItem}
                                    >
                                      <i class="fa fa-plus"></i>
                                    </a>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                          <div class="table-responsive">
                            <table class="table table-hover table-white">
                              <tbody>
                                {/* <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td class="text-right">Total</td>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "30px",
                                      width: "230px",
                                    }}
                                  >
                                    0
                                  </td>
                                </tr> */}

                                <tr>
                                  {/* <td
                                    colspan="5"
                                    style={{
                                      textAlign: "right",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Grand Total
                                  </td> */}
                                  {/* <td
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "30px",
                                      fontWeight: "bold",
                                      fontSize: "16px",
                                      width: "230px",
                                    }}
                                  >
                                    $ 0.00
                                  </td> */}
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>

                                  <td
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "30px",
                                    }}
                                  >
                                    <button
                                      className="btn btn-danger m-1 float-right"
                                      onClick={togglePopup}
                                    >
                                      X
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-primary m-1 float-right"
                                      onClick={handleSubmit}
                                    >
                                      SUBMIT
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* new form end here  */}
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProductPopup;
