import jwtDecode from 'jwt-decode';

function decodeJwtToken(jwtToken) {
    let decodedToken = null;
    try {
        decodedToken = jwtDecode(jwtToken);
    } catch (error) {
        // Handle the error here
        console.error('Error decoding JWT token:', error);
    }
    return decodedToken;


}
export default decodeJwtToken;
