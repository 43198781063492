import React, { createContext, useContext, useState } from "react";
import decodeJwtToken from "../auth/decodeJwtToken";

const accessToken = localStorage.getItem("accessToken");
const user = decodeJwtToken(accessToken);
const ExpensesNotificationContext = createContext();

export const ExpensesNotificationProvider = ({ children }) => {
  const [expensesNotification, setExpensesNotification] = useState(0); //default value

  return (
    <ExpensesNotificationContext.Provider
      value={{ expensesNotification, setExpensesNotification }}
    >
      {children}
    </ExpensesNotificationContext.Provider>
  );
};

export const useExpensesNotification = () => {
  return useContext(ExpensesNotificationContext);
};
