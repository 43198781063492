import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.css";
import "../shared/Form.css";
import Slideshow from "../components/SlideShow";
import { Form } from "react-bootstrap";
import img1 from "../assets/img/img1.jpeg";
import img2 from "../assets/img/img2.jpeg";
import OrciBanner from "../components/OrciBanner";
import ReCaptcha from "../components/ReCaptcha";
import { domain } from "../auth/domain";
import swal from "sweetalert";
import CustomLoader from "../components/CustomLoader";
import logo from "../assets/img/logo.png";
import LinearProgress from "@mui/material/LinearProgress";

function PageNotFound({ onLogin, isAuthenticated }) {
  const navigate = useNavigate();
  // declare state variables
  const [isVerified, setIsVerified] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  // handle reCaptcha
  const handleVerification = (isVerified) => {
    setIsVerified(isVerified);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // handle reCaptcha
    if (true) {
      setShowSpinner(true);

      // timeout duration in milliseconds
      const timeoutDuration = 5000; // 5 seconds

      // Create a timeout promise
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error("Network Error"));
        }, timeoutDuration);
      });

      try {
        const response = await Promise.race([
          fetch(domain + "jwt/jwt/create", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              username,
              password,
            }),
          }),
          timeoutPromise,
        ]);

        setShowSpinner(false);

        if (response.ok) {
          const data = await response.json();

          // alert(data.access);
          // store tokens after logged in
          localStorage.setItem("accessToken", data.access);
          localStorage.setItem("refreshToken", data.refresh);
          onLogin(); // Call the onLogin callback to update isAuthenticated in App component

          swal({
            text: "Successful Login!",
            icon: "success",
            title: "Auth",
            timer: 2000,
            buttons: false,
          }).then(() => {
            setUsername("");
            setPassword("");
            navigate("/dashboard");
          });
        } else {
          const data = await response.json();
          if (response.status === 401) {
            swal({
              title: "Oops",
              text: "Invalid Credentials",
              icon: "error",
            }).catch((error) => swal({ title: error }));
          } else {
            // Handle other types of errors, if needed
            console.error("Non-401 error:", data);
          }
        }
      } catch (error) {
        setShowSpinner(false);
        if (error.message === "Network Error") {
          swal({
            title: "Network Error",
            text: "Please check your internet connection",
            icon: "error",
          });
        } else {
          console.error(error);
          swal({
            title: error.message,
            icon: "error",
          });
        }
        // Handle the timeout scenario here
        // ...
      }
    }
  };

  return (
    <>
      <div class="main-wrapper error-wrapper">
        <div class="error-box">
          <h1>404</h1>
          <h3>
            <i class="fa fa-warning"></i> Oops! Page not found!
          </h3>
          <p>The page you requested was not found.</p>
          <Link to="/dashboard" class="btn btn-primary go-home">
            Go to Home
          </Link>
        </div>
      </div>
    </>
  );
}
export default PageNotFound;
