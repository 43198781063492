import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../shared/Form.css";
import { domain } from "../auth/domain";
import CustomLoader from "../components/CustomLoader";
import UserRolePopup from "../components/UserRolePopup";
import NoRecords from "../shared/NoRecords";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { LinearProgress, TextField } from "@mui/material";
import RoleDialog from "../components/RoleDialog";
function UserRole() {
  const accessToken = localStorage.getItem("accessToken");
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [filteredUserTypes, setFilteredUserTypes] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [refreshTable, setRefreshTable] = useState("");
  // handle react page visibility based on open/clode of popup
  const handleToggleModal = (modalState) => {
    setIsModalOpen(modalState);
  };
  const handleTableRefresh = (info) => {
    setRefreshTable(info);
  };
  // Implement a function to handle the search functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = userTypes.filter((userType) =>
      userType.name.toLowerCase().includes(query)
    );
    setFilteredUserTypes(filtered);
  };
  // Implement a function to handle the page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Use the currentPage and perPage state variables to calculate
  // the slice of applications to be displayed on the current page

  const offset = currentPage * perPage;
  const paginatedUserTypes = filteredUserTypes.slice(offset, offset + perPage);

  // fetch Current users
  useEffect(() => {
    setShowSpinner(true);

    // Fetch user types
    fetch(domain + "profiles/roles", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data["detail"]) {
          swal({ icon: "info", title: data["detail"] });
          navigate("/dashboard");
        }
        setUserTypes(data);
        setFilteredUserTypes(data);
        setShowSpinner(false);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setShowSpinner(false);
      });
  }, []);

  return (
    <div className="row">
      {showSpinner && <LinearProgress />}
      <h3>Roles</h3>
      <div class="text-right">
        {/* <a class="">
          <UserRolePopup
            onToggleModal={handleToggleModal}
            btnName="ADD"
            btnClass={"primary"}
            formTitle={"Add new role"}
          />
        </a> */}
      </div>
      {/* <TextField
        style={{ borderRadius: "10px" }}
        className="form-control mb-3"
        type="text"
        placeholder="Search..."
        onChange={handleSearch}
      /> */}
      <table
        id="app"
        className="datatable table table-striped"
        style={{
          boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)",
          borderRadius: "7px",
        }}
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Role</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {(() => {
            try {
              return paginatedUserTypes.map((userType, index) => (
                <tr key={userType.id}>
                  <td>{index + 1}</td>
                  <td>{userType.name}</td>
                  <td>
                    <RoleDialog
                      onToggleModal={handleToggleModal}
                      btnName="EDIT"
                      btnClass={"dark  m-1"}
                      formTitle={"Update role"}
                      userId={userType.id}
                      onRefreshTable={handleTableRefresh}
                    />

                    {/* <UserRolePopup
                      onToggleModal={handleToggleModal}
                      btnName="DELETE"
                      btnClass={"danger"}
                      formTitle={"Are you sure wanna Delete this role ?"}
                      roleId={userType.id}
                    /> */}
                  </td>
                </tr>
              ));
            } catch (error) {
              console.log(error);
              return (
                <tr>
                  <td>Network Problem.Please check your Internet</td>
                </tr>
              );
            }
          })()}
        </tbody>
      </table>
      <NoRecords data={userTypes} />
      {!isModalOpen && (
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageCount={Math.ceil(filteredUserTypes.length / perPage)}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          nextClassName="page-item"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
        />
      )}

      {/* {showSpinner && <CustomLoader />} */}
    </div>
  );
}

export default UserRole;
