import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./Popup.css";
import { domain } from "../auth/domain";
import swal from "sweetalert";
import confirmImage from "../assets/img/sent.png";
import { event, get } from "jquery";
import decodeJwtToken from "../auth/decodeJwtToken";
import { Tabs, Tab, Form } from "react-bootstrap";
import CustomLoader from "./CustomLoader";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TextField } from "@mui/material";
import Upload from "@mui/icons-material/Upload";
import formatCureny3F from "../shared/formatCurrency3F";
import removeCommas from "../shared/removeCommas";
import { duration } from "moment";
import { formatDate } from "@canvasjs/charts";
import formatCurrency from "../shared/formatCurrency";
import formatRoleName from "../shared/formatRoleName";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import { green, red, orange, grey } from "@mui/material/colors";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Avatar,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LoanReportDialog({
  btnName,
  btnClass,
  iconName,
  formTitle,
  userId,
  onToggleModal,
  onRefreshTable,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    onToggleModal(true);
    handleEditMode();
  };

  const handleRefresh = (data) => {
    onRefreshTable(data);
  };

  const handleClose = () => {
    setOpen(false);
    onToggleModal(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);

  const [cities, setCities] = useState([]);
  const [repaymentSchedule, setRepaymentSchedule] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [patientCityId, setSelectedPatientCity] = useState("");
  const [patientInsuranceId, setSelectedPatientInsurance] = useState(0);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [batchNumber, setBatchNumber] = useState("");
  const [category, setCategory] = useState("");
  const [lowStockThreshold, setLowStockThreshold] = useState("");
  const [location, setLocation] = useState("");
  const [cardNumber, setCardNumber] = useState(0);
  const [memberShipNumber, setMemberShipNumber] = useState(0);
  // use this for update insurance data and not insurance provider data
  const [insuranceId, setInsuranceId] = useState("");
  const [reason, setReason] = useState("");
  const [firstVisit, setFirstVisit] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [invoiceCategory, setInvoiceCategory] = useState("");
  const [showLabel, setShowLabel] = useState(false);

  const [nextOfKinFirstName, setNextOfKinFirstName] = useState("");
  const [nextOfKinLastName, setNextOfKinLastName] = useState("");
  const [nextOfKinMiddleName, setNextOfKinMiddleName] = useState("");

  const [nextOfKinGender, setNextOfKinGender] = useState("");
  const [nextOfKinPhone, setNextOfKinPhone] = useState("");
  const [productFieldSetting, setProductFieldSetting] = useState({});
  const [use_description, setUseDescription] = useState(false);
  const [use_expiry_date, setUseExpiryDate] = useState(false);
  const [use_batch_number, setUseBatchNumber] = useState(false);
  const [use_category, setUseCategory] = useState(false);
  const [doctorId, setSelectedDoctor] = useState("");
  const [doctorFee, setDoctorFee] = useState("");
  const [doctorFees, setDoctorFees] = useState([]);

  const [activeTab, setActiveTab] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);

  const [invoiceItems, setInvoiceItems] = useState([
    {
      id: 1,
      name: "",
      category: "",
      low_stock_threshold: "",
      description: "",
      batch_number: "",
      expiry_date: "",
      location: 1,
    },
  ]);

  const [loanData, setLoanData] = useState({
    amount: "",
    loan_category: "",
    repayment_method: "",
    loan_contract: null,
    start_date: "",
    interest_rate: "",
    duration: null,
  });

  const handleLoanFileChange = (e) => {
    const { name, files } = e.target;
    setLoanData({
      ...loanData,
      [name]: files[0],
    });
  };

  const handleProductFields = () => {
    setInvoiceItems((prevItems) =>
      prevItems.map((item) => {
        let newItem = { ...item }; // Create a shallow copy of the item
        if (!use_category) delete newItem.category;
        if (!use_description) delete newItem.description;
        if (!use_batch_number) delete newItem.batch_number;
        if (!use_expiry_date) delete newItem.expiry_date;
        return newItem;
      })
    );
  };

  const handleLoanInputChange = (e) => {
    const { name, value: rawValue } = e.target;

    let formattedValue = rawValue;
    // Handle amount formatting separately
    if (name === "amount") {
      formattedValue = formatCureny3F(rawValue);
      setLoanData({
        ...loanData,
        amount: formattedValue,
      });
      return; // Exit the function early since phone_number change does not need further processing
    }
    setLoanData({
      ...loanData,
      [name]: rawValue,
    });
  };
  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const addInvoiceItem = () => {
    const highestId = Math.max(...invoiceItems.map((item) => item.id));
    setInvoiceItems([
      ...invoiceItems,
      {
        id: highestId + 1,
        name: "",
        category: "",
        low_stock_threshold: "",
        description: "",
        sell_price: "",
        bulk_quantity: "",
        batch_number: "",
        expiry_date: "",
        location: 1,
      },
    ]);
  };

  const removeInvoiceItem = (id) => {
    setInvoiceItems(invoiceItems.filter((item) => item.id !== id));
  };

  const handleInputChange = (id, field, value) => {
    setInvoiceItems(
      invoiceItems.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    onToggleModal(!isOpen);
  };

  const handleNext = () => {
    if (activeTab === 0) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    } else if (activeTab === 1) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    }

    setActiveTab(activeTab + 1);
  };

  const handleEditMode = () => {
    if (btnName === "Edit") {
      fetch(domain + "loans/loans/" + userId + "/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setLoanData({ ...data, ["amount"]: formatCureny3F(data.amount) });
        });
    }

    if (btnName === "Schedule") {
      fetch(domain + "repayments/repayments-schedule/?loan=" + userId, {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setRepaymentSchedule(data);
        });
    }
  };

  useEffect(() => {
    handleProductFields();
    // fetch current Product
    if (btnName === "SETTING" || btnName === "EDIT" || btnName === "ADD") {
      fetch(domain + "api/product-field-settings/" + 1 + "/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setUseDescription(data?.use_description);
          setUseExpiryDate(data?.use_expiry_date);
          setUseBatchNumber(data?.use_batch_number);
          setUseCategory(data?.use_category);
        });
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (btnName === "Edit") {
      const formData = new FormData();
      for (const key in loanData) {
        if (
          key === "loan_contract" &&
          loanData.loan_contract !== null &&
          (loanData[key] instanceof File || loanData[key] instanceof Blob)
        ) {
          formData.append(key, loanData[key]);
        }
        if (key === "amount") {
          formData.append(key, removeCommas(loanData[key]));
        }
        if (key !== "loan_contract" && key !== "amount") {
          formData.append(key, loanData[key]);
        }
      }

      try {
        const response = await fetch(domain + "loans/loans/" + userId + "/", {
          method: "PATCH",
          headers: {
            Authorization: "JWT " + accessToken,
          },
          body: formData,
        });

        if (response.ok) {
          swal({
            title: "success",
            text: "Loan successful updated",
            icon: "success",
            buttons: false,
            timer: 2000,
          });
          // setLoanData({
          //   amount: "",
          //   loan_category: "",
          //   repayment_method: "",
          //   loan_contract: null,
          //   start_date: "",
          //   interest_rate: "",
          // });
          setReason("");
          handleClose();
          // onRefreshTable("update");
          handleRefresh(Math.random());
        } else {
          const body = await response.json();
          const details = body?.detail;
          swal({
            title: "Failed",
            text: details ? details : "Error occured",
            icon: "error",
            buttons: false,
            timer: 2000,
          });
          handleClose();
        }
      } catch (error) {
        swal({
          title: "Error",
          text: "Error occured",
          icon: "error",
          buttons: false,
          timer: 2000,
        });
      }
    }

    if (btnName === "ADD") {
      const response = await fetch(domain + "api/products/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + accessToken,
        },
        body: JSON.stringify([...invoiceItems]),
      });

      if (response.ok) {
        swal({ title: "Product added", icon: "success" });
        // setInvoiceItems([
        //   {
        //     id: 1,
        //     name: "",
        //     category: "",
        //     low_stock_threshold: "",
        //     description: "",
        //     batch_number: "",
        //     expiry_date: "",
        //     location: 1,
        //   },
        // ]);
        handleClose();
        // onRefreshTable("added");
        handleRefresh(Math.random());
      } else {
        swal({ title: "Error occured", icon: "error" });
      }
    }

    if (btnName === "EDIT") {
      const response = await fetch(domain + "api/products/" + userId + "/", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + accessToken,
        },
        body: JSON.stringify([...invoiceItems][0]),
      });

      if (response.ok) {
        swal({
          title: "Product successful updated",
          icon: "success",
        });
        handleClose();
        // onRefreshTable("update");
        handleRefresh(Math.random());
      } else {
        swal({ title: "Error occured", icon: "error" });
      }
    }

    if (btnName === "SETTING") {
      const response = await fetch(
        domain + "api/product-field-settings/" + 1 + "/",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            use_description,
            use_expiry_date,
            use_batch_number,
            use_category,
          }),
        }
      );

      if (response.ok) {
        swal({
          title: "Product settings successful updated",
          icon: "success",
        });
        handleClose();
        // onRefreshTable("update");
        handleRefresh(Math.random());
      } else {
        swal({ title: "Error occured", icon: "error" });
      }
    }

    if (btnName === "DELETE") {
      togglePopup();

      const response = await fetch(domain + "api/products/" + userId + "/", {
        method: "DELETE",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      });

      if (response.ok) {
        swal({ title: "Product successful deleted", icon: "success" });
        handleRefresh("delete");
      } else {
        swal({ title: "Something went wrong", icon: "error" });
      }
    }

    if (btnName === "Dr.CONSULTATION") {
      if (doctorId === "") {
        swal({ icon: "info", title: "Please select Dr" });
        return;
      }

      if (paymentMethod === "cash") {
        const response = await fetch(domain + "api/bills/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            amount: doctorFee,
            patient: userId,
            category: 1,
          }),
        });

        if (response.ok) {
          swal({ title: "Patient successful sent to Dr", icon: "success" });
        } else {
          swal({
            title: "Error occured",
            text: "Please try again or Consult System admin",
            icon: "error",
          });
        }
      }

      if (paymentMethod === "insurance") {
        const response = await fetch(domain + "api/invoices/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            amount: doctorFee,
            patient: userId,
            category: 1,
          }),
        });

        if (response.ok) {
          swal({ title: "Patient successful sent to Dr", icon: "success" });
        } else {
          swal({
            title: "Error occured",
            text: "Please try again or Consult System admin",
            icon: "error",
          });
        }
      }
    }
  };

  // Function to get city name based on city id
  const getLocation = (cityId) => {
    const city = cities.find((city) => city.id === cityId);
    return city ? city.name : "";
  };

  const getFee = (doctorId) => {
    // Check if doctorFees is available and not empty
    if (doctorFees && doctorFees.length > 0) {
      const fee = doctorFees.find((fee) => fee.doctor == doctorId);
      return fee ? fee.consultation_fee : "";
    }
    return ""; // Return empty string if doctorFees is not yet available or empty
  };

  const getInvoiceCategory = (categoryId) => {
    const category = invoiceCategory.find(
      (category) => category.id === categoryId
    );
    return category ? category.name : "";
  };

  // Handler for doctor selection
  const handleDoctorChange = (event) => {
    const selectedDoctorId = event.target.value;

    setSelectedDoctor(selectedDoctorId);
    setDoctorFee(getFee(selectedDoctorId));
  };

  return (
    <React.Fragment>
      <button title={btnName} className={btnClass} onClick={handleClickOpen}>
        <i className={"fa fa-" + iconName + " m-r-5"}></i>
        {btnName}
      </button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {btnName === "DELETE" || <DialogTitle>{formTitle}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            {(btnName === "ADD" || btnName === "Edit") && (
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <TextField
                      type="text"
                      label="Amount"
                      name="amount"
                      value={loanData.amount}
                      onChange={handleLoanInputChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Loan Category"
                      select
                      name="loan_category"
                      value={loanData.loan_category}
                      onChange={handleLoanInputChange}
                      fullWidth
                      margin="normal"
                    >
                      <MenuItem value="ENTREPRENEUR">Entrepreneur</MenuItem>
                      <MenuItem value="BUSINESS">Business</MenuItem>
                      <MenuItem value="WORKERS">Workers</MenuItem>
                    </TextField>
                    <TextField
                      label="Repayment Method"
                      select
                      name="repayment_method"
                      value={loanData.repayment_method}
                      onChange={handleLoanInputChange}
                      fullWidth
                      margin="normal"
                    >
                      <MenuItem value="DAILY">Daily</MenuItem>
                      <MenuItem value="WEEKLY">Weekly</MenuItem>
                      <MenuItem value="MONTHLY">Monthly</MenuItem>
                    </TextField>
                    {me?.group?.name == "Admin" &&
                      loanData.repayment_method !== "DAILY" && (
                        <TextField
                          label={`Duration ${
                            loanData.repayment_method === "WEEKLY"
                              ? "(Weeks)"
                              : "(Months)"
                          }`}
                          // select
                          type="number"
                          name="duration"
                          value={loanData.duration}
                          onChange={handleLoanInputChange}
                          fullWidth
                          margin="normal"
                        >
                          {/* <MenuItem value="7">7 Weeks</MenuItem>
                          <MenuItem value={8}>8 Weeks</MenuItem>
                          <MenuItem value="9">9 Weeks</MenuItem>
                          <MenuItem value="10">10 Weeks</MenuItem> */}
                        </TextField>
                      )}
                    <TextField
                      type="number"
                      label="Interest rate %"
                      name="interest_rate"
                      value={loanData.interest_rate}
                      onChange={handleLoanInputChange}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      type="date"
                      label="Start date"
                      name="start_date"
                      value={loanData.start_date}
                      onChange={handleLoanInputChange}
                      fullWidth
                      margin="normal"
                    />
                    <Button
                      variant="contained"
                      component="label"
                      startIcon={<Upload />}
                    >
                      Upload Contract
                      <input
                        type="file"
                        name="loan_contract"
                        onChange={handleLoanFileChange}
                      />
                    </Button>
                  </div>
                </div>
              </>
            )}
            {btnName === "SETTING" && (
              <>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setUseDescription(!use_description)}
                        checked={use_description}
                      />
                    }
                    label="Use Description"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setUseExpiryDate(!use_expiry_date)}
                        checked={use_expiry_date}
                      />
                    }
                    label="Use Expire Date"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setUseBatchNumber(!use_batch_number)}
                        checked={use_batch_number}
                      />
                    }
                    label="Use Bacth Number"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setUseCategory(!use_category)}
                        checked={use_category}
                      />
                    }
                    label="Use Category"
                  />
                </FormGroup>
              </>
            )}

            {btnName === "Schedule" && (
              <div className="row">
                <div className="col-md-12">
                  <Card
                    variant="outlined"
                    sx={{ mb: 3, borderRadius: 2, boxShadow: 3 }}
                  >
                    <CardContent>
                      <Typography variant="h5" gutterBottom>
                        {repaymentSchedule[0]?.loan?.customer?.full_name}
                      </Typography>
                      <Typography variant="h6" color={grey[600]}>
                        Loan Amount:{" "}
                        {formatCurrency(repaymentSchedule[0]?.loan?.amount)}
                      </Typography>

                      <List>
                        {(() => {
                          try {
                            return repaymentSchedule.map((track, index) => (
                              <ListItem
                                key={index}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  mb: 2,
                                  "&:hover": {
                                    backgroundColor: grey[100], // Hover effect
                                  },
                                }}
                              >
                                <ListItemIcon>
                                  <Avatar
                                    sx={{
                                      bgcolor:
                                        track.status === "fully_paid"
                                          ? green[500]
                                          : track.status === "not_paid_at_all"
                                          ? red[500]
                                          : orange[500],
                                    }}
                                  >
                                    {track.status === "fully_paid" ? (
                                      <CheckCircleIcon />
                                    ) : track.status === "not_paid_at_all" ? (
                                      <CancelIcon />
                                    ) : (
                                      <PendingIcon />
                                    )}
                                  </Avatar>
                                </ListItemIcon>

                                <Box sx={{ flexGrow: 1 }}>
                                  <Typography variant="body1">
                                    Payment Date:{" "}
                                    {formatDate(track?.payment_date)}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    Amount:{" "}
                                    {formatCurrency(track?.payment_amount)}
                                  </Typography>
                                  {track?.remarks && (
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                    >
                                      {track?.remarks}
                                    </Typography>
                                  )}
                                </Box>
                              </ListItem>
                            ));
                          } catch (error) {
                            console.log(error);
                            return (
                              <div className="text-center align-content-center">
                                <Typography variant="h6">
                                  Network Problem. Please check your Internet
                                </Typography>
                              </div>
                            );
                          }
                        })()}
                      </List>
                    </CardContent>
                  </Card>
                </div>
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-light m-1 float-right"
            onClick={handleClose}
          >
            CLOSE
          </button>
          {btnName === "DELETE" ? (
            <button
              type="submit"
              className="btn m-1 float-right btn-white"
              onClick={handleSubmit}
            >
              DELETE
            </button>
          ) : (
            <button
              hidden={btnName == "Schedule"}
              type="submit"
              className="btn m-1 float-right btn-primary"
              onClick={handleSubmit}
            >
              SUBMIT
            </button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
