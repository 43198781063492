// Get the subdomain from the current window location
// let subdomain = window.location.hostname.split(".")[0];

// Conditionally set the domain based on the subdomain
// export const domain = "http://208.113.135.21/api/v1/";
export const domain = process.env.REACT_APP_DOMAIN;
// DEVELOPMENT
// export const domain =
//   subdomain === "localhost"
//     ? "http://localhost:8000/"

// DEPLOYMENT;
// export const domain =
//   subdomain === "stocktz"
//     ? "https://stocktz.xyz/"
//     : `https://${subdomain}.stocktz.xyz/`;
