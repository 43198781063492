import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";

import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function NoRecords({ data }) {
  return (
    data.length === 0 && (
      <div
        className="text-center align-content-center"
        
      >
        <h4>No Records</h4>
      </div>
    )
  );
}
export default NoRecords;
