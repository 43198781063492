import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../shared/Form.css";
import { domain } from "../auth/domain";
import CustomLoader from "../components/CustomLoader";
import UserRolePopup from "../components/ExpensesDialog";
import formatCurrency from "../shared/formatCurrency";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import NoRecords from "../shared/NoRecords";
import { LinearProgress } from "@mui/material";
import { formatDateN } from "../shared/setting";
import formatDate from "../shared/formatDate";
import { useBranch } from "../contexts/BranchContext";
import { useExpensesNotification } from "../contexts/ExpensesNotificationContext";
import decodeJwtToken from "../auth/decodeJwtToken";
import formatRoleName from "../shared/formatRoleName";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

function Expenses() {
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [filteredUserTypes, setFilteredUserTypes] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [userTypes, setUserTypes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState("");
  const navigate = useNavigate();
  const { branch, setBranch } = useBranch();
  const { expensesNotification, setExpensesNotification } =
    useExpensesNotification();

  // handle react page visibility based on open/clode of popup
  const handleToggleModal = (modalState) => {
    setIsModalOpen(modalState);
  };

  const handleTableRefresh = (info) => {
    setRefreshTable(info);
  };

  const handleApproveLoan = async (userId) => {
    try {
      const result = await swal({
        title: "Are you sure you want to Approve this expense?",
        icon: "warning",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      if (result) {
        let status = "";
        if (me?.group?.name === "Cashier") {
          status = "PENDING";
        } else if (me?.group?.name === "Branch Manager") {
          status = "APPROVED_BY_BRANCH_MANAGER";
        } else if (me?.group?.name === "General Manager") {
          status = "APPROVED_BY_GENERAL_MANAGER";
        }

        const response = await fetch(
          domain + "expenses/expenses/" + userId + "/approve/",
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: "JWT " + accessToken,
            },
            body: JSON.stringify({ status }),
          }
        );

        if (response.ok) {
          swal({
            title: "success",
            text: "Expense successful approved",
            icon: "success",
            buttons: false,
            timer: 1300,
          });
          // handleClose();
          // onRefreshTable("update");
          handleTableRefresh(Math.random());
        } else {
          const body = await response.json();
          const details = body?.detail;
          swal({
            title: "Failed",
            text: details ? details : "Error occured",
            icon: "error",
            buttons: false,
          });
        }
      }
    } catch (error) {}
  };
  // Implement a function to handle the search functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = userTypes.filter((userType) =>
      userType.name.toLowerCase().includes(query)
    );
    setFilteredUserTypes(filtered);
  };
  // Implement a function to handle the page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  // Use the currentPage and perPage state variables to calculate
  // the slice of applications to be displayed on the current page

  const offset = currentPage * perPage;
  const paginatedUserTypes = filteredUserTypes.slice(offset, offset + perPage);

  // fetch Current users
  useEffect(() => {
    setShowSpinner(true);
    setBranch(branch);
    // Fetch user types
    let status = "";
    if (me?.group?.name === "Cashier") {
      status = "";
    } else if (me?.group?.name === "Branch Manager") {
      status = "PENDING";
    } else if (me?.group?.name === "General Manager") {
      status = "APPROVED_BY_BRANCH_MANAGER";
    }
    fetch(
      domain + `expenses/expenses/?branch_id=${branch?.id}&status=${status}`,
      {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["detail"]) {
          swal({ icon: "info", title: data["detail"] });
          navigate("/dashboard");
        }
        setUserTypes(data);
        setFilteredUserTypes(data);
        setShowSpinner(false);
        setExpensesNotification(data.length);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setShowSpinner(false);
      });
  }, [refreshTable, branch]);

  return (
    <div className="row">
      {showSpinner && <LinearProgress />}
      <h3>Expenses</h3>
      <div class="text-right">
        <a class="">
          {me?.group?.name === "Cashier" && (
            <UserRolePopup
              onToggleModal={handleToggleModal}
              onRefreshTable={handleTableRefresh}
              btnName="ADD"
              iconName={"plus"}
              btnClass={"btn btn-outline-primary mb-1"}
              formTitle={"Add Expense"}
            />
          )}
        </a>
      </div>

      {/* <input
        style={{ borderRadius: "10px" }}
        className="form-control"
        type="text"
        placeholder="Search..."
        onChange={handleSearch}
      />  */}
      <table
        id="app"
        className="datatable table table-striped"
        style={{
          boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)",
          borderRadius: "7px",
        }}
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Description</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Status</th>
            {["Branch Manager", "General Manager"].includes(
              me?.group?.name
            ) && <th className="text-right">Action</th>}
          </tr>
        </thead>
        <tbody>
          {(() => {
            try {
              return paginatedUserTypes.map((userType, index) => (
                <tr key={userType.id}>
                  <td>{index + 1}</td>
                  <td>{userType.description}</td>

                  <td>{formatCurrency(userType.amount)}</td>
                  <td>{formatDate(userType.created_at)}</td>
                  <td>
                    {userType.status.includes("REJECTED") ? (
                      <span className="custom-badge status-red">
                        {formatRoleName(userType.status)}

                        <Tooltip title={userType.rejection_reason} arrow>
                          <i className="fa fa-info-circle ml-2"></i>
                        </Tooltip>
                      </span>
                    ) : (
                      <span className="custom-badge status-green">
                        {formatRoleName(userType.status)}
                      </span>
                    )}
                  </td>

                  {["Branch Manager", "General Manager"].includes(
                    me?.group?.name
                  ) && (
                    <>
                      <td className="text-right">
                        <div class="dropdown dropdown-action">
                          <a
                            href="#"
                            class="action-icon dropdown-toggle"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fa fa-ellipsis-v"></i>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            x-placement="bottom-end"
                            style={{
                              position: "absolute",
                              willChange: "transform",
                              top: "0px",
                              left: "0px",
                              transform: "translate3d(-102px, 27px, 0px)",
                            }}
                          >
                            <button
                              className="dropdown-item"
                              onClick={() => handleApproveLoan(userType?.id)}
                            >
                              <i className="fa fa-check m-r-5"></i>Approve
                            </button>
                            <UserRolePopup
                              onToggleModal={handleToggleModal}
                              onRefreshTable={handleTableRefresh}
                              btnName="Reject"
                              iconName={"times"}
                              btnClass={"dropdown-item"}
                              formTitle={"Reject loan"}
                              userId={userType.id}
                            />
                          </div>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              ));
            } catch (error) {
              console.log(error);
              return (
                <tr>
                  <td>Network Problem.Please check your Internet</td>
                </tr>
              );
            }
          })()}
        </tbody>
      </table>
      <NoRecords data={userTypes} />

      {!isModalOpen && (
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageCount={Math.ceil(filteredUserTypes.length / perPage)}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          nextClassName="page-item"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
        />
      )}

      {/* {showSpinner && <CustomLoader />} */}
    </div>
  );
}

export default Expenses;
