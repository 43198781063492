export const formatPhoneNumber = (value) => {
  // Remove any non-digit characters except the initial +255
  let digits = value.replace(/\D/g, "");

  // Ensure the phone number starts with +255 and allow only 9 additional digits
  if (digits.length > 12) {
    digits = digits.slice(0, 12);
  }

  if (!digits.startsWith("255")) {
    digits = "255" + digits;
  } else if (digits.length > 3 && digits[3] === "0") {
    digits = digits.slice(0, 3); // Remove the zero if it appears after +255
  }

  return `+${digits}`;
};

export const formatVoterId = (value) => {
  // Ensure 'T' is at the start
  if (!value.startsWith("T")) {
    value = "T" + value.replace(/[^0-9]/g, ""); // Remove all non-digit characters and ensure 'T' is at the start
  } else {
    value = "T" + value.slice(1).replace(/[^0-9]/g, ""); // Keep 'T' at the start and remove all non-digit characters from the rest
  }

  // Format the ID according to the specified pattern
  let formattedId = value.slice(0, 1); // 'T'

  if (value.length > 1) formattedId += `-${value.slice(1, 5)}`;
  if (value.length > 5) formattedId += `-${value.slice(5, 9)}`;
  if (value.length > 9) formattedId += `-${value.slice(9, 12)}`;
  if (value.length > 12) formattedId += `-${value.slice(12, 13)}`;

  return formattedId;
};

export const formatNidaId = (value) => {
  // Remove all non-digit characters
  const digits = value.replace(/\D/g, "");

  // Format the ID according to the specified pattern
  if (digits.length <= 8) {
    return digits;
  } else if (digits.length <= 13) {
    return `${digits.slice(0, 8)}-${digits.slice(8)}`;
  } else if (digits.length <= 18) {
    return `${digits.slice(0, 8)}-${digits.slice(8, 13)}-${digits.slice(13)}`;
  } else {
    return `${digits.slice(0, 8)}-${digits.slice(8, 13)}-${digits.slice(
      13,
      18
    )}-${digits.slice(18, 20)}`;
  }
};
