import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  Typography,
  Box,
  CircularProgress,
  Container,
  Paper,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import swal from "sweetalert";
import { domain } from "../auth/domain";
import OrciBanner from "../components/OrciBanner";
import decodeJwtToken from "../auth/decodeJwtToken";
import { useBranch } from "../contexts/BranchContext";
import bgImg from "../../src/assets/img/pesatz1-Photoroom.png";
import "./Login.css";

function Login({ onLogin }) {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const { branch, setBranch } = useBranch();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowSpinner(true);

    try {
      const response = await fetch(domain + "jwt/jwt/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: username,
          password,
        }),
      });

      setShowSpinner(false);

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("accessToken", data.access);
        localStorage.setItem("refreshToken", data.refresh);
        const password_reset_required = decodeJwtToken(
          data.access
        ).password_reset_required;

        onLogin();
        swal({
          text: "Successful Login!",
          icon: "success",
          title: "Auth",
          timer: 2000,
          buttons: false,
        }).then(() => {
          setUsername("");
          setPassword("");
          const user = decodeJwtToken(data.access);
          const allBranch = { id: "", name: "All" };
          const defaultBranch = ["General Manager", "Admin"].includes(
            user?.group?.name
          )
            ? allBranch
            : user?.branch;
          setBranch(defaultBranch);

          password_reset_required
            ? navigate("/account-activation")
            : navigate("/dashboard");
        });
      } else {
        if (response.status === 401) {
          swal({
            title: "Oops",
            text: "Invalid Credentials",
            icon: "error",
            timer: 2000,
            buttons: false,
          });
        } else {
          const data = await response.json();
          console.error("Non-401 error:", data);
          swal({
            title: "Error",
            text: "Something went wrong. Please try again later.",
            icon: "error",
            timer: 2000,
            buttons: false,
          });
        }
      }
    } catch (error) {
      setShowSpinner(false);
      console.error(error);
      swal({
        title: "Network Error",
        text: "Please check your internet connection",
        icon: "error",
        timer: 2000,
        buttons: false,
      });
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background:
          "linear-gradient(270deg, #ff7e5f, #feb47b, #86a8e7, #91eae4)",
        backgroundSize: "400% 400%",
        animation: "gradientAnimation 12s ease infinite",
        "@keyframes gradientAnimation": {
          "0%": { backgroundPosition: "0% 50%" },
          "50%": { backgroundPosition: "100% 50%" },
          "100%": { backgroundPosition: "0% 50%" },
        },
      }}
    >
      <Container maxWidth="md">
        <Paper
          // className="inner"
          elevation={6}
          sx={{
            padding: "2rem",
            boxShadow: "0 10px 25px 0 rgba(0, 0, 0, 0.2)",
            borderRadius: "15px",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            backdropFilter: "blur(10px)",

            background: "rgba(255, 255, 255, 0.5)",
          }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              width: { xs: "100%", md: "50%" },
              paddingRight: { xs: 0, md: "2rem" },
              textAlign: "center",
            }}
          >
            <Box sx={{ mb: 3 }}>
              <OrciBanner />
            </Box>

            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: "bold", color: "#333" }}
            >
              Welcome Back!
            </Typography>

            <FormControl fullWidth sx={{ mb: 2 }} variant="outlined">
              <InputLabel htmlFor="outlined-email">Email</InputLabel>
              <OutlinedInput
                id="outlined-email"
                type="email"
                required
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                }
                label="Email"
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password *
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                required
                onChange={(event) => setPassword(event.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password *"
              />
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              sx={{
                mt: 3,
                height: "50px",
                fontSize: "16px",
                borderRadius: "25px",
              }}
              disabled={showSpinner}
            >
              {showSpinner ? <CircularProgress size={24} /> : "SIGN IN"}
            </Button>
          </Box>

          {/* Right Image */}
          <Box
            sx={{
              width: { xs: "110%", md: "60%" },
              mt: { xs: 3, md: 0 },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={bgImg}
              alt="Background"
              style={{
                width: "90%",
                height: "80%",
                // opacity: "80%",
                borderRadius: "15px",
                objectFit: "cover",
              }}
            />
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}

export default Login;
