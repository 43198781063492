import React, { useEffect, useState } from "react";
import decodeJwtToken from "../auth/decodeJwtToken";
import userImg from "../assets/img/user.jpg";
import { domain } from "../auth/domain";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import swal from "sweetalert";
import LinearProgress from "@mui/material/LinearProgress";

function Settings() {
  const accessToken = localStorage.getItem("accessToken");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [btnName, setBtnName] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  let user = decodeJwtToken(accessToken);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (btnName === "UPDATE-PASSWORD") {
      setShowSpinner(true);
      const response = await fetch(
        domain + "api-p/users-employees/change-password/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            old_password: oldPassword,
            new_password: newPassword,
          }),
        }
      );

      if (response.ok) {
        swal({ title: "Password succesful updated", icon: "success" });
        setOldPassword("");
        setNewPassword("");
        setShowSpinner(false);
      } else {
        const data = await response.json();
        if (data) {
          swal({ icon: "error", title: data.error });
        } else {
          swal({ title: "Error occured", icon: "error" });
        }
        setShowSpinner(false);
      }
    }
  };

  useEffect(() => {
    setShowSpinner(true);
    fetch(domain + "api-p/users-employees/" + user.user_id + "/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setName(data.first_name + " " + data.last_name);
        setEmail(data.email);
        setUsername(data.username);
        setShowSpinner(false);
      });
  }, []);

  return (
    <div class="card">
      {showSpinner && <LinearProgress />}
      <div class="card-body">
        <div class="d-flex flex-column align-items-center text-center">
          <img
            src={userImg}
            alt="user image"
            class="rounded-circle"
            width="150"
          />
          <div class="mt-3">
            <h4>Full Name: {name}</h4>
            <p class="text-secondary mb-1">Email: {email}</p>
            <p class="text-secondary mb-1">Username: {username}</p>
            {/* <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="outlined-search"
                  label="username"
                  type="search"
                  //   defaultValue={username}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </Box>*/}

            {/* <button class="btn btn-block btn-primary">UPDATE USERNAME</button> */}
          </div>
          <div class="mt-3">
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  id="outlined-search"
                  label="current password"
                  type="password"
                  //   defaultValue={username}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <TextField
                  id="outlined-search"
                  label="new password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              {showSpinner == false && (
                <button
                  onMouseOver={() => setBtnName("UPDATE-PASSWORD")}
                  onClick={handleSubmit}
                  class="btn btn-block btn-primary"
                >
                  UPDATE
                </button>
              )}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Settings;
