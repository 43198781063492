// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#800b33", // Customize primary color as needed
    },
    // Add more palette colors if required
  },
  // Add more theme configurations as needed
});

export default theme;
