import { Link, NavLink } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import '../shared/Form.css';
import { domain } from '../auth/domain';
import CustomLoader from '../components/CustomLoader';
import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import '../shared/Form.css';
import swal from 'sweetalert';
import Select from 'react-select';
import decodeJwtToken from '../auth/decodeJwtToken';
import userImg from '../assets/img/user.jpg';
import moment from 'moment';




function Payments() {
    const accessToken = localStorage.getItem('accessToken');

    let user = decodeJwtToken(accessToken);
    const [payments, setPayments] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const [filteredPayments, setFilteredPayments] = useState(payments);
    const [showSpinner, setShowSpinner] = useState(false);
    const [patients, setPatients] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // handle react page visibility based on open/clode of popup
    const handleToggleModal = (modalState) => {
        setIsModalOpen(modalState);
    };

    // Implement a function to handle the search functionality
    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        const filtered = payments.filter(
            (payment) =>
                payment.first_name.toLowerCase().includes(query) ||
                payment.last_name.toLowerCase().includes(query)
            // staff.user_type.toLowerCase().includes(query)
        );
        setFilteredPayments(filtered);
    };
    // Implement a function to handle the page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Use the currentPage and perPage state variables to calculate 
    // the slice of applications to be displayed on the current page


    // Function to format the date in a human-readable way
    const formatDate = (date) => {
        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');
        const billDate = moment(date);

        if (billDate.isSame(today, 'd')) {
            return 'TODAY';
        } else if (billDate.isSame(yesterday, 'd')) {
            return 'YESTERDAY';
        } else {
            return billDate.format('MMM D, YYYY');
        }
    };

    const offset = currentPage * perPage;
    const paginatedPayments = filteredPayments.slice(
        offset,
        offset + perPage
    );



    // Fetch Payments
    const fetchPayments = () => {
        setShowSpinner(true);
    
        fetch(domain + "api/payments/", {
            method: 'GET',
            headers: {
                'Authorization': 'JWT ' + accessToken,
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setPayments(data);
                setFilteredPayments(data);
                setShowSpinner(false);
    
                // Fetch related bill or invoice information for each payment
                data.forEach((payment) => {
                    const url = payment.content_type === 56 ? `api/bills/${payment.object_id}/` : `api/invoices/${payment.object_id}/`;
                    fetch(domain + url, {
                        method: 'GET',
                        headers: {
                            'Authorization': 'JWT ' + accessToken,
                        }
                    })
                    .then((response) => response.json())
                    .then((billOrInvoice) => {
                        // Update payment with related bill or invoice details
                        payment.billOrInvoiceDetails = billOrInvoice;
                        setPayments((prevPayments) => [...prevPayments]);  // Trigger a state update to rerender the component
                    })
                    .catch((error) => console.error(error));
                });
            })
            .catch((error) => console.error(error))
            .finally(() => setShowSpinner(false));
    };
    

    useEffect(() => {
        setShowSpinner(true);
        fetchPayments();
        // Fetch patients
        fetch(domain + "api/patients/", {
            method: 'GET',
            headers: {
                'Authorization': 'JWT ' + accessToken,
            }
        })
            .then(response => response.json())
            .then(data => {
                setPatients(data);

            })
            .catch(error => console.error(error))
            .finally(() => { setShowSpinner(false) });


        // Fetch service categories
        fetch(domain + "api/bills/", {
            method: 'GET',
            headers: {
                'Authorization': 'JWT ' + accessToken,
            }
        })
            .then(response => response.json())
            .then(data => {
                setCategories(data);

            })
            .catch(error => console.error(error))
            .finally(() => { setShowSpinner(false) });


    }, []);




    // Function to get patient details based on Bill id
    const getPatient = (patientId) => {
        const patient = patients.find(patient => patient.id === patientId);
        return patient ? patient.first_name + ' ' + patient.last_name : '';
    };

    // Function to get serviice category name based on category id
    const getBill = (billId, other) => {
        if(other=='patient')
        {

        }
        const category = categories.find(category => category.id === billId);
        return category ? category.name : '';
    };

    return (
        <div className="row">
            <h3>Payments</h3>

            <input
                style={{ borderRadius: '10px' }}
                className='form-control'
                type="text"
                placeholder="Search..."

                onChange={handleSearch}
            />
            <table id='app' className='datatable table table-striped'>

                <thead>
                    <tr>
                        <th>#</th>
                        <th>Reference Number</th>
                        <th>Patient</th>
                        <th>Payment Method</th>
                        <th>Paid Date</th>
                        <th>Amount</th>
                        
                
                    </tr>
                </thead>
                <tbody>
                    {(() => {
                        try {
                            return paginatedPayments.map((payment, index) =>
                                <tr key={payment.id}>

                                    <td>{index + 1}</td>
                                    
                                
        
                                    <td>{payment.content_type === 56 ? 'B' + payment.object_id : 'Inv' + payment.object_id}</td>
                                    <td>{payment.billOrInvoiceDetails ? getPatient(payment.billOrInvoiceDetails.patient) : ''}</td>
                                    <td>{payment.payment_method}</td>

                                    <td>

                                        {formatDate(payment.paid_date) === 'TODAY' ? <span class="custom-badge status-blue">TODAY</span> :
                                            formatDate(payment.paid_date) === 'YESTERDAY' ? <span class="custom-badge status-orange">YESTERDAY</span> :
                                                <span class="custom-badge">{formatDate(payment.paid_date)}</span>}
                                    </td>


                                    <td>{payment.billOrInvoiceDetails ? payment.billOrInvoiceDetails.amount+' /=Tsh' : ''}</td>



                                </tr>
                            )
                        } catch (error) {
                            console.log(error);
                            return <tr><span>Network Problem.Please check your Internet</span></tr>;
                        }
                    })()
                    }


                </tbody>
            </table>

            {!isModalOpen && (
                <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageCount={Math.ceil(filteredPayments.length / perPage)}
                    onPageChange={handlePageChange}
                    containerClassName="pagination"
                    activeClassName="active"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                />
            )}

            {/* {showSpinner && <CustomLoader />} */}
        </div>



    );
}

export default Payments;
