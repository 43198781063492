import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../shared/Form.css";
import { domain } from "../auth/domain";
import CustomLoader from "../components/CustomLoader";
import PatientsPopup from "../components/PurchasePopup";
import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import "../shared/Form.css";
import swal from "sweetalert";
import Select from "react-select";
import decodeJwtToken from "../auth/decodeJwtToken";
import userImg from "../assets/img/user.jpg";
import { LinearProgress } from "@mui/material";
import moment from "moment";
import SaleDialog from "../components/PurchaseDialog";
import formatCurrency from "../shared/formatCurrency";
import NoRecords from "../shared/NoRecords";

function Purchases() {
  const accessToken = localStorage.getItem("accessToken");
  const [sales, setSales] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [filteredSales, setFilteredSales] = useState(sales);
  const [showSpinner, setShowSpinner] = useState(false);
  const [insurances, setInsurances] = useState([]);
  const [cities, setCities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [purchaseReturned, setPurchaseReturned] = useState([]);
  const [refreshTable, setRefreshTable] = useState("");

  // handle react page visibility based on open/clode of popup
  const handleToggleModal = (modalState) => {
    setIsModalOpen(modalState);
  };
  const handleTableRefresh = (info) => {
    setRefreshTable(info);
  };
  // Implement a function to handle the search functionality
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = sales.filter(
      (patient) =>
        patient.total_amount.toLowerCase().includes(query) ||
        patient.supplier.full_name.toLowerCase().includes(query) ||
        patient.purchase_date.toLowerCase().includes(query)
    );
    setFilteredSales(filtered);
  };
  // Implement a function to handle the page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const formatDate = (date) => {
    const today = moment().startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");
    const billDate = moment(date);

    if (billDate.isSame(today, "d")) {
      return "TODAY";
    } else if (billDate.isSame(yesterday, "d")) {
      return "YESTERDAY";
    } else {
      return billDate.format("MMM D, YYYY");
    }
  };

  // Use the currentPage and perPage state variables to calculate
  // the slice of applications to be displayed on the current page

  const offset = currentPage * perPage;
  const paginatedPatients = filteredSales.slice(offset, offset + perPage);

  // Fetch Current patients
  const fetchSales = () => {
    setShowSpinner(true);

    fetch(domain + "api/purchases-items/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSales(data);
        setFilteredSales(data);
        setShowSpinner(false);
      })
      .catch((error) => console.error(error))
      .finally(() => setShowSpinner(false));
  };

  useEffect(() => {
    setShowSpinner(true);
    fetchSales();
    fetch(domain + "api/purchases-return/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setPurchaseReturned(data);
      });
  }, [refreshTable]);

  // Function to get insurance provider based on insurance id
  const getLocation = (insuranceId) => {
    const insurance = insurances.find(
      (insurance) => insurance.id === insuranceId
    );
    return insurance ? insurance.name : "";
  };

  // Function to get city name based on city id
  const getReturn = (saleId) => {
    const sale = purchaseReturned.find((sale) => sale.purchase === saleId);
    return sale ? sale : "";
  };

  return (
    <div className="row">
      {showSpinner && <LinearProgress />}
      <h3>Purchases</h3>
      <div class="text-right">
        <a class="">
          <SaleDialog
            onToggleModal={handleToggleModal}
            onRefreshTable={handleTableRefresh}
            iconName={"plus"}
            btnName="ADD"
            btnClass={"primary"}
            formTitle={"Add new purchase"}
          />
        </a>
      </div>
      {/* <input
        style={{ borderRadius: "10px" }}
        className="form-control"
        type="text"
        placeholder="Search..."
        onChange={handleSearch}
      /> */}
      <table
        id="app"
        className="datatable table table-striped"
        style={{
          boxShadow: "0 10px 15px 0 rgba(0, 0, 0, 0.2)",
          borderRadius: "7px",
        }}
      >
        <thead>
          <tr>
            <th>#</th>
<th>id</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Supplier</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {(() => {
            try {
              return paginatedPatients.map((sale, index) => (
                <tr key={sale.id}>
                  <td>{index + 1}</td>
                  <td>{sale?.txn_id}</td>
                  <td>{formatCurrency(sale.total_amount)}</td>

                  <td>{sale.purchase_date}</td>
                  <td>{sale?.supplier?.full_name}</td>
                  <td>
                    <SaleDialog
                      returnedSale={getReturn(sale.id)}
                      onToggleModal={handleToggleModal}
                      onRefreshTable={handleTableRefresh}
                      iconName={""}
                      btnName="details"
                      btnClass={"dark ml-1"}
                      userId={sale.id}
                    />
                  </td>
                </tr>
              ));
            } catch (error) {
              console.log(error);
              return (
                <div className="col-sm-12">
                  Network Problem.Please check your Internet
                </div>
              );
            }
          })()}
        </tbody>
      </table>
      <NoRecords data={sales} />
      {/* <table id="app" className="datatable table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Product</th>
            <th>Quantity</th>
            <th>unit price</th>
            <th>Total</th>
            <th>Date</th>

            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {(() => {
            try {
              return paginatedPatients.map((sale, index) => (
                <tr key={sale.id}>
                  <td>{index + 1}</td>
                  <td>{sale.product.name}</td>
                  <td>{sale.quantity_purchased} unit(s)</td>
                  <td>{sale.unit_price} Tsh</td>
                  <td>{sale.total_amount} Tsh</td>
                  <td>{sale.purchase_date}</td>

                  <td>
                    {sale.status === "COMPLETED" ? (
                      <span class="custom-badge status-blue">
                        {sale.status}
                      </span>
                    ) : (
                      <span class="custom-badge status-orange">
                        {sale.status}
                      </span>
                    )}
                  </td>
                  <td className="text-right">
                    <PatientsPopup
                      disabled={sale.status == "RETURNED" ? true : false}
                      onToggleModal={handleToggleModal}
                      iconName={"refresh"}
                      btnName="return"
                      btnClass={"dark"}
                      formTitle={"Return sale"}
                      userId={sale.id}
                    />
                    <PatientsPopup
                      returnedPurchase={getReturn(sale.id)}
                      disabled={sale.status == "COMPLETED" ? true : false}
                      onToggleModal={handleToggleModal}
                      iconName={"eye"}
                      btnName="view"
                      btnClass={"danger ml-1"}
                      userId={sale.id}
                    />
                  </td>
                </tr>
              ));
            } catch (error) {
              console.log(error);
              return (
                <tr>
                  <td>Network Problem.Please check your Internet</td>
                </tr>
              );
            }
          })()}
        </tbody>
      </table> */}

      {!isModalOpen && (
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageCount={Math.ceil(filteredSales.length / perPage)}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          nextClassName="page-item"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
        />
      )}

      {/* {showSpinner && <LinearProgress/>} */}
    </div>
  );
}

export default Purchases;
