import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./Popup.css";
import { domain } from "../auth/domain";
import swal from "sweetalert";
import confirmImage from "../assets/img/sent.png";
import { event, get } from "jquery";
import decodeJwtToken from "../auth/decodeJwtToken";
import { Tabs, Tab, Form } from "react-bootstrap";
import CustomLoader from "./CustomLoader";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TextField } from "@mui/material";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Autocomplete } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RoleDialog({
  btnName,
  btnClass,
  iconName,
  formTitle,
  userId,
  onToggleModal,
  onRefreshTable,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    onToggleModal(true);
    handleEditMOde();
  };

  const handleRefresh = (data) => {
    onRefreshTable(data);
  };

  const handleClose = () => {
    setOpen(false);
    onToggleModal(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);

  const [cities, setCities] = useState([]);

  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPersmissions] = useState([]);
  const [patientInsuranceId, setSelectedPatientInsurance] = useState(0);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [category, setCategory] = useState("");
  const [lowStockThreshold, setLowStockThreshold] = useState("");
  const [location, setLocation] = useState("");
  const [cardNumber, setCardNumber] = useState(0);
  const [memberShipNumber, setMemberShipNumber] = useState(0);
  // use this for update insurance data and not insurance provider data
  const [insuranceId, setInsuranceId] = useState("");

  const [firstVisit, setFirstVisit] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [invoiceCategory, setInvoiceCategory] = useState("");
  const [showLabel, setShowLabel] = useState(false);

  const [nextOfKinFirstName, setNextOfKinFirstName] = useState("");
  const [nextOfKinLastName, setNextOfKinLastName] = useState("");
  const [nextOfKinMiddleName, setNextOfKinMiddleName] = useState("");

  const [nextOfKinGender, setNextOfKinGender] = useState("");
  const [nextOfKinPhone, setNextOfKinPhone] = useState("");
  const [productFieldSetting, setProductFieldSetting] = useState({});
  const [use_description, setUseDescription] = useState(false);
  const [use_expiry_date, setUseExpiryDate] = useState(false);
  const [use_batch_number, setUseBatchNumber] = useState(false);
  const [use_category, setUseCategory] = useState(false);
  const [doctorId, setSelectedDoctor] = useState("");
  const [doctorFee, setDoctorFee] = useState("");
  const [doctorFees, setDoctorFees] = useState([]);

  const [groups, setGroups] = useState([]);
  const [groupId, setSelectedGroupId] = useState(0);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");

  const [activeTab, setActiveTab] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);

  const [invoiceItems, setInvoiceItems] = useState([
    {
      id: 1,
      name: "",
      category: "",
      low_stock_threshold: "",
      description: "",
      batch_number: "",
      expiry_date: "",
      location: 1,
    },
  ]);
  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const addInvoiceItem = () => {
    const highestId = Math.max(...invoiceItems.map((item) => item.id));
    setInvoiceItems([
      ...invoiceItems,
      {
        id: highestId + 1,
        name: "",
        category: "",
        low_stock_threshold: "",
        description: "",
        sell_price: "",
        bulk_quantity: "",
        batch_number: "",
        expiry_date: "",
        location: 1,
      },
    ]);
  };

  const removeInvoiceItem = (id) => {
    setInvoiceItems(invoiceItems.filter((item) => item.id !== id));
  };

  const handleInputChange = (id, field, value) => {
    setInvoiceItems(
      invoiceItems.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    onToggleModal(!isOpen);
  };

  const handleNext = () => {
    if (activeTab === 0) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    } else if (activeTab === 1) {
      if (productName === "" || productName === "") {
        swal({ icon: "info", title: "Please fill in the required fields" });
        return;
      }
    }

    setActiveTab(activeTab + 1);
  };
const handleEditMOde = ()=>
  {

    // fetch current Product
    if (btnName === "EDIT") {
      fetch(domain + "api-p/groups/" + userId + "/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setSelectedPersmissions(data?.permissions)
        });
      // Fetch groups

      fetch(domain + "api-p/groups/", {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setGroups(data);
        })
        .catch((error) => console.error(error));

        fetch(domain + "api-p/permissions/", {
          method: "GET",
          headers: {
            Authorization: "JWT " + accessToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setPermissions(data);
          })
          .catch((error) => console.error(error));
    }

  }
  useEffect(() => {
    



    // fetch(domain + "api/profile/", {
    //   method: "GET",
    //   headers: {
    //     Authorization: "JWT " + accessToken,
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setLocation(data.filter((data) => data.user === me.user_id));
    //   });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (btnName === "ADD") {
      const response = await fetch(domain + "api/products/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + accessToken,
        },
        body: JSON.stringify([...invoiceItems]),
      });

      if (response.ok) {
        swal({ title: "User added", icon: "success" });
        // setInvoiceItems([
        //   {
        //     id: 1,
        //     name: "",
        //     category: "",
        //     low_stock_threshold: "",
        //     description: "",
        //     batch_number: "",
        //     expiry_date: "",
        //     location: 1,
        //   },
        // ]);
        handleClose();
        // onRefreshTable("added");
        handleRefresh(Math.random());
      } else {
        swal({ title: "Error occured", icon: "error" });
      }
    }

    if (btnName === "EDIT") {
      const response = await fetch(domain + "api-p/groups/" + userId + "/", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + accessToken,
        },
        body: JSON.stringify({
        permissions:selectedPermissions
        }),
      });

      if (response.ok) {
        swal({
          title: "Role updated",
          icon: "success",
        });
        handleClose();
        // onRefreshTable("update");
        handleRefresh(Math.random());
      } else {
        swal({ title: "Error occured", icon: "error" });
      }
    }

    if (btnName === "SETTING") {
      const response = await fetch(
        domain + "api/product-field-settings/" + 1 + "/",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            use_description,
            use_expiry_date,
            use_batch_number,
            use_category,
          }),
        }
      );

      if (response.ok) {
        swal({
          title: "Product settings successful updated",
          icon: "success",
        });
        handleClose();
        // onRefreshTable("update");
        handleRefresh(Math.random());
      } else {
        swal({ title: "Error occured", icon: "error" });
      }
    }

    if (btnName === "DELETE") {
      togglePopup();

      const response = await fetch(domain + "api/products/" + userId + "/", {
        method: "DELETE",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      });

      if (response.ok) {
        swal({ title: "Product successful deleted", icon: "success" });
        handleRefresh("delete");
      } else {
        swal({ title: "Something went wrong", icon: "error" });
      }
    }

    if (btnName === "Dr.CONSULTATION") {
      if (doctorId === "") {
        swal({ icon: "info", title: "Please select Dr" });
        return;
      }

      if (paymentMethod === "cash") {
        const response = await fetch(domain + "api/bills/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            amount: doctorFee,
            patient: userId,
            category: 1,
          }),
        });

        if (response.ok) {
          swal({ title: "Patient successful sent to Dr", icon: "success" });
        } else {
          swal({
            title: "Error occured",
            text: "Please try again or Consult System admin",
            icon: "error",
          });
        }
      }

      if (paymentMethod === "insurance") {
        const response = await fetch(domain + "api/invoices/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + accessToken,
          },
          body: JSON.stringify({
            amount: doctorFee,
            patient: userId,
            category: 1,
          }),
        });

        if (response.ok) {
          swal({ title: "Patient successful sent to Dr", icon: "success" });
        } else {
          swal({
            title: "Error occured",
            text: "Please try again or Consult System admin",
            icon: "error",
          });
        }
      }
    }
  };

  // Function to get city name based on city id
  const getLocation = (cityId) => {
    const city = cities.find((city) => city.id === cityId);
    return city ? city.name : "";
  };

  const getFee = (doctorId) => {
    // Check if doctorFees is available and not empty
    if (doctorFees && doctorFees.length > 0) {
      const fee = doctorFees.find((fee) => fee.doctor == doctorId);
      return fee ? fee.consultation_fee : "";
    }
    return ""; // Return empty string if doctorFees is not yet available or empty
  };

  const getInvoiceCategory = (categoryId) => {
    const category = invoiceCategory.find(
      (category) => category.id === categoryId
    );
    return category ? category.name : "";
  };

  // Handler for doctor selection
  const handleDoctorChange = (event) => {
    const selectedDoctorId = event.target.value;

    setSelectedDoctor(selectedDoctorId);
    setDoctorFee(getFee(selectedDoctorId));
  };

  return (
    <React.Fragment>
      <button
        title={btnName}
        className={"btn btn-outline-" + btnClass}
        onClick={handleClickOpen}
      >
        <i className={"fa fa-" + iconName + " m-r-5"}></i>
        {btnName}
      </button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {btnName === "DELETE" || <DialogTitle>{groups.find((grp)=>grp.id === userId)?.name}'s Permissions</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            {(btnName === "ADD" || btnName === "EDIT") && (
              <>
                <div className="row">
                  <div className="col-lg-12">
           
                  <FormControl  sx={{ m: 1 }}>
                        
                        <Autocomplete
       multiple
       
       id="checkboxes-tags-demo"
       options={permissions}
       value={ 
     
       // ):
       selectedPermissions}
   
       onChange={(e,v)=>setSelectedPersmissions(v)
       }
       disableCloseOnSelect
       getOptionLabel={(option) => option.name}
       renderOption={(props, option, { selected }) => (
         <li {...props}>
           <Checkbox
             icon={icon}
             checkedIcon={checkedIcon}
             style={{ marginRight: 8 }}
             checked={selected}
           />
           {option.name}{selected}
         </li>
       )}
       style={{ width: 550 }}
       renderInput={(params) => (
         <TextField {...params} label="Permissions" placeholder="Role permissions" />
       )}
     />
     </FormControl>
                  
                    {/* <FormControl fullWidth sx={{ m: 1 }}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        // value={claimYear}
                        label="Role"
                        required
                        value={groupId}
                        onChange={(event) =>
                          setSelectedGroupId(event.target.value)
                        }
                      >
                        {(() => {
                          try {
                            return groups.map((group) => (
                              <MenuItem value={group.id}>{group.name}</MenuItem>
                            ));
                          } catch (error) {
                            console.log(error);
                            return (
                              <option>
                                Network Problem.Please check your Internet
                              </option>
                            );
                          }
                        })()}
                      </Select>
                    </FormControl> */}
                  </div>
                </div>
              </>
            )}
            {btnName === "SETTING" && (
              <>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setUseDescription(!use_description)}
                        checked={use_description}
                      />
                    }
                    label="Use Description"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setUseExpiryDate(!use_expiry_date)}
                        checked={use_expiry_date}
                      />
                    }
                    label="Use Expire Date"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setUseBatchNumber(!use_batch_number)}
                        checked={use_batch_number}
                      />
                    }
                    label="Use Bacth Number"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setUseCategory(!use_category)}
                        checked={use_category}
                      />
                    }
                    label="Use Category"
                  />
                </FormGroup>
              </>
            )}

            {btnName === "DELETE" && (
              <div class="text-center">
                <img src={confirmImage} alt="" width="50" height="46" />
                <h3>Are you sure want to Delete this product ?</h3>
                <p> This will even delete the associated Sales and Purchases</p>
                <div class="m-t-20"> </div>
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-light m-1 float-right"
            onClick={handleClose}
          >
            CLOSE
          </button>
          {btnName === "DELETE" ? (
            <button
              type="submit"
              className="btn m-1 float-right btn-white"
              onClick={handleSubmit}
            >
              DELETE
            </button>
          ) : (
            <button
              type="submit"
              className="btn m-1 float-right btn-primary"
              onClick={handleSubmit}
            >
              SUBMIT
            </button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
