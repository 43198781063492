import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "./Popup.css";
import { domain } from "../auth/domain";
import swal from "sweetalert";
import microfinanceLogo from "../assets/img/logo-Photoroom.png";
import userImg from "../assets/img/user.jpg";
import { event, get } from "jquery";
import decodeJwtToken from "../auth/decodeJwtToken";
import { Tabs, Tab, Form } from "react-bootstrap";
import CustomLoader from "./CustomLoader";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Upload from "@mui/icons-material/Upload";
import { useNavigate } from "react-router-dom";
import { useBranch } from "../contexts/BranchContext";
import formatCureny3F from "../shared/formatCurrency3F";
import {
  Tooltip,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import removeCommas from "../shared/removeCommas";
import { Paper, Grid } from "@mui/material";
import { styled } from "@mui/system";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  formatPhoneNumber,
  formatNidaId,
  formatVoterId,
} from "../shared/formatter";
import formatCurrency from "../shared/formatCurrency";
import LinearProgress from "@mui/material/LinearProgress";
import nmb from "../assets/img/nmb-logo.webp";
import { formatDate } from "@canvasjs/charts";
import statementDetailsToPDF from "./statementDetailsToPDF";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: 10,
  background: "#f9f9f9",
  border: "1px solid #e0e0e0",
  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
}));

const Title = styled(Typography)({
  fontWeight: "bold",
  color: "#800b33",
  whiteSpace: "nowrap",
});
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: "#800b33",
  marginBottom: theme.spacing(1),
}));

const AmountContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

const AvatarContainer = styled(Box)(({ theme }) => ({
  border: `4px solid ${theme.palette.primary.main}`,
  borderRadius: "50%",
  overflow: "hidden",
}));
// Example usage with mock data
const customer = {
  name: "abc",
  mobile: "+255 700 123 456",
  residence: "Dar es Salaam",
  imageUrl: userImg,
};

export default function CustomerStatementsDialog({
  btnName,
  btnClass,
  iconName,
  formTitle,
  userId,
  // onToggleModal,
  popupData,
  onRefreshTable,
  disabled,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    // onToggleModal && onToggleModal(true);
    handleEditMode();
  };

  const handleRefresh = (data) => {
    onRefreshTable(data);
  };

  const handleClose = () => {
    setOpen(false);
    // onToggleModal(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const accessToken = localStorage.getItem("accessToken");
  const me = decodeJwtToken(accessToken);
  const navigate = useNavigate();
  const { branch, setBranch } = useBranch();
  const [repayments, setRepayments] = useState([]);
  const [patientCityId, setSelectedPatientCity] = useState("");
  const [patientInsuranceId, setSelectedPatientInsurance] = useState(0);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [category, setCategory] = useState("");
  const [lowStockThreshold, setLowStockThreshold] = useState("");
  const [location, setLocation] = useState("");
  const [cardNumber, setCardNumber] = useState(0);
  const [memberShipNumber, setMemberShipNumber] = useState(0);
  // use this for update insurance data and not insurance provider data
  const [insuranceId, setInsuranceId] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [firstVisit, setFirstVisit] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [invoiceCategory, setInvoiceCategory] = useState("");
  const [showLabel, setShowLabel] = useState(false);

  const [nextOfKinFirstName, setNextOfKinFirstName] = useState("");
  const [nextOfKinLastName, setNextOfKinLastName] = useState("");
  const [nextOfKinMiddleName, setNextOfKinMiddleName] = useState("");

  const [invoice, setInvoice] = useState([]);
  const [nextOfKinPhone, setNextOfKinPhone] = useState("");
  const [doctors, setDoctors] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [quantity, setQuantity] = useState("");
  const [reason, setReason] = useState([]);
  const [cost, setCost] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const [error, setError] = useState({
    id_type: "",
    id_number: "",
    full_name: "",
    email: "",
    phone_number: "",
    address: "",
    photo: null,
    gender: "M",
    marital_status: "S",
    residence: "",
    loan_category: "",
    repayment_method: "",
    bank_reference_number: "",
    branch_id: me?.branch?.id,
  });
  const [customerData, setCustomerData] = useState({
    id_type: "",
    id_number: "",
    full_name: "",
    email: "",
    phone_number: "",
    address: "",
    photo: null,
    gender: "M",
    marital_status: "S",
    residence: "",
    loan_category: "",
    repayment_method: "",
    bank_reference_number: "",
    bank_account_number: "",
    branch_id: me?.branch?.id,
  });
  const [guarantorData, setGuarantorData] = useState({
    id_type: "",
    id_number: "",
    full_name: "",
    phone_number: "+255",
    address: "",
    photo: null,
    gender: "M",
  });

  const [loanData, setLoanData] = useState({
    amount: "",
    loan_category: "",
    repayment_method: "",
    customer_id: userId,
    loan_contract: null,
    branch_id: me?.branch?.id,
    status:
      me?.group?.name !== "Cashier"
        ? "APPROVED_BY_CASHIER_AND_WAIT_FOR_LOAN_OFFICER"
        : "APPROVED_BY_CASHIER_AND_WAIT_FOR_LOAN_OFFICER",
  });
  const handleInputChange = (e) => {
    const { name, value: rawValue } = e.target;

    let formattedValue = rawValue;

    // Handle phone number formatting separately
    if (name === "phone_number") {
      formattedValue = formatPhoneNumber(rawValue);
      setCustomerData({
        ...customerData,
        [name]: formattedValue,
      });
      return; // Exit the function early since phone_number change does not need further processing
    }

    // fullnames into uppercases changes
    if (name === "full_name") {
      setCustomerData({
        ...customerData,
        full_name: rawValue.toUpperCase(),
      });
      return; // Exit the function early since id_type change does not need further processing
    }

    // Clear id_number if id_type changes
    if (name === "id_type") {
      setCustomerData({
        ...customerData,
        id_type: rawValue,
        id_number: "", // Clear id_number field
      });
      return; // Exit the function early since id_type change does not need further processing
    }

    // Format id_number based on id_type
    if (name === "id_number") {
      formattedValue =
        customerData.id_type === "NIDA"
          ? formatNidaId(rawValue)
          : customerData.id_type === "VOTER_ID"
          ? formatVoterId(rawValue)
          : rawValue;
    }

    setCustomerData({
      ...customerData,
      [name]: formattedValue,
    });
  };
  const handleGuarantorInputChange = (e) => {
    const { name, value: rawValue } = e.target;
    let formattedValue = rawValue;
    // fullnames into uppercases changes
    if (name === "full_name") {
      setGuarantorData({
        ...guarantorData,
        full_name: rawValue.toUpperCase(),
      });
      return; // Exit the function early since id_type change does not need further processing
    }

    // Handle phone number formatting separately
    if (name === "phone_number") {
      formattedValue = formatPhoneNumber(rawValue);
      setGuarantorData({
        ...guarantorData,
        [name]: formattedValue,
      });
      return; // Exit the function early since phone_number change does not need further processing
    }

    // Clear id_number if id_type changes
    if (name === "id_type") {
      setGuarantorData({
        ...guarantorData,
        id_type: rawValue,
        id_number: "", // Clear id_number field
      });
      return; // Exit the function early since id_type change does not need further processing
    }

    // Format id_number based on id_type
    if (name == "id_number") {
      formattedValue =
        guarantorData.id_type === "NIDA"
          ? formatNidaId(rawValue)
          : guarantorData.id_type === "VOTER_ID"
          ? formatVoterId(rawValue)
          : rawValue;
    }
    setGuarantorData({
      ...guarantorData,
      [name]: formattedValue,
    });
  };

  const handleLoanInputChange = (e) => {
    const { name, value: rawValue } = e.target;

    let formattedValue = rawValue;
    // Handle amount formatting separately
    if (name === "amount") {
      formattedValue = formatCureny3F(rawValue);
      setLoanData({
        ...loanData,
        amount: formattedValue,
      });
      return; // Exit the function early since phone_number change does not need further processing
    }

    // Handle amount formatting separately
    if (name === "loan_category") {
      setLoanData({
        ...loanData,
        loan_category: formattedValue,
        repayment_method: formattedValue === "EMERGENCY" ? "MONTHLY" : "", // Reset repayment method for other categories
      });
      return; // Exit the function early since phone_number change does not need further processing
    }

    setLoanData({
      ...loanData,
      [name]: rawValue,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    const file = files[0];

    // Check if the selected file is an image
    if (file && file.type.startsWith("image/")) {
      setCustomerData({
        ...customerData,
        [name]: file,
      });
    } else {
      swal({
        title: "Error",
        text: "Please select valid image file.",
        timer: 1300,
        buttons: false,
        icon: "error",
      });
      setCustomerData({
        ...customerData,
        [name]: null,
      });
    }
  };

  const handleGuarantorFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    // Check if the selected file is an image
    if (file && file.type.startsWith("image/")) {
      setGuarantorData({
        ...guarantorData,
        [name]: file,
      });
    } else {
      swal({
        title: "Error",
        text: "Please select valid image file.",
        timer: 1300,
        buttons: false,
        icon: "error",
      });
      return;
    }
  };

  const handleLoanFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file && file.type === "application/pdf") {
      setLoanData({
        ...loanData,
        [name]: file,
      });
    } else {
      // Display an error message or handle the invalid file type case
      swal({
        title: "Invalid File",
        text: "Please upload a valid PDF document.",
        icon: "error",
        timer: 2000,
        buttons: false,
      });
    }
  };

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const handleEditMode = () => {
    if (btnName === "VIEW") {
      fetch(domain + "repayments/repayments/?loan=" + popupData?.id, {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const branchId = data.branch?.id;

          setRepayments(data?.results);
        });
    }
    if (btnName === "Invoice") {
      setShowSpinner(true);
      fetch(domain + "nmb/callback/check-invoice/" + userId, {
        method: "GET",
        headers: {
          Authorization: "JWT " + accessToken,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json(); // Proceed only if the response is OK (status 200)
          } else {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }
        })
        .then((data) => {
          setInvoice(data.data);
        })
        .finally(() => setShowSpinner(false));
    }
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    // onToggleModal(!isOpen);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (btnName === "ADD") {
      setShowSpinner(true);
      let hasError = false;

      if (!customerData?.id_number) {
        setError((prevError) => ({
          ...prevError,
          id_number: "ID number is required",
        }));
        hasError = true;
      }

      if (!customerData?.id_type) {
        setError((prevError) => ({
          ...prevError,
          id_type: "ID type is required",
        }));
        hasError = true;
      }
      if (!customerData?.full_name) {
        setError((prevError) => ({
          ...prevError,
          full_name: "Full name is required",
        }));
        hasError = true;
      }

      if (!customerData?.phone_number) {
        setError((prevError) => ({
          ...prevError,
          phone_number: "Phone number is required",
        }));
        hasError = true;
      }

      if (hasError) {
        setShowSpinner(false);
        return; // Prevent submission if there are errors
      }
      setError({
        name: "",
        cost: "",
      }); // Clear errors

      const formData = new FormData();
      for (const key in customerData) {
        if (key !== "photo") {
          formData.append(key, customerData[key]);
        }
      }

      formData.append("guarantor.full_name", guarantorData.full_name);
      formData.append("guarantor.phone_number", guarantorData.phone_number);
      formData.append("guarantor.address", guarantorData.address);
      // formData.append('guarantor.photo', guarantorData.photo);
      formData.append("guarantor.gender", guarantorData.gender);

      const response = await fetch(domain + "customers/customers/", {
        method: "POST",
        headers: {
          Authorization: "JWT " + accessToken,
        },
        body: formData,
      });
      if (response.ok) {
        setShowSpinner(false);
        swal({
          title: "succes",
          text: "Customer added",
          icon: "success",
          timer: 2000,
          buttons: false,
        });
        setActiveStep(0);
        handleClose();
        handleRefresh(Math.random());
      } else {
        const body = await response.json();

        if (body) {
          const errors = body;
          for (const [field, messages] of Object.entries(errors)) {
            messages.forEach((message) => {
              swal({
                title: "Error",
                text: `${message}`,
                icon: "error",
                timer: 3000,
                buttons: false,
              });
            });
          }
        } else {
          setShowSpinner(false);
          swal({
            title: "Error",
            text: "An unexpected error occurred",
            icon: "error",
            timer: 2000,
            buttons: false,
          });
        }
      }
    }

    if (btnName.includes("Apply Loan")) {
      const formData = new FormData();
      for (const key in loanData) {
        if (key === "amount") {
          formData.append(key, removeCommas(loanData[key]));
        } else {
          formData.append(key, loanData[key]);
        }
        if (
          key === "loan_contract" &&
          loanData.loan_contract !== null &&
          (loanData[key] instanceof File || loanData[key] instanceof Blob)
        ) {
          formData.append(key, loanData[key]);
        }
      }

      const response = await fetch(domain + "loans/loans/", {
        method: "POST",
        headers: {
          Authorization: "JWT " + accessToken,
        },
        body: formData,
      });
      if (response.ok) {
        swal({
          title: "Success",
          text: "Loan application submitted successfully",
          icon: "success",
          timer: 2000,
          buttons: false,
        });
        setActiveStep(0);
        handleClose();
        handleRefresh(Math.random());
        navigate(`/loans/${userId}`);
      } else {
        const body = await response.json();

        if (body) {
          const errors = body;
          for (const [field, messages] of Object.entries(errors)) {
            messages.forEach((message) => {
              swal({
                title: "Error",
                text: `${field}: ${message}`,
                icon: "error",
                timer: 3000,
                buttons: false,
              });
            });
          }
        } else {
          swal({
            title: "Error",
            text: "An unexpected error occurred",
            icon: "error",
            timer: 2000,
            buttons: false,
          });
        }
      }
    }

    if (btnName === "Edit") {
      const formData = new FormData();
      for (const key in customerData) {
        formData.append(key, customerData[key]);
      }

      formData.append("guarantor.full_name", guarantorData.full_name);
      formData.append("guarantor.phone_number", guarantorData.phone_number);
      formData.append("guarantor.address", guarantorData.address);
      formData.append("guarantor.photo", guarantorData.photo);
      formData.append("guarantor.gender", guarantorData.gender);

      const response = await fetch(
        domain + "customers/customers/" + userId + "/",
        {
          method: "PUT",
          headers: {
            Authorization: "JWT " + accessToken,
          },
          body: formData,
        }
      );

      if (response.ok) {
        swal({
          title: "Success",
          text: "Customer updated",
          icon: "success",
          timer: 2000,
          buttons: false,
        });
        handleClose();
        // onRefreshTable("update");
        handleRefresh(Math.random());
        setActiveStep(0);
      } else {
        swal({
          title: "Error",
          text: "Error occurred",
          icon: "error",
          timer: 2000,
          buttons: false,
        });
      }
    }
  };

  const handlePrintStatement = async () => {
    setShowSpinner(true);
    const response = await fetch(domain + "customers/payments/", {
      method: "POST",
      headers: {
        Authorization: "JWT " + accessToken,
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        customer: popupData.customer?.id,
        outstanding_amount: 2000,
        amount: 2000,
        payment_type: "statement",
        paid: false,
      }),
    });
    if (response.ok) {
      setShowSpinner(false);
      swal({
        title: "succes",
        text: "Successful printed",
        icon: "success",
        timer: 1300,
        buttons: false,
      });
      statementDetailsToPDF(popupData, repayments);
    } else {
      setShowSpinner(false);
      const body = await response.json();

      if (body) {
        const errors = body;
        for (const [field, messages] of Object.entries(errors)) {
          messages.forEach((message) => {
            swal({
              title: "Error",
              text: `${message}`,
              icon: "error",
              timer: 3000,
              buttons: false,
            });
          });
        }
      } else {
        setShowSpinner(false);
        swal({
          title: "Error",
          text: "An unexpected error occurred",
          icon: "error",
          timer: 2000,
          buttons: false,
        });
      }
    }
  };
  // Stepper settings
  const steps =
    btnName === "ADD" ? ["Customer Info"] : ["Customer Info", "Guarantor Info"];

  return (
    <React.Fragment>
      <button
        title={btnName}
        className={btnClass}
        onClick={handleClickOpen}
        disabled={disabled}
      >
        <i className={"fa fa-" + iconName + " m-r-5"}></i>
        {btnName}
      </button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {showSpinner && <LinearProgress />}

        <DialogTitle>{formTitle}</DialogTitle>

        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <StyledPaper elevation={3}>
              {/* Section 1: Microfinance company name and logo + Customer image */}
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 4 }}
              >
                <Grid item xs={6} container alignItems="center">
                  <img
                    src={microfinanceLogo}
                    alt="Microfinance Logo"
                    style={{
                      width: "70px",
                      height: "50px",
                      marginRight: "10px",
                    }}
                  />
                  <div className="">
                    <Title variant="h5">BELLICKS FINANCE LTD</Title>
                    <Title variant="h6">LOAN STATEMENT</Title>
                  </div>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end">
                  <AvatarContainer>
                    <Avatar
                      alt={"user image"}
                      src={popupData?.customer?.photo_url}
                      sx={{ width: 60, height: 60 }}
                    />
                  </AvatarContainer>
                </Grid>
              </Grid>

              {/* Section 2: Customer Information */}
              <SectionTitle variant="h6">Customer Information</SectionTitle>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Name:</strong> {popupData.customer.full_name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Mobile:</strong> {popupData.customer.phone_number}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <strong>Residence:</strong> {popupData.customer.residence}
                  </Typography>
                </Grid>
              </Grid>

              {/* Section 3: Guarantor Information */}
              <SectionTitle variant="h6" sx={{ mt: 4 }}>
                Guarantor Information
              </SectionTitle>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Name:</strong>{" "}
                    {popupData.customer?.guarantor?.full_name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Mobile:</strong>{" "}
                    {popupData.customer?.guarantor?.phone_number}
                  </Typography>
                </Grid>
              </Grid>

              {/* Section 4: Loan Information */}
              <SectionTitle variant="h6" sx={{ mt: 4 }}>
                Loan Information
              </SectionTitle>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">
                    <strong>Start Date:</strong>{" "}
                    {formatDate(popupData?.start_date)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">
                    <strong>End Date:</strong> {formatDate(popupData?.end_date)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">
                    <strong>Loan Amount:</strong>{" "}
                    {formatCurrency(popupData?.repayment_amount)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">
                    <strong>Amount Returned:</strong>{" "}
                    {formatCurrency(popupData?.total_amount_returned)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">
                    <strong>Amount Remaining:</strong>
                    {formatCurrency(popupData?.total_amount_remain)}
                  </Typography>
                </Grid>
              </Grid>

              {/* Section 5: Repayment Table */}
              <SectionTitle variant="h6" sx={{ mt: 4 }}>
                Repayment History
              </SectionTitle>
              <TableContainer
                component={Paper}
                sx={{
                  borderRadius: "12px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Amount Paid
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Loan Outstanding Amount
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Receipt Number
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {repayments.map((repayment, index) => (
                      <TableRow key={index}>
                        <TableCell>{repayment.date}</TableCell>
                        <TableCell>
                          {formatCurrency(repayment.amount)}
                        </TableCell>

                        <TableCell>
                          {formatCurrency(
                            repayment?.schedule?.loan?.repayment_amount -
                              repayment.totalAmountPaid
                          )}
                        </TableCell>
                        <TableCell>{repayment.receipt_number}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </StyledPaper>
          </Box>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-light m-1 float-right"
            onClick={handleClose}
          >
            CLOSE
          </button>
          {btnName === "DELETE" ? (
            <button
              type="submit"
              className="btn m-1 float-right btn-danger"
              onClick={handleSubmit}
            >
              DELETE
            </button>
          ) : (
            <button
              hidden={
                (btnName === "Edit" && activeStep !== steps.length) ||
                btnName == "Invoice"
              }
              type="submit"
              className="btn m-1 float-right btn-primary"
              onClick={handlePrintStatement}
            >
              PRINT
            </button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
