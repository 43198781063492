import React, { useState, useEffect, useRef } from 'react';
import { Tabs, Tab, Form } from 'react-bootstrap';
import '../shared/Form.css';
import { domain } from '../auth/domain';
import swal from 'sweetalert';
import CustomLoader from '../components/CustomLoader';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';

function UpdateApplication() {
    const accessToken = localStorage.getItem('accessToken');
    const [applicationAbstract, setApplicationAbstract] = useState('');
    const [applicationTitle, setApplicationTitle] = useState('');
    const [studyTypeId, setSelectedStudyType] = useState('');
    const [studyDesignId, setSelectedStudyDesign] = useState('');
    const [researchFieldId, setSelectedRsesearchField] = useState('');
    const [researchFields, setResearchFields] = useState([]);
    const [studyDesigns, setStudyDesigns] = useState([]);
    const [studyTypes, setStudyTypes] = useState([]);
    const [studyPurpose, setStudyPurpose] = useState('');
    const [duration, setDuration] = useState('');
    const [sponsor, setSponsor] = useState('');
    const [piName, setPiName] = useState('');
    const [piEmail, setPiEmail] = useState('');
    const [piMobile, setPiMobile] = useState('');
    const [piCountryId, setPiCountryId] = useState('');
    const [piNationality, setPiNationality] = useState('');
    const [countries, setCountries] = useState([]);
    const [isStudent, setIsStudent] = useState(true);
    const [studyProposal, setStudyProposalFile] = useState(null);
    const [irbApproval, setIrbApprovalFile] = useState('NONE');
    const [dataCollection, setDataCollectionFile] = useState('NONE');
    const [cv, setCvFile] = useState(null);
    const [coverLetter, setCoverLetterFile] = useState(null);
    const [consentForm, setConsentFormFile] = useState('NONE');
    const [introductionLetter, setIntroductionLetter] = useState('NONE');
    const [activeTab, setActiveTab] = useState(0);
    const [showSpinner, setShowSpinner] = useState(false);
    const location = useLocation();
    const [application, setApplication] = useState([]);
    const [tabCompletion, setTabCompletion] = useState([false, false, false]);
    const irbApprovalRef = useRef('NONE');
    const consentFormRef = useRef('NONE');
    const dataCollectionRef = useRef('NONE');
    const introductionLetterRef = useRef('NONE');
    const studyProposalRef = useRef(null);
    const cvRef = useRef(null);
    const coverLetterRef = useRef(null);
    const navigate = useNavigate();
    const durationList = [];

    // Fetch data to be updated
    // extract application id from current location
    const applicationId = location.pathname.split('/').pop();

    // const handleTabSelect = (tabName) => {
    //     setActiveTab(tabName);
    // };


    const handleCountryChange = (selectedOption) => {
        setPiCountryId(selectedOption.value);
    };

    const countryOptions = countries.map((country) => ({
        value: country.id,
        label: country.nationality,
    }));

    // loop for creating an array contain duration
    for (let i = 1; i <= 12; i++) {
        durationList.push(i);
    }



    const handleNext = () => {
        if (activeTab === 0) {
            if (applicationTitle === '' || applicationAbstract === '' ||
                studyTypeId === '' || studyDesignId === '' ||
                researchFieldId === '' || studyPurpose === '' ||
                duration === '' || sponsor === '') {
                swal({ icon: 'info', title: 'Please fill in the required fields' });
                return;
            }
        } else if (activeTab === 1) {
            if (piName === '' || piEmail === '' || piMobile === '' ||
                piCountryId === '') {
                swal({ icon: 'info', title: 'Please fill in the required fields' });
                return;
            }
        }

        setActiveTab(activeTab + 1);
    };



    const handleTabOneChange = () => {
        if ('') {
            setTabCompletion([true, tabCompletion[1], tabCompletion[2]]);
        } else {
            setTabCompletion([false, tabCompletion[1], tabCompletion[2]]);
        }
    };


    // fetch Current user Applications
    useEffect(() => {
        setShowSpinner(true);
        fetch(`${domain}applications/${applicationId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + accessToken,
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setApplication(data);
                setApplicationTitle(data.applicationTitle);
                setApplicationAbstract(data.applicationAbstract);
                setSelectedStudyType(data.studyType.id);
                setSelectedStudyDesign(data.studyDesign.id);
                setSelectedRsesearchField(data.researchField.id);
                setStudyPurpose(data.studyPurpose);
                setPiName(data.piName);
                setPiEmail(data.piEmail);
                setPiMobile(data.piMobile);
                setPiNationality(data.piNationality);
                setDuration(data.duration);
                setSponsor(data.sponsor);
                setIsStudent(data.isStudent);



            })
            .catch((error) => console.error(error))
            .finally(() => { setShowSpinner(false) });
    }, []);


    const handleStudyProposalFileChange = (event) => {
        const file1 = event.target.files[0];
        if (file1.type === 'application/pdf') {
            if (file1.size <= 2 * 1024 * 1024) { // 5MB in bytes
                if (file1 && file1 !== 'NONE') {
                    const reader = new FileReader();
                    reader.readAsDataURL(file1);
                    reader.onload = () => {
                        setStudyProposalFile(reader.result);
                    };
                } else {
                    setStudyProposalFile(null); // Set the studyProposalFile to null or any default value
                }
            }
            else {
                swal({ title: 'info', icon: 'info', text: 'File size is too large.Attach 2MB or less' });
                event.target.value = '';
            }

        }
        else {
            swal({ title: 'info', icon: 'info', text: 'only PDF allowed' });
            event.target.value = '';

        }

    };


    const handleCoverLetterFileChange = (event) => {
        const file2 = event.target.files[0];
        if (file2.type === 'application/pdf') {
            if (file2.size <= 2 * 1024 * 1024) { // 5MB in bytes
                if (file2 && file2 !== 'NONE') {
                    const reader = new FileReader();
                    reader.readAsDataURL(file2);
                    reader.onload = () => {
                        setCoverLetterFile(reader.result);
                    };
                } else {
                    setCoverLetterFile(null); // Set the studyProposalFile to null
                }
            }
            else {
                swal({ title: 'info', icon: 'info', text: 'File size is too large.Attach 2MB or less' });
                event.target.value = '';
            }
        }
        else {
            swal({ title: 'info', icon: 'info', text: 'only PDF allowed' });
            event.target.value = '';

        }

    };



    const handleIntroductionLetterChange = (event) => {
        const file3 = event.target.files[0];
        if (file3.type === 'application/pdf') {
            if (file3.size <= 2 * 1024 * 1024) { // 5MB in bytes
                if (file3 && file3 !== 'NONE') {
                    const reader = new FileReader();
                    reader.readAsDataURL(file3);
                    reader.onload = () => {
                        setIntroductionLetter(reader.result);
                    };
                } else {
                    setIntroductionLetter('NONE'); // Set the studyProposalFile to null or any default value
                }
            }
            else {
                swal({ title: 'info', icon: 'info', text: 'File size is too large.Attach 2MB or less' });
                event.target.value = '';
            }

        }
        else {
            swal({ title: 'info', icon: 'info', text: 'only PDF allowed' });
            event.target.value = '';

        }
    };


    const handleIrbApprovalFileFileChange = (event) => {
        const file4 = event.target.files[0];
        if (file4.type === 'application/pdf') {
            if (file4.size <= 2 * 1024 * 1024) { // 5MB in bytes
                if (file4 && file4 !== 'NONE') {
                    const reader = new FileReader();
                    reader.readAsDataURL(file4);
                    reader.onload = () => {
                        setIrbApprovalFile(reader.result);
                    };
                } else {
                    setIrbApprovalFile('NONE'); // Set the studyProposalFile to null or any default value
                }
            }
            else {
                swal({ title: 'info', icon: 'info', text: 'File size is too large.Attach 2MB or less' });
                event.target.value = '';
            }

        }
        else {
            swal({ title: 'info', icon: 'info', text: 'only PDF allowed' });
            event.target.value = '';

        }
    };






    const handleDataCollectionFileChange = (event) => {
        const file5 = event.target.files[0];

        if (file5 && file5 !== 'NONE') {
            const reader = new FileReader();
            reader.readAsDataURL(file5);
            reader.onload = () => {
                setDataCollectionFile(reader.result);
            };
        } else {
            setDataCollectionFile('NONE'); // Set the studyProposalFile to null or any default value
        }
    };



    const handleCvFileChange = (event) => {
        const file6 = event.target.files[0];
        if (file6.type === 'application/pdf') {
            if (file6.size <= 2 * 1024 * 1024) { // 5MB in bytes
                if (file6 && file6 !== 'NONE') {
                    const reader = new FileReader();
                    reader.readAsDataURL(file6);
                    reader.onload = () => {
                        setCvFile(reader.result);
                    };
                } else {
                    setCvFile(null); // Set the studyProposalFile to null or any default value
                }
            }
            else {
                swal({ title: 'info', icon: 'info', text: 'File size is too large.Attach 2MB or less' });
                event.target.value = '';
            }

        }
        else {
            swal({ title: 'info', icon: 'info', text: 'only PDF allowed' });
            event.target.value = '';

        }
    };


    const handleConsentFormFileChange = (event) => {
        const file7 = event.target.files[0];
        if (file7.type === 'application/pdf') {
            if (file7.size <= 2 * 1024 * 1024) { // 5MB in bytes
                if (file7 && file7 !== 'NONE') {
                    const reader = new FileReader();
                    reader.readAsDataURL(file7);
                    reader.onload = () => {
                        setConsentFormFile(reader.result);
                    };
                } else {
                    setConsentFormFile('NONE'); // Set the studyProposalFile to null or any default value
                }
            }
            else {
                swal({ title: 'info', icon: 'info', text: 'File size is too large.Attach 2MB or less' });
                event.target.value = '';
            }

        }
        else {
            swal({ title: 'info', icon: 'info', text: 'only PDF allowed' });
            event.target.value = '';

        }
    };




    // fetch researchField
    useEffect(() => {
        fetch(domain + "v1/research-field", {
            method: 'GET',
            headers: {

                'Authorization': 'Bearer ' + accessToken,

            }
        })
            .then((response) => response.json())
            .then((data) => { setResearchFields(data) })
            .catch((error) => console.error(error));
    }, []);


    // fetch study designs
    useEffect(() => {
        fetch(domain + "v1/study-design", {
            method: 'GET',
            headers: {

                'Authorization': 'Bearer ' + accessToken,

            }
        })
            .then((response) => response.json())
            .then((data) => { setStudyDesigns(data) })
            .catch((error) => console.error(error));
    }, []);

    // fetch study types
    useEffect(() => {
        fetch(domain + "v1/study-type", {
            method: 'GET',
            headers: {

                'Authorization': 'Bearer ' + accessToken,

            }
        })
            .then((response) => response.json())
            .then((data) => { setStudyTypes(data) })
            .catch((error) => console.error(error));
    }, []);

    // fetch Countries data
    useEffect(() => {
        fetch(domain + "countries?size=700", {
            method: 'GET',

        })
            .then((response) => response.json())
            .then((data) => { setCountries(data) })
            .catch((error) => console.error(error));
    }, []);

    // submit Data input in Form
    const handleSubmit = async (event) => {
        event.preventDefault();
        setShowSpinner(true);
        // alert(firstname + lastname + middlename + address + password + email + institution + mobile + profession);


        const response = await fetch(`${domain}applications/${applicationId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,

            },
            body: JSON.stringify(
                {
                    applicationAbstract,
                    applicationTitle,
                    consentForm: { "attachment": consentForm },
                    coverLetter: { "attachment": coverLetter },
                    cv: { "attachment": cv },
                    dataCollection: { "attachment": dataCollection },
                    duration,
                    irbApproval: { "attachment": irbApproval },
                    piEmail,
                    piMobile,
                    piName,
                    piCountryId,
                    introductionLetter: { "attachment": introductionLetter },
                    researchFieldId,
                    sponsor,
                    studyDesignId,
                    studyProposal: { "attachment": studyProposal },
                    studyPurpose,
                    studyTypeId,
                    isStudent


                })
        });

        if (response.ok) {
            setShowSpinner(false)
            // display success messages
            swal({
                title: "Application  Info",
                text: "Successful updated",
                icon: "success",
                // dangerMode: true,
            })
            navigate('/my-application');

            // set varaible to empty values
            setApplicationAbstract('');
            setApplicationTitle('');
            setDuration('');
            setPiEmail('');
            setPiMobile('');
            setPiName('');
            setPiNationality('');
            // setSelectedRsesearchField('');
            setSponsor('');
            // setSelectedStudyDesign('');
            setStudyPurpose('');
            // setSelectedStudyType('');
            setIsStudent(true)
            setIntroductionLetter('NONE');
            setConsentFormFile('NONE');
            setIrbApprovalFile('');
            setCoverLetterFile('');
            setStudyProposalFile('');
            setCvFile('');
            setDataCollectionFile('');

        } else {
            setShowSpinner(false)
            // display success messages
            swal({
                title: "Oops...(" + response.status + ")",
                text: "Update Failed",
                icon: "error",

                // dangerMode: true,
            })
        }
    };




    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card-box form-card">
                    <h6 className="card-title">Update Application</h6>
                    <form className='form-custom m-3' onSubmit={handleSubmit}>
                        <Tabs activeKey={activeTab} id="tabs-example">
                            <Tab eventKey={0} title="STUDY DETAILS">
                                <div>
                                    <Form.Group>
                                        <label htmlFor="exampleInputName1">Title *</label>
                                        <Form.Control required type="text" className="form-control form-custom" id="exampleInputName1" value={applicationTitle} onChange={(event) => setApplicationTitle(event.target.value)}></Form.Control>
                                    </Form.Group>

                                    <Form.Group>
                                        <label htmlFor="exampleTextarea1">Abstract *</label>
                                        <textarea required className="form-control form-custom" id="exampleTextarea1" rows="4" value={applicationAbstract} onChange={(event) => setApplicationAbstract(event.target.value)}></textarea>
                                    </Form.Group>
                                    <Form.Group>
                                        <label htmlFor="exampleSelectGender">Study type *</label>
                                        <select required className="form-control form-custom" id="exampleSelectGender" value={studyTypeId} onChange={(event) => setSelectedStudyType(event.target.value)}>
                                            <option>Select</option>
                                            {
                                                (() => {
                                                    try {
                                                        return studyTypes.map((field) => <option value={field.id} key={field.id}>{field.studyType}</option>)
                                                    }
                                                    catch (error) {
                                                        console.log(error);
                                                        return <option>Network Problem.Please check your Internet</option>;
                                                    }
                                                })()}

                                        </select>
                                    </Form.Group>
                                    <Form.Group>
                                        <label htmlFor="exampleSelectGender">Study design *</label>
                                        <select required className="form-control form-custom" id="exampleSelectGender" value={studyDesignId} onChange={(event) => setSelectedStudyDesign(event.target.value)}>
                                            <option>Select</option>
                                            {
                                                (() => {
                                                    try {
                                                        return studyDesigns.map((field) => <option value={field.id} key={field.id}>{field.studyDesign}</option>)
                                                    }
                                                    catch (error) {
                                                        console.log(error);
                                                        return <h6>Network Problem.Please check your Internet</h6>;
                                                    }
                                                })()}

                                        </select>
                                    </Form.Group>

                                    <Form.Group>
                                        <label htmlFor="exampleSelectGender">Research Field *</label>
                                        <select required className="form-control form-custom" value={researchFieldId} onChange={(event) => setSelectedRsesearchField(event.target.value)}>
                                            <option>Select</option>
                                            {(() => {
                                                try {
                                                    return researchFields.map((field) => <option value={field.id} key={field.id}>{field.researchField}</option>)
                                                }
                                                catch (error) {
                                                    console.log(error);
                                                    return <h6>Network Problem.Please check your Internet</h6>;
                                                }
                                            })()}


                                        </select>
                                    </Form.Group>
                                    <Form.Group>
                                        <label htmlFor="exampleTextarea1">Purpose of Study *</label>
                                        <textarea required className="form-control form-custom" id="exampleTextarea1" rows="4" value={studyPurpose} onChange={(event) => setStudyPurpose(event.target.value)}></textarea>
                                    </Form.Group>
                                    <Form.Group>
                                        <label htmlFor="exampleInputName1">Study Duration (In terms of Months) *</label>
                                        <select required className="form-control form-custom" value={duration} onChange={(event) => setDuration(event.target.value)}>
                                            <option>Select</option>
                                            {durationList.map((month) => <option value={month} key={month}>{month} Month(s)</option>)}
                                        </select>
                                    </Form.Group>
                                    <Form.Group>
                                        <label htmlFor="exampleInputName1">Sponsor *</label>
                                        <Form.Control required type="text" className="form-control form-custom" id="exampleInputName1" value={sponsor} onChange={(event) => setSponsor(event.target.value)}></Form.Control>
                                    </Form.Group>
                                </div>
                            </Tab>
                            <Tab eventKey={1} title="PI DETAILS">
                                <div>
                                    <Form.Group>
                                        <label htmlFor="exampleInputName1">PI Name *</label>
                                        <Form.Control required type="text" className="form-control form-custom" id="exampleInputName1" value={piName} onChange={(event) => setPiName(event.target.value)}></Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <label htmlFor="exampleInputName1">PI Email *</label>
                                        <Form.Control required type="email" className="form-control form-custom" id="exampleInputName1" value={piEmail} onChange={(event) => setPiEmail(event.target.value)}></Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <label htmlFor="exampleInputName1">PI Phone Number *</label>
                                        <Form.Control required type="tel" maxLength={10} className="form-control form-custom" id="exampleInputName1" value={piMobile} onChange={(event) => setPiMobile(event.target.value)}></Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <label htmlFor="exampleInputName1">PI Nationality *</label>
                                        <Select
                                            id="countrySelect"
                                            required
                                            value={countryOptions.find((option) => option.value === piCountryId)}
                                            options={countryOptions}
                                            onChange={handleCountryChange}
                                        />
                                    </Form.Group>
                                </div>
                            </Tab>
                            <Tab eventKey='2' title="ATTACHMENTS">
                                <div>
                                    <div className="col-md-4" style={{ borderRadius: '5px' }}>
                                        <Form.Group className="row">
                                            <label>Are You student ?</label>
                                            <div className="col-sm-4">
                                                <div className="form-check">
                                                    <label className="form-check-label">
                                                        <input type="radio" className="form-check-input" name="ExampleRadio4" id="membershipRadios1" value={isStudent} onChange={(event) => setIsStudent(event.target.value = true)} />Yes
                                                        <i className="input-helper"></i>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="form-check">
                                                    <label className="form-check-label">
                                                        <input type="radio" className="form-check-input" name="ExampleRadio4" id="membershipRadios2" value={isStudent} onChange={(event) => setIsStudent(event.target.value = false)} /> No
                                                        <i className="input-helper"></i>
                                                    </label>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <Form.Group>

                                        {isStudent && (
                                            <Form.Group className='mt-3'>
                                                <label>Introduction Letter *</label>
                                                <div className="form-custom">
                                                    <Form.Control required type="file" className="form-control visibility-hidden form-custom" lang="es" onChange={handleIntroductionLetterChange} />
                                                    {/* <label className="custom-file-label" htmlFor="customFileLang2"></label> */}
                                                </div>
                                            </Form.Group>)
                                        }
                                        <label>Full study proposal *</label>
                                        <div className="form-custom">
                                            <Form.Control required type="file" className="form-control visibility-hidden form-custom" id="customFileLang1" lang="es" onChange={handleStudyProposalFileChange} />
                                            {/* <label className="custom-file-label" htmlFor="customFileLang1"></label> */}
                                        </div>
                                    </Form.Group>
                                    <Form.Group>
                                        <label>Cover letter signed by PI *</label>
                                        <div className="form-custom">
                                            <Form.Control required type="file" className="form-control visibility-hidden form-custom" id="customFileLang3" lang="es" onChange={handleCoverLetterFileChange} />
                                            {/* <label className="custom-file-label text-gray" htmlFor="customFileLang3"></label> */}
                                        </div>
                                    </Form.Group>
                                    <Form.Group>
                                        <label>CV *</label>
                                        <div className="form-custom">
                                            <Form.Control required type="file" className="form-control visibility-hidden form-custom" id="customFileLang4" lang="es" onChange={handleCvFileChange} />
                                            {/* <label className="custom-file-label" htmlFor="customFileLang4"></label> */}
                                        </div>
                                    </Form.Group>
                                    <Form.Group>
                                        <label>Consent Forms</label>
                                        <div className="form-custom">
                                            <Form.Control type="file" className="form-control visibility-hidden form-custom" id="customFileLang5" lang="es" onChange={handleConsentFormFileChange} />
                                            {/* <label className="custom-file-label" htmlFor="customFileLang5"></label> */}
                                        </div>
                                    </Form.Group>
                                    <Form.Group>
                                        <label>IRB Approval Certificate</label>
                                        <div className="form-custom-file">
                                            <Form.Control type="file" className="form-control visibility-hidden form-custom" id="customFileLang6" lang="es" onChange={handleIrbApprovalFileFileChange} />
                                            {/* <label className="custom-file-label" htmlFor="customFileLang6"></label> */}
                                        </div>
                                    </Form.Group>
                                    <Form.Group>
                                        <label>Data collection Tools</label>
                                        <div className="form-custom">
                                            <Form.Control type="file" className="form-control visibility-hidden form-custom" id="customFileLang7" lang="es" onChange={handleDataCollectionFileChange} />
                                            {/* <label className="custom-file-label" htmlFor="customFileLang7"></label> */}
                                        </div>
                                    </Form.Group>

                                    {/* <Form.Group className='mt-2'>
                                        {showSpinner ? <CustomLoader /> : <button type="submit" className="btn btn-primary btn-lg">UPDATE</button>}
                                    </Form.Group> */}
                                </div>
                            </Tab>
                        </Tabs>
                        {/* Previous, Next, and Submit buttons */}
                        <Form.Group className="button-group m-3">
                            {activeTab > 0 && (
                                <button type="button" className="btn btn-outline-primary mr-1" onClick={
                                    () => {

                                        setActiveTab(activeTab - 1);

                                    }
                                }>
                                    Previous
                                </button>
                            )}

                            {activeTab < 2 && (
                                <button type="button" className="btn btn-outline-primary" onClick={
                                    () => handleNext()
                                }>
                                    Next
                                </button>
                            )}

                            {activeTab === 2 && (
                                showSpinner ? <CustomLoader /> :
                                    <button type="submit" className="btn btn-primary">
                                        UPDATE
                                    </button>
                            )}
                        </Form.Group>

                    </form>
                </div>
            </div>
        </div>
    );
}

export default UpdateApplication;
